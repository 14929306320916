import React from 'react';
import PDFThumbnail from './PDFThumbnail';
import { truncateText } from '../utils/textUtils';
import { IoClose, IoAddCircle, IoLanguage } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

const RecentPDFs = ({ sortedPdfs, displayedRecentPdfs, onOpenPdf, onRemoveFromRecent, onAddPdf, isDarkMode, showAllRecent, toggleShowAllRecent }) => {
  const { t, i18n } = useTranslation();
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onAddPdf(file);
    }
  };

  // Function to format CAS block based on language
  const formatCasBlock = (block) => {
    if (!block) return null;
    
    // In English, show as "CAS: B1", in Chinese show as "中科院: 1区"
    const isEnglish = i18n.language === 'en';
    const cleanBlock = block.replace('B', '');
    
    return isEnglish ? `CAS: B${cleanBlock}` : `中科院: ${cleanBlock}区`;
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <div className="flex items-center">
          <h2 className={`text-xl font-bold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>{t('pdf.recentPDFs')}</h2>
          <label htmlFor="add-pdf-recent" className={`mr-2 cursor-pointer ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}`}>
            <IoAddCircle size={25} />
          </label>
          <input
            id="add-pdf-recent"
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
        <div className="flex items-center">
          {sortedPdfs.length > 6 && (
            <button
              onClick={toggleShowAllRecent}
              className={`text-sm font-medium mr-2 ${isDarkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-600 hover:text-gray-800'}`}
            >
              {showAllRecent ? t('pdf.showLess') : t('pdf.viewAll')}
            </button>
          )}
          {/* Language switcher button */}
          <button
            onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en')}
            className={`p-2 rounded-full ${isDarkMode ? 'bg-[#21262d] text-blue-300' : 'bg-gray-200 text-blue-600'}`}
            title={t('common.changeLanguage')}
          >
            <IoLanguage size={16} />
          </button>
        </div>
      </div>
      {sortedPdfs.length === 0 ? (
        <p className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{t('pdf.noPDFsFound')}</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          {displayedRecentPdfs.map((pdf) => (
            <div
              key={pdf.id}
              className={`${isDarkMode ? 'bg-[#21262d]/50' : 'bg-white/50'} backdrop-blur-sm rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-xl transition duration-300 flex flex-col relative group`}
            >
              <button
                className="absolute top-0.5 right-0.5 z-10 p-0.5 rounded-full bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-70 transition duration-200 opacity-0 group-hover:opacity-100"
                onClick={(e) => {
                  e.stopPropagation();
                  
                  // If removing the sample PDF, mark it as closed
                  if (pdf.id === "sample123") {
                    localStorage.setItem('sample_pdf_closed', 'true');
                  }
                  
                  onRemoveFromRecent(pdf.id);
                }}
              >
                <IoClose size={16} />
              </button>
              <div 
                className="flex flex-col h-full"
                onClick={() => onOpenPdf(pdf.id)}
              >
                <div className="relative h-24 overflow-hidden">
                  <PDFThumbnail pdfId={pdf.id} metadata={pdf} className="w-full h-full object-cover" />
                </div>
                <div className="p-2 flex-grow flex flex-col">
                  <h3 className={`font-semibold text-sm mb-1 line-clamp-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                    {pdf.name}
                  </h3>
                  <p className={`text-xs mb-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    <span className="font-medium">Author:</span> {truncateText(pdf.author || 'Unknown', 30)}
                  </p>
                  {pdf.journalName && (
                    <p className={`text-xs mb-1 font-bold ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {truncateText(pdf.journalName, 30)}
                    </p>
                  )}
                  <div className="flex flex-wrap gap-1 mt-auto">
                    {pdf.journalMetrics && (
                      <>
                        {pdf.journalMetrics.if && (
                          <span className={`px-2 py-1 text-xs rounded-full ${isDarkMode ? 'bg-blue-800/50 text-blue-200' : 'bg-blue-100 text-blue-800'}`}>
                            IF: {pdf.journalMetrics.if}
                          </span>
                        )}
                        {pdf.journalMetrics.q && (
                          <span className={`px-2 py-1 text-xs rounded-full ${isDarkMode ? 'bg-green-800/50 text-green-200' : 'bg-green-100 text-green-800'}`}>
                            Q: {pdf.journalMetrics.q}
                          </span>
                        )}
                        {pdf.journalMetrics.b && (
                          <span className={`px-2 py-1 text-xs rounded-full ${isDarkMode ? 'bg-yellow-800/50 text-yellow-200' : 'bg-yellow-100 text-yellow-800'}`}>
                            {formatCasBlock(pdf.journalMetrics.b)}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {pdf.lastOpened && (
                    <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Last opened: {new Date(pdf.lastOpened).toLocaleDateString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default RecentPDFs;

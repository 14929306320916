import React, { useState, useEffect, useCallback } from 'react';
import { AiOutlineLoading } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

const DailyMeme = ({ isDarkMode, showMeme }) => {
  const [memeUrl, setMemeUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const { t } = useTranslation();

  const fetchMeme = useCallback(async () => {
    if (!showMeme) return;
    setIsLoading(true);
    setImageLoaded(false);
    setError(null);
    try {
      const response = await fetch('https://api.labcat.com.cn/meme/');
      if (!response.ok) {
        throw new Error('Failed to fetch meme');
      }
      const data = await response.json();
      setMemeUrl(`https://api.labcat.com.cn${data.url}`);
    } catch (err) {
      setError('Failed to load meme');
      console.error('Error fetching meme:', err);
    }
  }, [showMeme]);

  useEffect(() => {
    if (showMeme) {
      fetchMeme();
    } else {
      setMemeUrl(null);
      setImageLoaded(false);
    }
  }, [showMeme, fetchMeme]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    setIsLoading(false);
  };

  const toggleEnlarged = () => {
    setIsEnlarged(!isEnlarged);
  };

  if (!showMeme) return null;

  return (
    <>
      {(isLoading || !imageLoaded) && (
        <div className="flex items-center justify-center p-6 bg-opacity-80 rounded-lg mb-2 font-sans">
          <div className="flex flex-col items-center">
            <AiOutlineLoading className={`animate-spin text-4xl ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
            <p className={`mt-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>{t('common.loading')}</p>
          </div>
        </div>
      )}
      
      <div className={`mt-2 rounded-lg ${isDarkMode ? 'bg-[#21262d]' : 'bg-white'} shadow-md relative overflow-hidden font-sans`}>
        {error && <p className="text-center p-4 text-red-500">{error}</p>}
        {memeUrl && (
          <div className="max-h-[350px] overflow-y-auto overflow-x-hidden relative group" style={{ scrollbarWidth: 'thin' }}>
            <div className="absolute top-2 right-2 bg-black bg-opacity-60 text-white px-2 py-1 rounded-md text-xs z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              {t('common.click') || 'Click'} {t('common.toEnlarge') || 'to enlarge'}
            </div>
            <div 
              className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-30 bg-black bg-opacity-20 transition-opacity cursor-pointer"
              onClick={toggleEnlarged}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
              </svg>
            </div>
            <img 
              src={memeUrl} 
              alt="Daily Research Meme" 
              className="w-full h-auto cursor-pointer" 
              onLoad={handleImageLoad}
              onClick={toggleEnlarged}
              style={{ display: imageLoaded ? 'block' : 'none' }}
            />
          </div>
        )}
      </div>
      
      {isEnlarged && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50 p-4 overflow-hidden"
          onClick={toggleEnlarged}
        >
          <div className="relative w-auto max-w-[95%] max-h-[90vh] bg-transparent p-2 rounded-lg flex items-center justify-center">
            <button 
              className="absolute top-2 right-2 z-10 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                toggleEnlarged();
              }}
            >
              <IoClose size={20} />
            </button>
            <img 
              src={memeUrl} 
              alt="Enlarged Daily Research Meme" 
              className="max-w-full max-h-[85vh] object-contain"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DailyMeme;

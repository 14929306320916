import React, { useState, useEffect } from 'react';
import { IoClose, IoCopy, IoGift, IoCheckmark } from "react-icons/io5";
import AnimatedTokenDisplay from './AnimatedTokenDisplay';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const ReferralModal = ({ isOpen, onClose, isDarkMode }) => {
  const [referralCode, setReferralCode] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [stats, setStats] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      checkRedemptionStatus();
      getReferralCode();
      getReferralStats();
    }
  }, [isOpen]);

  const checkRedemptionStatus = async () => {
    try {
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await fetch('https://api.labcat.com.cn/redemption/redemption-status', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setHasRedeemed(data.has_redeemed);
    } catch (error) {
      console.error('Error checking redemption status:', error);
    }
  };

  const getReferralCode = async () => {
    try {
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await fetch('https://api.labcat.com.cn/redemption/referral-code', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setReferralCode(data.referral_code || '');
    } catch (error) {
      console.error('Error getting referral code:', error);
    }
  };

  const getReferralStats = async () => {
    try {
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await fetch('https://api.labcat.com.cn/redemption/referral-stats', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setStats({
        total_referrals: data.total_referrals || 0,
        recent_referrals: data.recent_referrals || 0,
        tokens_earned: data.tokens_earned || 0
      });
    } catch (error) {
      console.error('Error getting referral stats:', error);
    }
  };

  const handleRedeem = async () => {
    if (!redeemCode.trim()) {
      setMessageType('error');
      setMessage('请输入推荐码');
      return;
    }

    try {
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await fetch('https://api.labcat.com.cn/redemption/redeem', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          code: redeemCode.trim() 
        })
      });
      
      const data = await response.json();

      if (!response.ok) {
        setMessageType('error');
        let errorMessage = '兑换失败';
        if (data.detail) {
          if (data.detail === "You have already redeemed a referral code") {
            errorMessage = "您已经兑换过推荐码";
          } else if (data.detail === "Cannot use your own referral code") {
            errorMessage = "不能使用自己的推荐码";
          } else if (data.detail === "Invalid code") {
            errorMessage = "无效的推荐码";
          } else {
            errorMessage = data.detail;
          }
        }
        setMessage(errorMessage);
        return;
      }

      setMessageType('success');
      setMessage('兑换成功！获得5000 tokens！');
      checkRedemptionStatus();
      window.dispatchEvent(new Event('tokenUpdate'));
      setRedeemCode('');
      
    } catch (error) {
      console.error('Error redeeming code:', error);
      setMessageType('error');
      setMessage('兑换失败，请重试');
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(referralCode);
      setMessageType('success');
      setMessage(
        <div className="space-y-2">
          <p className="text-green-300">✓ 推荐码已复制到剪贴板！</p>
          <p className="text-sm text-cyan-400/80">把下面的消息分享给好友:</p>
          <div className="p-3 bg-[#1a1f25] rounded-lg border border-cyan-500/20 text-sm">
            🎁 来试试 LabCat AI 学术助手吧！用我的推荐码 <span className="text-pink-400 font-bold">{referralCode}</span> 注册，
            你可以获得 5000 tokens 奖励！
            <br/>
            👉 https://labcat.com.cn/
            <br/>
            下载后在左下角推荐奖励token输入我的推荐码 <span className="text-pink-400 font-bold">{referralCode}</span>，大家一起发nature 🚀
          </div>
        </div>
      );
    } catch (err) {
      setMessageType('error');
      setMessage('复制失败');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-sm">
      <div className={`relative w-[480px] p-8 rounded-xl shadow-2xl 
        bg-gradient-to-b from-[#0d1117] to-[#161b22] border-[0.5px] 
        border-cyan-500/30 backdrop-blur-md
        shadow-[0_0_25px_rgba(6,182,212,0.15)]`}
      >
        {/* Glowing background effect */}
        <div className="absolute inset-0 rounded-xl overflow-hidden">
          <div className="absolute -inset-[100%] opacity-50 bg-gradient-conic from-cyan-500/10 via-transparent to-transparent animate-spin-slow" />
        </div>

        {/* Cyber decorative elements */}
        <div className="absolute top-0 left-0 w-32 h-[1px] bg-gradient-to-r from-cyan-400 to-transparent opacity-60" />
        <div className="absolute top-0 left-0 w-[1px] h-32 bg-gradient-to-b from-cyan-400 to-transparent opacity-60" />
        <div className="absolute bottom-0 right-0 w-32 h-[1px] bg-gradient-to-l from-cyan-400 to-transparent opacity-60" />
        <div className="absolute bottom-0 right-0 w-[1px] h-32 bg-gradient-to-t from-cyan-400 to-transparent opacity-60" />

        {/* Corner decorations */}
        <div className="absolute top-0 left-0 w-4 h-4">
          <div className="absolute top-0 left-0 w-full h-[1px] bg-cyan-400 opacity-80" />
          <div className="absolute top-0 left-0 h-full w-[1px] bg-cyan-400 opacity-80" />
        </div>
        <div className="absolute top-0 right-0 w-4 h-4">
          <div className="absolute top-0 right-0 w-full h-[1px] bg-cyan-400 opacity-80" />
          <div className="absolute top-0 right-0 h-full w-[1px] bg-cyan-400 opacity-80" />
        </div>
        <div className="absolute bottom-0 left-0 w-4 h-4">
          <div className="absolute bottom-0 left-0 w-full h-[1px] bg-cyan-400 opacity-80" />
          <div className="absolute bottom-0 left-0 h-full w-[1px] bg-cyan-400 opacity-80" />
        </div>
        <div className="absolute bottom-0 right-0 w-4 h-4">
          <div className="absolute bottom-0 right-0 w-full h-[1px] bg-cyan-400 opacity-80" />
          <div className="absolute bottom-0 right-0 h-full w-[1px] bg-cyan-400 opacity-80" />
        </div>

        {/* Close button */}
        <div className="absolute -top-12 right-0 z-50">
          <button
            onClick={onClose}
            className="group relative p-2 rounded-full bg-cyan-500/10 border border-cyan-400/30
              hover:bg-cyan-400/20 transition-all duration-300"
          >
            <IoClose className="w-6 h-6 text-cyan-400 group-hover:text-cyan-300 transition-colors" />
            <div className="absolute inset-0 rounded-full bg-cyan-400/0 group-hover:bg-cyan-400/10 
              transition-all duration-300 animate-pulse" />
          </button>
        </div>

        <div className="relative">
          <h2 className="text-2xl font-rajdhani font-bold mb-6 text-transparent bg-clip-text 
            bg-gradient-to-r from-cyan-400 to-blue-400 tracking-wider flex items-center">
            <IoGift className="mr-3 text-cyan-400 animate-pulse" />
            <span className="animate-cyber-glow">{t('homeTab.referralProgram')}</span>
          </h2>

          {/* Share Section - Now more prominent */}
          <div className="mb-6">
            <div className="mb-4 p-4 rounded-lg bg-cyan-500/5 border border-cyan-400/20">
              <p className="text-orange-300 mb-2">{t('referral.iRecommendOthers')}</p>
              <p className="text-sm text-cyan-400/70 mb-4">{t('referral.friendGets')}</p>
              <p className="text-sm text-cyan-400/70">{t('referral.youGet')}</p>
            </div>
            
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={referralCode}
                readOnly
                className="flex-1 p-4 rounded-lg bg-[#1a1f25]/80 border-2 border-cyan-500/30 
                  text-cyan-300 font-rajdhani tracking-wider text-2xl text-center focus:outline-none
                  focus:border-cyan-400/60 transition-all duration-300
                  shadow-[0_0_15px_rgba(6,182,212,0.1)]"
              />
              <button
                onClick={copyToClipboard}
                className="p-4 rounded-lg bg-pink-500/10 text-pink-400 hover:text-pink-300
                  transition-all duration-300 border-2 border-pink-500/30 hover:border-pink-400/60
                  hover:shadow-[0_0_15px_rgba(236,72,153,0.3)] hover:bg-pink-500/20
                  active:transform active:scale-95 group relative"
              >
                <IoCopy className="w-6 h-6 relative z-10" />
                <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-pink-500/20 to-orange-500/20 
                  opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </button>
            </div>

            {/* Stats - Now more compact */}
            {stats && (
              <div className="mt-4 flex items-center justify-between text-sm px-1">
                <div className="flex flex-col items-center">
                  <span className="text-orange-400 font-bold text-lg font-rajdhani">{stats.total_referrals}</span>
                  <span className="text-cyan-400/60 text-xs">{t('referral.totalReferrals')}</span>
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-orange-400 font-bold text-lg font-rajdhani">{stats.recent_referrals}</span>
                  <span className="text-cyan-400/60 text-xs">{t('referral.last30Days')}</span>
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-green-400 font-bold text-lg font-rajdhani">{stats.tokens_earned}</span>
                  <span className="text-cyan-400/60 text-xs">{t('referral.tokensEarned')}</span>
                </div>
              </div>
            )}
          </div>

          {/* Redeem Section */}
          {!hasRedeemed && (
            <div className="mt-8">
              <h3 className="font-rajdhani font-semibold mb-3 text-orange-300 uppercase tracking-wider">
                {t('referral.iWasReferred')}
              </h3>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  placeholder={t('referral.enterReferralCode')}
                  className="flex-1 p-3 rounded-lg bg-[#1a1f25]/80 border border-orange-500/30 
                    text-orange-300 focus:outline-none focus:border-orange-400/60 transition-all duration-300"
                />
                <button
                  onClick={handleRedeem}
                  className="px-6 py-3 rounded-lg font-rajdhani font-semibold tracking-wider
                    bg-cyan-500/10 text-cyan-400 hover:text-cyan-300
                    transition-all duration-300 border border-cyan-500/30 
                    hover:border-cyan-400/60 hover:bg-cyan-500/20
                    hover:shadow-[0_0_15px_rgba(6,182,212,0.2)]
                    active:transform active:scale-95"
                >
                  兑换
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className={`mt-4 p-4 rounded-lg border ${
              messageType === 'success'
                ? 'bg-green-500/10 border-green-400/50 text-green-300'
                : 'bg-red-500/10 border-red-400/50 text-red-300'
            } font-rajdhani tracking-wide shadow-lg backdrop-blur-sm`}>
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralModal; 
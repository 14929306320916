import { useState, useEffect, useCallback } from 'react';
import { openDB } from 'idb';
import CryptoJS from 'crypto-js';

const usePDFMetadata = (pdfs) => {
  const [sortedPdfs, setSortedPdfs] = useState([]);

  const fetchMetadata = useCallback(async () => {
    try {
      const db = await openDB('PDFStore', 4, {
        upgrade(db, oldVersion, newVersion, transaction) {
          if (oldVersion < 1) {
            db.createObjectStore('metadata');
          }
        },
      });

      // Define sample PDF data
      const samplePdfId = "sample123";
      const samplePdf = {
        id: samplePdfId,
        name: "White Paper",
        author: "Satoshi Nakamoto",
        journalName: "Journal",
        journalMetrics: {
          if: "21",
          q: "Q1",
          b: "B1"
        },
        lastOpened: Date.now(),
        lastViewed: Date.now(),
      };

      // Only add sample PDF if it hasn't been explicitly closed by user
      const isSamplePdfClosed = localStorage.getItem('sample_pdf_closed') === 'true';
      
      if (!isSamplePdfClosed) {
        // Check if the sample PDF already exists
        try {
          const existingSample = await db.get('recentPDFs', samplePdfId);
          
          if (!existingSample) {
            // Try to fetch the actual sample.pdf file from public folder
            try {
              const response = await fetch('/sample.pdf');
              if (response.ok) {
                const blob = await response.blob();
                
                // Store the sample PDF in IndexedDB
                await db.put('pdfs', blob, samplePdfId);
                await db.put('metadata', samplePdf, samplePdfId);
                await db.put('recentPDFs', samplePdf);
                await db.put('openPDFs', samplePdf);
                
                console.log('Sample PDF added to database');
              }
            } catch (fetchError) {
              console.error('Error fetching sample PDF:', fetchError);
            }
          }
        } catch (error) {
          console.error('Error checking for sample PDF:', error);
        }
      }

      // Process PDF metadata like in the original function
      const updatedPdfs = await Promise.all(pdfs.map(async (pdf) => {
        let metadata;
        try {
          metadata = await db.get('metadata', pdf.id);
        } catch (error) {
          console.warn(`Error fetching metadata for PDF ${pdf.id}:`, error);
          metadata = {};
        }
        return {
          ...pdf,
          ...metadata
        };
      }));

      // Make sure sample PDF is included if not already present and not closed by user
      if (!isSamplePdfClosed && !updatedPdfs.some(pdf => pdf.id === samplePdfId)) {
        updatedPdfs.push(samplePdf);
      }

      // Sort PDFs by lastOpened timestamp (original logic)
      const sorted = updatedPdfs.sort((a, b) => (b.lastOpened || 0) - (a.lastOpened || 0));
      setSortedPdfs(sorted);
    } catch (error) {
      console.error('Error fetching metadata:', error);
      // Still try to include sample PDF in case of errors, but respect user choice
      const isSamplePdfClosed = localStorage.getItem('sample_pdf_closed') === 'true';
      const sorted = [...pdfs].sort((a, b) => (b.lastOpened || 0) - (a.lastOpened || 0));
      
      // Only add the sample PDF if it's not closed
      if (!isSamplePdfClosed && !sorted.some(pdf => pdf.id === "sample123")) {
        sorted.push({
          id: "sample123",
          name: "White Paper",
          author: "Satoshi Nakamoto",
          journalName: "Journal",
          journalMetrics: {
            if: "21",
            q: "Q1",
            b: "B1"
          },
          lastOpened: Date.now(),
          lastViewed: Date.now(),
        });
      }
      
      setSortedPdfs(sorted);
    }
  }, [pdfs]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  return sortedPdfs;
};

export default usePDFMetadata;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoCloseCircle } from 'react-icons/io5';

const PaymentCancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect back to main app after 3 seconds
    const timer = setTimeout(() => navigate('/'), 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-md">
      <div className="bg-[#0d1117] rounded-xl border border-gray-500/30 shadow-2xl p-8 max-w-md w-full">
        <div className="flex flex-col items-center space-y-6 text-center">
          <div className="text-6xl text-gray-500 mb-2">
            <IoCloseCircle />
          </div>
          
          <h2 className="text-2xl font-bold text-white font-rajdhani">
            Payment Cancelled
          </h2>
          
          <p className="text-gray-300">
            Your payment was cancelled and you have not been charged.
          </p>
          
          <div className="text-sm text-gray-400 mt-2">
            Redirecting you back to the app...
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancel; 
// TokenDisplay.js
import React, { useState, useEffect, useRef } from 'react';
import AnimatedTokenDisplay from './AnimatedTokenDisplay';

const TokenDisplay = ({ onBalanceChange, forceUpdate }) => {
  const [tokenBalance, setTokenBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPolling, setIsPolling] = useState(false);
  const pollingIntervalRef = useRef(null);
  const previousBalanceRef = useRef(null);

  const fetchTokenBalance = async () => {
    try {
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await fetch('https://api.labcat.com.cn/api/tokens/balance', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        const newBalance = data.tokens_remaining;
        
        // If balance changed during polling, stop polling
        if (isPolling && previousBalanceRef.current !== null && newBalance !== previousBalanceRef.current) {
          setIsPolling(false);
          clearInterval(pollingIntervalRef.current);
        }
        
        setTokenBalance(newBalance);
        previousBalanceRef.current = newBalance;
        onBalanceChange?.(newBalance);
      }
    } catch (error) {
      console.error('Error fetching token balance:', error);
    } finally {
      setLoading(false);
    }
  };

  // Start polling when forceUpdate changes
  useEffect(() => {
    if (forceUpdate) {
      setIsPolling(true);
      previousBalanceRef.current = tokenBalance;
      
      // Clear any existing interval
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
      
      // Start polling every 2 seconds
      pollingIntervalRef.current = setInterval(fetchTokenBalance, 2000);
      
      // Stop polling after 30 seconds as a safety measure
      setTimeout(() => {
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          setIsPolling(false);
        }
      }, 30000);
    }
    
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [forceUpdate]);

  // Initial fetch
  useEffect(() => {
    fetchTokenBalance();
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  if (loading) return (
    <div className="text-sm animate-pulse">
      Loading tokens...
    </div>
  );

  return (
    <div className="token-display">
      <AnimatedTokenDisplay 
        value={tokenBalance} 
        isPolling={isPolling} 
      />
    </div>
  );
};

export default TokenDisplay;

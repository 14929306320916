import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { generateText } from './ChatUI';
import TokenDisplay from './TokenDisplay';
import InsufficientTokensModal from './InsufficientTokensModal';
import TokenPurchase from './TokenPurchase';
import { translateWithBaidu, languageOptions } from '../utils/translation';
import { useTranslation } from 'react-i18next';
import { IoLanguage, IoChevronDown } from "react-icons/io5";
import { motion, AnimatePresence } from 'framer-motion';

const SelectedTextPanel = ({ selectedText, fullPDFText, isDarkMode, isAuthenticated = true, onLogin }) => {
  const { t, i18n } = useTranslation();
  const [translatedText, setTranslatedText] = useState('');
  const [showGenerating, setShowGenerating] = useState(false);
  const [finalSelectedText, setFinalSelectedText] = useState('');
  const [forceTokenUpdate, setForceTokenUpdate] = useState(0);
  const [showTokenDisplay, setShowTokenDisplay] = useState(false);
  const tokenDisplayTimeoutRef = useRef(null);
  const [showInsufficientWarning, setShowInsufficientWarning] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [showTokenPurchase, setShowTokenPurchase] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('zh'); // Default to Chinese simplified
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const langMenuRef = useRef(null);

  useEffect(() => {
    // Initialize selected language from localStorage or default to 'zh'
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFinalSelectedText(selectedText);
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedText]);

  useEffect(() => {
    if (finalSelectedText) {
      handleTranslation(finalSelectedText);
    }
  }, [finalSelectedText, selectedLanguage]); // Re-translate when language changes

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
    
    // Map the Baidu language code to i18n language code
    let i18nLang = 'en'; // Default to English
    
    // Direct mappings
    const langMap = {
      'zh': 'zh',      // Chinese Simplified
      'cht': 'zh-TW',  // Chinese Traditional
      'spa': 'es',     // Spanish
      'ara': 'ar',     // Arabic
      'fra': 'fr',     // French
      'de': 'de',      // German
      'ru': 'ru',      // Russian
      'pt': 'pt',      // Portuguese
      'jp': 'ja',      // Japanese
      'kor': 'ko',     // Korean
      'it': 'it',      // Italian
      'vie': 'vi',     // Vietnamese
      'th': 'th',      // Thai
      'hi': 'hi'       // Hindi
    };
    
    if (langMap[lang]) {
      i18nLang = langMap[lang];
    }
    
    // Only change language if we have translations for it
    // Currently, only English and Chinese are fully supported in the app
    if (['en', 'zh'].includes(i18nLang)) {
      i18n.changeLanguage(i18nLang);
    }
  };

  const handleTranslation = async (text) => {
    const translation = await translateWithBaidu(text, setShowGenerating, selectedLanguage);
    if (translation) {
      setTranslatedText(translation);
    } else {
      setTranslatedText('Translation failed');
    }
  };

  const startTokenDisplayTimer = () => {
    setShowTokenDisplay(true);
    
    // Clear any existing timeout
    if (tokenDisplayTimeoutRef.current) {
      clearTimeout(tokenDisplayTimeoutRef.current);
    }
    
    // Hide token display after 0.5 minute
    tokenDisplayTimeoutRef.current = setTimeout(() => {
      setShowTokenDisplay(false);
    }, 30000);
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (tokenDisplayTimeoutRef.current) {
        clearTimeout(tokenDisplayTimeoutRef.current);
      }
    };
  }, []);

  const handleTranslate = async () => {
    startTokenDisplayTimer();
    setShowGenerating(true);
    setTranslatedText('');
    
    // Determine target language for prompt
    const targetLanguageName = languageOptions.find(lang => lang.value === selectedLanguage)?.label || 'Chinese';
    const prompt = `You are a great researcher, please translate the text to ${targetLanguageName}:`;
    
    // NOTE: The model parameter is currently ignored by the backend, which always uses 'GLM-4-Flash'
    // This is kept for consistency and to support future model selection features
    const model = 'GLM-4-Flash';
    const POLISH_MINIMUM_TOKENS = 1000;

    try {
      let fullResponse = '';
      await generateText(
        prompt,
        finalSelectedText,
        model,
        (content) => {
          fullResponse += content;
          setTranslatedText(fullResponse);
          setShowGenerating(false);
        },
        () => setForceTokenUpdate(prev => prev + 1),
        POLISH_MINIMUM_TOKENS
      );
    } catch (error) {
      if (error.message === 'INSUFFICIENT_TOKENS' || 
          error.message.includes('Insufficient tokens')) {
        setShowInsufficientWarning(true);
        setTranslatedText('');
      } else {
        setTranslatedText('生成过程中出现错误，请重试。');
        console.error('Error:', error);
      }
    } finally {
      setShowGenerating(false);
    }
  };

  // Modify Biaoshu handler to use selected language
  const handleBiaoshu = async () => {
    startTokenDisplayTimer();
    setShowGenerating(true);
    setTranslatedText('');
    
    const targetLanguageName = languageOptions.find(lang => lang.value === selectedLanguage)?.label || 'Chinese';
    const prompt = `You are a great researcher, who is great at applying for NIH funds. Below is the text you want to use as context in your fund application. The application needs to be in ${targetLanguageName}. Please change the tone and language of the text to be suitable for use in this ${targetLanguageName} fund application:`;
    
    const model = 'GLM-4-Flash';
    const POLISH_MINIMUM_TOKENS = 1000;

    try {
      let fullResponse = '';
      await generateText(
        prompt,
        finalSelectedText,
        model,
        (content) => {
          fullResponse += content;
          setTranslatedText(fullResponse);
          setShowGenerating(false);
        },
        () => setForceTokenUpdate(prev => prev + 1),
        POLISH_MINIMUM_TOKENS
      );
    } catch (error) {
      if (error.message === 'INSUFFICIENT_TOKENS' || 
          error.message.includes('Insufficient tokens')) {
        setShowInsufficientWarning(true);
        setTranslatedText('');
      } else {
        setTranslatedText('生成过程中出现错误，请重试。');
        console.error('Error:', error);
      }
    } finally {
      setShowGenerating(false);
    }
  };

  // Update copywrite handler to include language option
  const handleCopywrite = async () => {
    startTokenDisplayTimer();
    setShowGenerating(true);
    setTranslatedText('');
    
    const targetLanguageName = languageOptions.find(lang => lang.value === selectedLanguage)?.label || 'English';
    const prompt = `You are a great researcher, who is great at copywriting scientific papers. Please rewrite it to avoid being considered plagiarism. Your rewrite should be in ${targetLanguageName}:`;
    
    const model = 'GLM-4-Flash';
    const POLISH_MINIMUM_TOKENS = 1000;

    try {
      let fullResponse = '';
      await generateText(
        prompt,
        finalSelectedText,
        model,
        (content) => {
          fullResponse += content;
          setTranslatedText(fullResponse);
          setShowGenerating(false);
        },
        () => setForceTokenUpdate(prev => prev + 1),
        POLISH_MINIMUM_TOKENS
      );
    } catch (error) {
      if (error.message === 'INSUFFICIENT_TOKENS' || 
          error.message.includes('Insufficient tokens')) {
        setShowInsufficientWarning(true);
        setTranslatedText('');
      } else {
        setTranslatedText('生成过程中出现错误，请重试。');
        console.error('Error:', error);
      }
    } finally {
      setShowGenerating(false);
    }
  };

  // Handle closing dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen && !event.target.closest('.language-dropdown')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className={`flex flex-col h-full ${isDarkMode ? 'bg-[#0d1117]' : 'bg-white'}`}>
      <div className={`px-4 border-b ${isDarkMode ? 'border-[#21262d]' : 'border-gray-200'} h-10 flex items-center justify-between flex-shrink-0`}>
        <h2 className={`text-sm font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>{t('translation.translateSelection')}</h2>
        <div className="flex items-center">
          {/* Language dropdown - updated style */}
          <div className="relative language-dropdown mr-3" ref={langMenuRef}>
            <motion.button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className={`flex items-center space-x-1 px-2.5 py-1 rounded-md transition-all duration-200 ${
                isDarkMode 
                  ? 'bg-blue-900/20 text-blue-400 hover:bg-blue-900/30 border border-blue-800/30' 
                  : 'bg-blue-100/80 text-blue-600 hover:bg-blue-200/80 border border-blue-200'
              } backdrop-blur-sm text-xs`}
              whileHover={{ 
                scale: 1.05,
                boxShadow: isDarkMode ? "0 0 8px rgba(59, 130, 246, 0.3)" : "0 0 8px rgba(59, 130, 246, 0.3)"
              }}
              whileTap={{ scale: 0.95 }}
            >
              <IoLanguage className={`mr-1 ${dropdownOpen ? 'animate-pulse' : ''}`} size={16} />
              <span>{languageOptions.find(lang => lang.value === selectedLanguage)?.label?.split(' ')[0] || 'Language'}</span>
              <motion.span
                animate={{ 
                  rotate: dropdownOpen ? 180 : 0 
                }}
                transition={{ duration: 0.3 }}
              >
                <IoChevronDown className="ml-1" size={14} />
              </motion.span>
            </motion.button>
            
            <AnimatePresence>
              {dropdownOpen && (
                <motion.div 
                  className={`absolute right-0 mt-1 z-10 w-48 rounded-md shadow-lg ${
                    isDarkMode ? 'bg-[#1a1f24] border border-[#30363d]' : 'bg-white border border-gray-200'
                  } max-h-64 overflow-y-auto py-1`}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  {languageOptions.map((language) => (
                    <motion.button
                      key={language.value}
                      onClick={() => {
                        handleLanguageChange(language.value);
                        setDropdownOpen(false);
                      }}
                      className={`w-full text-left px-3 py-1.5 text-xs ${
                        selectedLanguage === language.value 
                          ? (isDarkMode ? 'bg-blue-900/30 text-blue-300' : 'bg-blue-100 text-blue-700') 
                          : (isDarkMode ? 'text-gray-300 hover:bg-[#30363d]' : 'text-gray-700 hover:bg-gray-100')
                      }`}
                      whileHover={{ 
                        x: 2,
                        backgroundColor: isDarkMode ? 'rgba(59, 130, 246, 0.2)' : 'rgba(239, 246, 255, 0.9)'
                      }}
                    >
                      {language.label}
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          
          {showTokenDisplay && (
            <div className={`${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
              <TokenDisplay forceUpdate={forceTokenUpdate} />
            </div>
          )}
        </div>
      </div>
      <div className="flex-grow overflow-y-auto p-4 flex flex-col">
        {finalSelectedText && (
          <p className={`font-sans text-sm mb-4 flex-1 overflow-y-auto p-3 rounded-md ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
            {finalSelectedText}
          </p>
        )}
            
        {finalSelectedText && (
          <div className={`font-sans text-sm mt-4 flex-1 overflow-y-auto p-3 rounded-md ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
            {showGenerating ? t('common.loading') : translatedText}
          </div>
        )}
      </div>
      <div className={`h-20 p-4 border-t ${isDarkMode ? 'border-[#21262d]' : 'border-gray-200'} flex-shrink-0`}>
        <div className="flex justify-between space-x-2">
          <button onClick={handleTranslate} className={`
            flex-1 font-medium py-2 px-2 text-xs sm:text-sm rounded-md
            transition-all duration-300 
            ${isDarkMode 
              ? 'bg-[#21262d] hover:bg-[#30363d] text-gray-200' 
              : 'bg-[#f0f0f4] hover:bg-[#e5e5e9] text-gray-800'
            }
          `}>
            <span className="block sm:hidden">{t('common.translate')}</span>
            <span className="hidden sm:block">{t('common.translate')}</span>
          </button>
          <button onClick={handleCopywrite} className={`
            flex-1 font-medium py-2 px-2 text-xs sm:text-sm rounded-md
            transition-all duration-300 
            ${isDarkMode 
              ? 'bg-[#21262d] hover:bg-[#30363d] text-gray-200' 
              : 'bg-[#f0f0f4] hover:bg-[#e5e5e9] text-gray-800'
            }
          `}>
            <span className="block sm:hidden">{t('common.copywrite')}</span>
            <span className="hidden sm:block">{t('common.copywrite')}</span>
          </button>
          <button onClick={handleBiaoshu} className={`
            flex-1 font-medium py-2 px-2 text-xs sm:text-sm rounded-md
            transition-all duration-300 
            ${isDarkMode 
              ? 'bg-[#21262d] hover:bg-[#30363d] text-gray-200' 
              : 'bg-[#f0f0f4] hover:bg-[#e5e5e9] text-gray-800'
            }
          `}>
            <span className="block sm:hidden">{t('common.biaoshu')}</span>
            <span className="hidden sm:block">{t('common.biaoshu')}</span>
          </button>
        </div>
      </div>
      
      {/* Modals */}
      {showInsufficientWarning && (
        <InsufficientTokensModal
          onClose={() => setShowInsufficientWarning(false)}
          onPurchase={() => {
            setShowInsufficientWarning(false);
            setShowTokenPurchase(true);
          }}
          requiredTokens={1000}
          currentBalance={currentBalance}
          isDarkMode={isDarkMode}
          isAuthenticated={isAuthenticated}
          onLogin={onLogin}
        />
      )}
      
      {showTokenPurchase && (
        <TokenPurchase
          onClose={() => setShowTokenPurchase(false)}
          onSuccess={() => {
            setForceTokenUpdate(prev => prev + 1);
            startTokenDisplayTimer();
          }}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default SelectedTextPanel;
import React, { useState, useRef, useEffect } from 'react';
import { IoClose, IoLanguage, IoRemove, IoExpand, IoContract } from "react-icons/io5";
import { generateText, checkTokenBalance } from './ChatUI';
import { encode } from 'gpt-tokenizer'
import TokenDisplay from './TokenDisplay';
import InsufficientTokensModal from './InsufficientTokensModal';
import TokenPurchase from './TokenPurchase';

const TextPolishModal = ({ isDarkMode, onClose, forceTokenUpdate, onTokenUpdate, isAuthenticated = true, onLogin }) => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [activeStyle, setActiveStyle] = useState('english');
  const [activeLength, setActiveLength] = useState('same');
  
  // Window state
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [size, setSize] = useState({ width: 1200, height: 800 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isResizing, setIsResizing] = useState(false);
  const [resizeEdge, setResizeEdge] = useState(null);
  const minSize = { width: 800, height: 600 }; // Minimum window size

  // Add new state for dock behavior
  const [isDocked, setIsDocked] = useState(false);

  const [tokenCount, setTokenCount] = useState(0);

  const windowRef = useRef(null);
  const headerRef = useRef(null);

  // Add useEffect to initialize maximized state
  useEffect(() => {
    setIsMaximized(true);
  }, []);

  // Dragging functionality
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging && !isMaximized) {
        const newX = e.clientX - dragOffset.x;
        const newY = e.clientY - dragOffset.y;
        setPosition({ x: newX, y: newY });
      }
      
      if (isResizing && !isMaximized) {
        const rect = windowRef.current.getBoundingClientRect();
        let newWidth = size.width;
        let newHeight = size.height;
        let newX = position.x;
        let newY = position.y;

        switch (resizeEdge) {
          case 'right':
            newWidth = Math.max(minSize.width, e.clientX - rect.left);
            break;
          case 'bottom':
            newHeight = Math.max(minSize.height, e.clientY - rect.top);
            break;
          case 'left':
            const deltaX = rect.left - e.clientX;
            newWidth = Math.max(minSize.width, rect.width + deltaX);
            newX = e.clientX;
            break;
          case 'top':
            const deltaY = rect.top - e.clientY;
            newHeight = Math.max(minSize.height, rect.height + deltaY);
            newY = e.clientY;
            break;
          case 'bottom-right':
            newWidth = Math.max(minSize.width, e.clientX - rect.left);
            newHeight = Math.max(minSize.height, e.clientY - rect.top);
            break;
          case 'bottom-left':
            newWidth = Math.max(minSize.width, rect.right - e.clientX);
            newHeight = Math.max(minSize.height, e.clientY - rect.top);
            newX = e.clientX;
            break;
          case 'top-right':
            newWidth = Math.max(minSize.width, e.clientX - rect.left);
            newHeight = Math.max(minSize.height, rect.bottom - e.clientY);
            newY = e.clientY;
            break;
          case 'top-left':
            newWidth = Math.max(minSize.width, rect.right - e.clientX);
            newHeight = Math.max(minSize.height, rect.bottom - e.clientY);
            newX = e.clientX;
            newY = e.clientY;
            break;
        }

        setSize({ width: newWidth, height: newHeight });
        if (resizeEdge.includes('left') || resizeEdge.includes('top')) {
          setPosition({ x: newX, y: newY });
        }
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setIsResizing(false);
      setResizeEdge(null);
    };

    if (isDragging || isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isResizing, dragOffset, isMaximized, resizeEdge, size.width, size.height, position.x, position.y]);

  const handleMouseDown = (e) => {
    if (headerRef.current && headerRef.current.contains(e.target) && !isMaximized) {
      setIsDragging(true);
      const rect = windowRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  };

  const toggleMinimize = () => {
    if (isMinimized) {
      setIsMinimized(false);
      setIsDocked(false);
    } else {
      setIsMinimized(true);
      setIsDocked(true);
      if (isMaximized) setIsMaximized(false);
    }
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
    if (isMinimized) setIsMinimized(false);
  };

  const [showInsufficientWarning, setShowInsufficientWarning] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [showTokenPurchase, setShowTokenPurchase] = useState(false);

  const handlePolish = async () => {
    if (!inputText.trim()) {
      alert('请输入需要润色的文本');
      return;
    }

    setIsGenerating(true);
    setOutputText('');
    
    let prompt = "You are a professional academic writer. ";
    
    // Add style-specific prompts
    if (activeStyle === 'chinese') {
      prompt += "Please help rewrite the following text to make it more professional and suitable for a Chinese academic paper. The response must be in Chinese.";
    } else if (activeStyle === 'english') {
      prompt += "Please help rewrite the following text to make it more professional and suitable for an English academic paper. The response must be in English.";
    } else {
      prompt += "Please help rewrite the following text to make it more professional and suitable for a grant application. The response must be in Chinese.";
    }

    // Add length instructions
    if (activeLength === 'shorter') {
      prompt += " Make the response more concise, about 50% of the original length.";
    } else if (activeLength === 'longer') {
      prompt += " Expand the content with more details, about 200% of the original length.";
    } else {
      prompt += " Keep the response length similar to the original.";
    }

    try {
      // First check balance
      const balance = await checkTokenBalance();
      setCurrentBalance(balance); // Set the current balance
      
      const POLISH_MINIMUM_TOKENS = 1000;  // Lower minimum for polish
      
      if (balance < POLISH_MINIMUM_TOKENS) {
        setShowInsufficientWarning(true);
        return;
      }

      let fullResponse = '';
      await generateText(
        prompt,
        inputText,
        'GLM-4-Flash', // NOTE: The model parameter is currently ignored by the backend, which always uses 'GLM-4-Flash'
        (content) => {
          fullResponse += content;
          setOutputText(fullResponse);
        },
        () => {
          // Update both local and parent token states
          onTokenUpdate(prev => prev + 1);
        },
        POLISH_MINIMUM_TOKENS  // Pass the minimum tokens requirement
      );
    } catch (error) {
      if (error.message === 'INSUFFICIENT_TOKENS' || 
          error.message.includes('Insufficient tokens')) {
        setShowInsufficientWarning(true);
        setOutputText('');
      } else {
        setOutputText('生成过程中出现错误，请重试。');
        console.error('Error:', error);
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const updateTokenCount = (text) => {
    const tokens = encode(text);
    setTokenCount(tokens.length);
  };

  // Add resize handles JSX
  const ResizeHandles = () => (
    <>
      <div 
        className="absolute top-0 left-0 w-2 h-2 cursor-nw-resize" 
        onMouseDown={startResize('top-left')} 
      />
      <div 
        className="absolute top-0 right-0 w-2 h-2 cursor-ne-resize" 
        onMouseDown={startResize('top-right')} 
      />
      <div 
        className="absolute bottom-0 left-0 w-2 h-2 cursor-sw-resize" 
        onMouseDown={startResize('bottom-left')} 
      />
      <div 
        className="absolute bottom-0 right-0 w-2 h-2 cursor-se-resize" 
        onMouseDown={startResize('bottom-right')} 
      />
      <div 
        className="absolute top-0 left-2 right-2 h-1 cursor-n-resize" 
        onMouseDown={startResize('top')} 
      />
      <div 
        className="absolute bottom-0 left-2 right-2 h-1 cursor-s-resize" 
        onMouseDown={startResize('bottom')} 
      />
      <div 
        className="absolute left-0 top-2 bottom-2 w-1 cursor-w-resize" 
        onMouseDown={startResize('left')} 
      />
      <div 
        className="absolute right-0 top-2 bottom-2 w-1 cursor-e-resize" 
        onMouseDown={startResize('right')} 
      />
    </>
  );

  const startResize = (edge) => (e) => {
    if (!isMaximized) {
      e.preventDefault();
      setIsResizing(true);
      setResizeEdge(edge);
    }
  };

  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      <div
        ref={windowRef}
        style={{
          transform: isMaximized 
            ? 'none' 
            : isDocked 
              ? 'translate(20px, calc(100vh - 60px)) scale(0.2)' 
              : isMinimized 
                ? `translate(${position.x}px, calc(100vh - 40px))` 
                : `translate(${position.x}px, ${position.y}px)`,
          width: isMaximized 
            ? '100%' 
            : isMinimized 
              ? '300px' 
              : `${size.width}px`,
          height: isMaximized 
            ? '100%' 
            : isMinimized 
              ? '40px' 
              : `${size.height}px`,
          transition: 'all 0.3s ease',
          opacity: isDocked ? 0.8 : 1,
          transformOrigin: 'bottom left'
        }}
        className={`fixed shadow-2xl rounded-lg overflow-hidden pointer-events-auto relative
          ${isDarkMode ? 'bg-gray-900' : 'bg-white'}
          ${isDocked ? 'hover:scale-105 hover:opacity-100' : ''}`}
      >
        {!isMaximized && !isMinimized && <ResizeHandles />}
        
        {/* Header */}
        <header 
          ref={headerRef}
          onMouseDown={handleMouseDown}
          className={`flex flex-grow px-5 py-2 border-b cursor-move
            ${isDarkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'}`}
        >
          <div className="flex-none">
            <div className="flex flex-grow mt-1">
              <img
                src="/logo192.png"
                alt="Logo"
                className="mt-1 h-5 w-auto"
              />
              <div className={`ml-2 my-auto text-lg font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                润色喵
              </div>
            </div>
          </div>

          <div className="flex-grow flex items-center justify-center">
            <div className={`${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
              <TokenDisplay forceUpdate={forceTokenUpdate} />
            </div>
          </div>

          <div className="flex-none flex items-center space-x-2">
            <button
              onClick={toggleMinimize}
              className={`rounded-full w-8 h-8 flex items-center justify-center
                ${isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
            >
              <IoRemove size={20} className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} />
            </button>
            <button
              onClick={toggleMaximize}
              className={`rounded-full w-8 h-8 flex items-center justify-center
                ${isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
            >
              {isMaximized ? 
                <IoContract size={20} className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} /> : 
                <IoExpand size={20} className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} />
              }
            </button>
            <button
              onClick={onClose}
              className={`rounded-full w-8 h-8 flex items-center justify-center
                ${isDarkMode ? 'hover:bg-red-900' : 'hover:bg-red-100'}`}
            >
              <IoClose size={20} className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} />
            </button>
          </div>
        </header>

        {/* Content */}
        {!isMinimized && (
          <div className={`flex-1 h-[calc(100%-3rem)] overflow-auto ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
            <div className="flex">
              <div className="py-4 z-10">
                <button className="rounded px-3 py-1 bg-blue-100 border border-blue-200 text-base text-blue-700 font-semibold focus:outline-none focus:ring-1 focus:ring-blue-600">
                  <IoLanguage className="inline mr-1" />
                  Text
                </button>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="pb-4 top-32 h z-10 w-full">
                <div className={`rounded-lg border drop-shadow-sm shadow-sm
                  ${isDarkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'}`}>
                  <div className={`flex border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}>
                    <div className="w-5/12">
                      <nav className="flex flex-row rounded-tl-lg">
                        {[
                          { id: 'shorter', label: '缩写一点' },
                          { id: 'same', label: '字数相同' },
                          { id: 'longer', label: '扩写一点' }
                        ].map(length => (
                          <button
                            key={length.id}
                            onClick={() => setActiveLength(length.id)}
                            className={`uppercase py-4 px-4 text-sm lg:text-sm font-semibold transition-colors duration-100 ${
                              activeLength === length.id
                                ? 'text-blue-500 border-b-2 border-blue-500'
                                : 'text-gray-600 hover:hover:text-gray-100'
                            }`}
                          >
                            {length.label}
                          </button>
                        ))}
                      </nav>
                    </div>

                    <div className="w-2/12 flex items-center justify-center">
                      <button
                        onClick={handlePolish}
                        disabled={isGenerating}
                        className={`bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-md px-6 py-2 
                          transition-colors duration-100 disabled:opacity-50 disabled:cursor-not-allowed
                          ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : ''}`}
                      >
                        {isGenerating ? '润色中...' : '润色成'}
                      </button>
                    </div>

                    <div className="w-5/12">
                      <nav className="flex flex-row rounded-tl-lg">
                        {[
                          { id: 'chinese', label: '中文论文' },
                          { id: 'english', label: '英文论文' },
                          { id: 'grant', label: '标书' }
                        ].map(style => (
                          <button
                            key={style.id}
                            onClick={() => setActiveStyle(style.id)}
                            className={`uppercase py-4 px-4 text-sm lg:text-sm font-semibold transition-colors duration-100 ${
                              activeStyle === style.id
                                ? 'text-blue-500 border-b-2 border-blue-500'
                                : 'text-gray-600 hover:text-gray-100'
                            }`}
                          >
                            {style.label}
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>

                  <div className="flex min-h-[400px]">
                    <div className={`w-6/12 border-r p-6 relative
                      ${isDarkMode ? 'border-gray-700 bg-gray-900' : 'border-gray-200'}`}>
                      <textarea
                        value={inputText}
                        onChange={(e) => {
                          setInputText(e.target.value);
                          updateTokenCount(e.target.value);
                        }}
                        className={`resize-none w-full h-full min-h-[360px] focus:outline-none text-base lg:text-l
                          placeholder-gray-400 custom-scrollbar
                          ${isDarkMode ? 'bg-gray-900 text-gray-200' : 'bg-white text-black'}`}
                        spellCheck="false"
                        placeholder="在这里填入你需要润色翻译或者加工的语言，可以是任何语言，可以是任何语境,可以变成英文，中文，可以扩写或者缩写..."
                      />
                      <div className={`absolute bottom-2 right-6 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        预计Token数: {tokenCount}
                      </div>
                    </div>

                    <div className={`w-6/12 p-6 relative placeholder-gray-400
                      ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
                      <div className={`whitespace-pre-line lg:text-l text-base custom-scrollbar min-h-[360px]
                        ${isDarkMode ? 'text-gray-200' : 'text-black'}`}>
                        {outputText || <span className="text-gray-400">润色/翻译后的文本将显示在这里...</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Simulated Dock */}
      {isDocked && (
        <div className="fixed bottom-0 left-0 right-0 h-12 bg-gray-800/80 backdrop-blur-md pointer-events-auto flex items-center px-4 z-50">
          <div 
            onClick={() => {
              setIsDocked(false);
              setIsMinimized(false);
            }}
            className="flex items-center space-x-2 px-3 py-1 rounded-md hover:bg-white/10 cursor-pointer"
          >
            <img
              src="/logo192.png"
              alt="Logo"
              className="h-6 w-6"
            />
            <span className="text-white text-sm">润色</span>
          </div>
        </div>
      )}

      {showInsufficientWarning && (
        <InsufficientTokensModal
          onClose={() => setShowInsufficientWarning(false)}
          onPurchase={() => {
            setShowInsufficientWarning(false);
            setShowTokenPurchase(true);
          }}
          requiredTokens={1000}
          currentBalance={currentBalance}
          isDarkMode={isDarkMode}
          isAuthenticated={isAuthenticated}
          onLogin={onLogin}
        />
      )}
      
      {showTokenPurchase && (
        <TokenPurchase 
          isDarkMode={isDarkMode} 
          onClose={() => setShowTokenPurchase(false)}
        />
      )}
    </div>
  );
};

export default TextPolishModal; 
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import EmailLoginForm from './EmailLoginForm';
import { IoLogoWechat, IoClose, IoArrowForward, IoQrCode } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

const LoginModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const iframeRef = useRef(null);
  const [activeTab, setActiveTab] = useState('login'); // 'login' or 'register'

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        // Determine the current hostname for the bridge page redirect
        const bridgePath = `${window.location.origin}/wechat-login-bridge.html`;
        const encodedBridgePath = encodeURIComponent(bridgePath);
        
        // Append the redirect_to parameter to tell the backend where to redirect
        const response = await fetch(`https://api.labcat.com.cn/wechat/qr-code?redirect_to=${encodedBridgePath}`);
        const data = await response.json();
        setQrCodeUrl(data.qr_code_url);
      } catch (error) {
        console.error('Error fetching QR code:', error);
      }
    };

    if (isOpen) {
      fetchQRCode();
    }
  }, [isOpen]);

  // Add message event listener to handle WeChat login data sent via postMessage
  useEffect(() => {
    const handleMessage = (event) => {
      // Only accept messages from our own origins
      const validOrigins = [
        'https://api.labcat.com.cn',
        window.location.origin,
        'http://localhost:3000'
      ];
      
      if (!validOrigins.includes(event.origin)) {
        return;
      }
      
      try {
        // Check if the message contains WeChat login data
        if (event.data && typeof event.data === 'string' && event.data.includes('wechat_login_data')) {
          const urlParams = new URLSearchParams(event.data);
          const loginData = urlParams.get('wechat_login_data');
          
          if (loginData) {
            const data = JSON.parse(decodeURIComponent(loginData));
            console.log('Received WeChat login data via postMessage:', data);
            
            // Store the token and user info
            localStorage.setItem('wechat_user_info', JSON.stringify(data.user_info));
            const cleanToken = data.token.replace(/^b['"]|['"]$/g, '');
            localStorage.setItem('wechat_token', cleanToken);
            localStorage.setItem('access_token', cleanToken); // Set as main token
            localStorage.setItem('auth_provider', 'wechat'); // Track auth provider
            
            // Reload the page to apply the login
            window.location.reload();
          }
        }
      } catch (error) {
        console.error('Error processing message:', error);
      }
    };
    
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleLoginSuccess = (data) => {
    // Store the token and user info in localStorage
    localStorage.setItem('access_token', data.token);
    
    // Reload the page to apply the login
    window.location.reload();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black/60 backdrop-blur-sm flex items-center justify-center p-4">
      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.2 }}
        className="bg-white dark:bg-gray-900 rounded-2xl shadow-[0_0_30px_rgba(0,0,0,0.2)] ring-1 ring-gray-200 dark:ring-gray-800 w-full max-w-5xl overflow-hidden relative"
      >
        {/* Common welcome message for both login methods */}
        <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-800 bg-gradient-to-r from-blue-600 to-indigo-700 text-white p-4">
          <div>
            <h1 className="text-2xl font-bold">{t('loginModal.welcome')}</h1>
            <p className="text-sm opacity-90">{t('loginModal.description')}</p>
          </div>
          <button 
            onClick={onClose}
            className="p-2 rounded-full bg-white/20 hover:bg-white/30 transition-colors z-10"
            aria-label="Close"
          >
            <IoClose className="text-white" size={18} />
          </button>
        </div>

        {/* Top tabs for switching between login and register */}
        <div className="flex border-b border-gray-200 dark:border-gray-800">
          <button 
            onClick={() => setActiveTab('login')}
            className={`flex-1 px-6 py-3 text-sm font-semibold ${
              activeTab === 'login'
                ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
          >
            {t('emailLogin.signIn')}
          </button>
          <button 
            onClick={() => setActiveTab('register')}
            className={`flex-1 px-6 py-3 text-sm font-semibold ${
              activeTab === 'register'
                ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
          >
            {t('emailLogin.createAccount')}
          </button>
        </div>

        <div className="flex flex-col md:flex-row">
          {/* Left Column - WeChat Login */}
          <div className="w-full md:w-2/5 bg-gradient-to-br from-blue-600/90 to-indigo-700/90 p-8 flex flex-col justify-between">
            <div className="text-white">
              {/* WeChat QR Code Section */}
              <div className="bg-white rounded-xl shadow-md p-4 mb-6 border border-gray-100 ring-1 ring-white/20">
                <div className="flex items-center mb-3">
                  <IoLogoWechat className="text-green-500 text-xl mr-2" />
                  <h3 className="text-gray-800 font-medium">{t('loginModal.wechatLogin')}</h3>
                </div>
                <div className="bg-white rounded-lg overflow-hidden aspect-square w-full mb-4">
                  {qrCodeUrl ? (
                    <iframe
                      ref={iframeRef}
                      src={qrCodeUrl}
                      className="w-full h-full"
                      frameBorder="0"
                      scrolling="no"
                      allow="cross-origin-isolated"
                      title="WeChat Login QR Code"
                      style={{ 
                        clipPath: 'inset(0px 0px 0px 0px)',
                        transform: 'scale(1)',
                        marginTop: '-40px',
                        pointerEvents: 'none'
                      }}
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-100">
                      <div className="flex flex-col items-center">
                        <IoQrCode className="text-4xl text-gray-400 mb-2" />
                        <div className="text-gray-500 text-sm">{t('loginModal.loadingQR')}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            <div className="bg-white/20 backdrop-blur-sm rounded-lg p-3 mt-auto ring-1 ring-white/30">
              <p className="text-white text-xs">{t('loginModal.privacyNote')}</p>
            </div>
          </div>

          {/* Right Column - Email Login */}
          <div className="w-full md:w-3/5 p-8 relative">
            {/* New user promotion - with responsive design to fit better */}
            {activeTab === 'login' && (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mb-6 p-4 border border-blue-100 dark:border-blue-900 bg-blue-50 dark:bg-blue-900/30 rounded-lg"
              >
                <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-3">
                  <div>
                    <h3 className="text-lg font-medium text-blue-800 dark:text-blue-300">{t('emailLogin.needAccount')}</h3>
                    <p className="text-sm text-blue-600 dark:text-blue-400">Join now to access all features.</p>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setActiveTab('register')}
                    className="bg-blue-600 text-white px-4 py-2 rounded-full flex items-center justify-center space-x-1 text-sm font-medium shadow-md hover:bg-blue-700 transition-colors"
                  >
                    <span>{t('emailLogin.createAccount')}</span>
                    <IoArrowForward size={16} />
                  </motion.button>
                </div>
              </motion.div>
            )}

            {/* If register tab is active, show a promotion for existing users */}
            {activeTab === 'register' && (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mb-6 p-4 border border-green-100 dark:border-green-900 bg-green-50 dark:bg-green-900/30 rounded-lg"
              >
                <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-3">
                  <div>
                    <h3 className="text-lg font-medium text-green-800 dark:text-green-300">{t('emailLogin.alreadyHaveAccount')}</h3>
                    <p className="text-sm text-green-600 dark:text-green-400">Welcome back!</p>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setActiveTab('login')}
                    className="bg-green-600 text-white px-4 py-2 rounded-full flex items-center justify-center space-x-1 text-sm font-medium shadow-md hover:bg-green-700 transition-colors"
                  >
                    <span>{t('emailLogin.signIn')}</span>
                    <IoArrowForward size={16} />
                  </motion.button>
                </div>
              </motion.div>
            )}

            {/* Pass the initial active view to EmailLoginForm */}
            <EmailLoginForm 
              onLoginSuccess={handleLoginSuccess} 
              initialActiveView={activeTab === 'login' ? 'login' : 'register'} 
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default LoginModal;

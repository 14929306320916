import journalData from "../data/ifqbt.json";

const processedJournalData = journalData.reduce((acc, journal) => {
  const key = journal.journal.toLowerCase();
  acc[key] = {
    if: journal.IF,
    q: journal.Q,
    b: journal.B,
    t: journal.T,
  };
  if (journal.jcr) {
    acc[journal.jcr.toLowerCase()] = {
      if: journal.IF,
      q: journal.Q,
      b: journal.B,
      t: journal.T,
    };
  }
  return acc;
}, {});

export function getJournalMetrics(journalName) {
  const key = journalName.toLowerCase();
  return processedJournalData[key] || null;
}

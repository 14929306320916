import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoCheckmarkCircle, IoAlertCircle } from 'react-icons/io5';

const PaymentSuccess = () => {
  const [message, setMessage] = useState('Processing your payment...');
  const [isSuccess, setIsSuccess] = useState(true);
  const [tokens, setTokens] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        // Get the session ID from the URL
        const urlParams = new URLSearchParams(location.search);
        const sessionId = urlParams.get('session_id');

        if (!sessionId) {
          setIsSuccess(false);
          setMessage('Payment verification failed. No session ID found.');
          return;
        }

        // Get token for authentication
        const authProvider = localStorage.getItem('auth_provider');
        let token;
        
        if (authProvider === 'email') {
          token = localStorage.getItem('email_token');
        } else {
          // Default to wechat token
          token = localStorage.getItem('wechat_token');
        }
        
        // Use access_token as fallback
        if (!token) {
          token = localStorage.getItem('access_token');
        }
        
        if (!token) {
          setIsSuccess(false);
          setMessage('Authentication error. Please login and try again.');
          setTimeout(() => navigate('/'), 3000);
          return;
        }

        // Verify payment with backend
        const response = await fetch(`https://api.labcat.com.cn/stripe/success?session_id=${sessionId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to verify payment');
        }

        const data = await response.json();
        
        if (data.success) {
          setIsSuccess(true);
          setMessage(data.message || 'Payment successful! Your tokens have been added to your account.');
          
          // Get updated token balance
          const balanceResponse = await fetch('https://api.labcat.com.cn/api/tokens/balance', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          if (balanceResponse.ok) {
            const balanceData = await balanceResponse.json();
            setTokens(balanceData.tokens_remaining);
          }
          
          // Redirect back to main app after 3 seconds
          setTimeout(() => navigate('/'), 3000);
        } else {
          setIsSuccess(true);
          setMessage(data.message || 'Payment received. Processing your tokens...');
          setTimeout(() => navigate('/'), 3000);
        }
      } catch (error) {
        console.error('Payment verification error:', error);
        setIsSuccess(false);
        setMessage('There was an error verifying your payment. Please contact support.');
        setTimeout(() => navigate('/'), 5000);
      }
    };

    verifyPayment();
  }, [navigate, location.search]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-md">
      <div className="bg-[#0d1117] rounded-xl border border-green-500/30 shadow-2xl p-8 max-w-md w-full">
        <div className="flex flex-col items-center space-y-6 text-center">
          {isSuccess ? (
            <div className="text-6xl text-green-500 mb-2">
              <IoCheckmarkCircle />
            </div>
          ) : (
            <div className="text-6xl text-red-500 mb-2">
              <IoAlertCircle />
            </div>
          )}
          
          <h2 className="text-2xl font-bold text-white font-rajdhani">
            {isSuccess ? 'Payment Successful!' : 'Payment Issue'}
          </h2>
          
          <p className="text-gray-300">
            {message}
          </p>
          
          {tokens && (
            <div className="mt-4">
              <p className="text-gray-400">Current token balance:</p>
              <p className="text-2xl font-bold text-green-500">{tokens.toLocaleString()}</p>
            </div>
          )}
          
          <div className="text-sm text-gray-400 mt-2">
            Redirecting you back to the app...
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess; 
import React from 'react';
import AnimatedTokenDisplay from './AnimatedTokenDisplay';
import { IoLogIn } from 'react-icons/io5';

const InsufficientTokensModal = ({ 
  onClose, 
  onPurchase, 
  requiredTokens = 100, 
  currentBalance = 0, 
  isDarkMode,
  isAuthenticated = true,
  onLogin
}) => {
  const handleClose = (e) => {
    // Prevent event from bubbling up
    e.stopPropagation();
    onClose();
  };

  const handleLogin = (e) => {
    e.stopPropagation();
    onClose(); // Close modal first
    if (onLogin) onLogin(); // Then open login modal
  };

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50"
      onClick={handleClose} // Close when clicking backdrop
    >
      <div 
        className={`relative w-full max-w-md rounded-lg shadow-2xl p-6 ${isDarkMode ? 'bg-[#1a1b26] text-white' : 'bg-white text-black'}`}
        onClick={e => e.stopPropagation()} // Prevent closing when clicking modal content
      >
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 to-orange-500"></div>
        
        <div className="mb-4 text-center">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h2 className="text-2xl font-bold mb-2">余额不足</h2>
          <div className="flex justify-center mb-4">
            <AnimatedTokenDisplay 
              value={currentBalance}
              isPolling={false}
            />
          </div>
          <div className="text-lg font-bold text-green-500 mb-4">
            需要: {requiredTokens.toLocaleString()} tokens
          </div>
          
          {!isAuthenticated && (
            <div className={`text-sm mb-4 p-2 rounded ${isDarkMode ? 'bg-blue-900/30 text-blue-200' : 'bg-blue-50 text-blue-700'}`}>
              登录后可获得免费Token
            </div>
          )}
        </div>

        <div className="flex justify-center gap-4">
          <button
            onClick={handleClose}  // Use handleClose instead of onClose directly
            className={`px-4 py-2 rounded-md ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600' 
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            取消
          </button>
          
          {!isAuthenticated && onLogin && (
            <button
              onClick={handleLogin}
              className="px-4 py-2 rounded-md bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white flex items-center gap-1"
            >
              <IoLogIn className="text-sm" />
              登录
            </button>
          )}
          
          <button
            onClick={(e) => {
              e.stopPropagation();
              onPurchase();
            }}
            className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white"
          >
            购买Token
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientTokensModal; 
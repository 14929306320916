import React, { useEffect, useState } from 'react';
import { IoClose, IoDownload } from 'react-icons/io5';
import packageJson from '../../package.json';

const UpdateNotification = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [versionInfo, setVersionInfo] = useState(null);
  const currentVersion = packageJson.version;
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('idle');

  const getOSType = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.includes('windows')) return 'windows';
    if (userAgent.includes('macintosh')) return 'mac';
    return 'windows';
  };

  const osType = getOSType();

  useEffect(() => {
    // Store the current version in localStorage to track updates
    const lastRunVersion = localStorage.getItem('appVersion');
    
    // If this is the first run after an update, don't show update notification
    if (lastRunVersion !== currentVersion) {
      localStorage.setItem('appVersion', currentVersion);
      
      // If this isn't the first run of the app (lastRunVersion exists),
      // and we've updated to a newer version, show a "successfully updated" message
      if (lastRunVersion && compareVersions(currentVersion, lastRunVersion)) {
        // Optional: Show a toast or notification that update was successful
        console.log(`Successfully updated from ${lastRunVersion} to ${currentVersion}`);
      }
    }

    const eventSource = new EventSource('https://api.labcat.com.cn/updates/check');

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const isNewer = compareVersions(data.version, currentVersion);
      if (data.version && isNewer) {
        setVersionInfo(data);
        setShowNotification(true);
      }
    };

    const electron = window.electron;
    if (electron?.ipcRenderer) {
      const removeProgressListener = electron.ipcRenderer.on('download-progress', (progress) => {
        setDownloadProgress(progress);
      });

      const removeCompleteListener = electron.ipcRenderer.on('download-complete', (path) => {
        setIsDownloading(false);
        setDownloadProgress(0);
        setUpdateStatus('installing');
      });

      const removeInstallingListener = electron.ipcRenderer.on('update-installing', () => {
        setUpdateStatus('installing');
      });

      const removeFailedListener = electron.ipcRenderer.on('download-failed', (error) => {
        setIsDownloading(false);
        setDownloadProgress(0);
        setUpdateStatus('idle');
        alert(`下载失败: ${error}`);
      });

      return () => {
        eventSource.close();
        removeProgressListener?.();
        removeCompleteListener?.();
        removeInstallingListener?.();
        removeFailedListener?.();
      };
    }

    return () => {
      eventSource.close();
    };
  }, [currentVersion]);

  const compareVersions = (newVersion, currentVersion) => {
    const newParts = newVersion.split('.').map(Number);
    const currentParts = currentVersion.split('.').map(Number);
    
    for (let i = 0; i < newParts.length; i++) {
      if (newParts[i] > currentParts[i]) return true;
      if (newParts[i] < currentParts[i]) return false;
    }
    return false;
  };

  if (!showNotification || !versionInfo) return null;

  const downloadUrl = versionInfo.downloads[osType]?.url;

  const handleDownload = async () => {
    const electron = window.electron;
    if (!electron?.ipcRenderer) {
      alert('Downloads are only available in the desktop app');
      return;
    }

    try {
      setIsDownloading(true);
      const result = await electron.ipcRenderer.invoke('download-update', {
        url: downloadUrl,
        version: versionInfo.version
      });

      if (!result.success) {
        alert('Download failed: ' + result.error);
        setIsDownloading(false);
      }
    } catch (error) {
      console.error('Download error:', error);
      setIsDownloading(false);
      alert('Download failed. Please try again.');
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-3 max-w-[280px] animate-slide-up border border-gray-200 dark:border-gray-700">
        <div className="flex justify-between items-start space-x-2">
          <div className="flex-1 min-w-0">
            <h3 className="font-medium text-sm text-gray-900 dark:text-white truncate">
              发现新版本 {versionInfo.version}
            </h3>
            <p className="text-xs text-gray-600 dark:text-gray-300 mt-1 line-clamp-2">
              {versionInfo.release_notes}
            </p>
            
            {/* Special instructions for users on versions before 0.2.9 */}
            {compareVersions('0.2.9', currentVersion) && (
              <div className="mt-2 p-2 bg-blue-50 dark:bg-blue-900/20 rounded-md">
                <p className="text-xs text-blue-700 dark:text-blue-300">
                  <strong>更新说明:</strong> 下载后，请手动安装新版本。
                  {osType === 'mac' ? 
                    ' 在打开的窗口中，将应用拖到Applications文件夹。' : 
                    ' 运行下载的安装程序并按照提示操作。'}
                </p>
              </div>
            )}
          </div>
          <button
            onClick={() => setShowNotification(false)}
            className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 transition-colors"
          >
            <IoClose className="w-4 h-4" />
          </button>
        </div>
        <div className="mt-2">
          <button
            onClick={handleDownload}
            disabled={isDownloading || updateStatus === 'installing'}
            className="flex items-center justify-center w-full px-3 py-1.5 bg-blue-500 text-xs text-white rounded-md hover:bg-blue-600 transition-colors disabled:bg-blue-300"
          >
            {updateStatus === 'installing' ? (
              <span>正在安装更新...</span>
            ) : isDownloading ? (
              <>
                <span>下载中 {downloadProgress}%</span>
                <div className="w-full bg-blue-200 h-1 mt-1 rounded-full">
                  <div 
                    className="bg-blue-500 h-1 rounded-full transition-all duration-300"
                    style={{ width: `${downloadProgress}%` }}
                  />
                </div>
              </>
            ) : (
              <>
                <IoDownload className="w-3 h-3 mr-1" />
                下载更新
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateNotification; 
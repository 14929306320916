import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { IoMail, IoLockClosed, IoPersonOutline, IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';

const EmailLoginForm = ({ onLoginSuccess, initialActiveView = 'login' }) => {
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState(initialActiveView); // 'login', 'register', or 'forgot'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Update active view when initialActiveView changes
  useEffect(() => {
    setActiveView(initialActiveView);
  }, [initialActiveView]);

  // Check if there's a verified email in localStorage
  useEffect(() => {
    const verifiedEmail = localStorage.getItem('verifiedEmail');
    if (verifiedEmail) {
      setEmail(verifiedEmail);
      setActiveView('login');
      setMessage('Your email has been verified. Please log in with your password.');
      // Remove the stored email after using it
      localStorage.removeItem('verifiedEmail');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setLoading(true);

    try {
      if (activeView === 'forgot') {
        // Handle password reset request
        const response = await fetch('https://api.labcat.com.cn/auth/forgot-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        const data = await response.json();
        setMessage(data.message || t('emailLogin.resetLinkSent'));
      } else if (activeView === 'login') {
        // Handle login
        const response = await fetch('https://api.labcat.com.cn/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || t('emailLogin.loginFailed'));
        }

        const data = await response.json();
        
        // Save token and user info
        localStorage.setItem('email_token', data.token);
        localStorage.setItem('email_user_info', JSON.stringify(data.user_info));
        localStorage.setItem('access_token', data.token);
        localStorage.setItem('auth_provider', 'email');
        
        // Call the success callback
        onLoginSuccess(data);
      } else if (activeView === 'register') {
        // Handle registration
        const response = await fetch('https://api.labcat.com.cn/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password, name }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || t('emailLogin.registrationFailed'));
        }

        const data = await response.json();
        setMessage(data.message || t('emailLogin.registrationSuccess'));
        // Switch to login view after successful registration
        setActiveView('login');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
          {activeView === 'forgot' 
            ? t('emailLogin.resetPassword') 
            : activeView === 'login' 
              ? t('emailLogin.signIn') 
              : t('emailLogin.createAccount')}
        </h2>
        {activeView === 'register' && (
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Fill out the form below to create your account
          </p>
        )}
      </div>

      {error && (
        <motion.div 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg text-sm shadow-sm"
        >
          {error}
        </motion.div>
      )}

      {message && (
        <motion.div 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-4 p-4 bg-green-50 border border-green-200 text-green-600 rounded-lg text-sm shadow-sm"
        >
          {message}
        </motion.div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('emailLogin.email')}
          </label>
          <div className="relative">
            <input
              id="email"
              type="email"
              className="w-full pl-10 pr-3 py-3 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white text-sm shadow-sm"
              placeholder={t('emailLogin.emailPlaceholder')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <IoMail className="text-gray-400" />
            </div>
          </div>
        </div>

        {activeView !== 'forgot' && (
          <div className="space-y-2">
            <div className="flex justify-between">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t('emailLogin.password')}
              </label>
              {activeView === 'login' && (
                <button
                  type="button"
                  className="text-xs text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                  onClick={() => {
                    setActiveView('forgot');
                    setError('');
                    setMessage('');
                  }}
                >
                  {t('emailLogin.forgotPassword')}
                </button>
              )}
            </div>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                className="w-full pl-10 pr-10 py-3 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white text-sm shadow-sm"
                placeholder={t('emailLogin.passwordPlaceholder')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <IoLockClosed className="text-gray-400" />
              </div>
              <div 
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <IoEyeOffOutline className="text-gray-400 hover:text-gray-600" />
                ) : (
                  <IoEyeOutline className="text-gray-400 hover:text-gray-600" />
                )}
              </div>
            </div>
            {activeView === 'register' && (
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1 pl-1">
                {t('emailLogin.passwordRequirements')}
              </p>
            )}
          </div>
        )}

        {activeView === 'register' && (
          <div className="space-y-2">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('emailLogin.name')}
            </label>
            <div className="relative">
              <input
                id="name"
                type="text"
                className="w-full pl-10 pr-3 py-3 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white text-sm shadow-sm"
                placeholder={t('emailLogin.namePlaceholder')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <IoPersonOutline className="text-gray-400" />
              </div>
            </div>
          </div>
        )}

        <motion.button
          type="submit"
          disabled={loading}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={`w-full py-3 px-4 ${
            activeView === 'register'
              ? 'bg-green-600 hover:bg-green-700'
              : 'bg-blue-600 hover:bg-blue-700'
          } text-white font-medium rounded-lg transition-colors duration-200 shadow-md flex justify-center items-center space-x-2 disabled:opacity-70 disabled:cursor-not-allowed`}
        >
          {loading ? (
            <>
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>{t('emailLogin.processing')}</span>
            </>
          ) : (
            <span>
              {activeView === 'forgot'
                ? t('emailLogin.sendResetLink')
                : activeView === 'login'
                  ? t('emailLogin.signIn')
                  : t('emailLogin.register')}
            </span>
          )}
        </motion.button>

        {/* View toggle links at the bottom */}
        {activeView === 'forgot' && (
          <div className="mt-4 text-center">
            <button
              type="button"
              className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 inline-flex items-center"
              onClick={() => {
                setActiveView('login');
                setError('');
                setMessage('');
              }}
            >
              <span>← {t('emailLogin.backToLogin')}</span>
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default EmailLoginForm; 
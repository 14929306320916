import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import TokenPurchase from './TokenPurchase';
import TokenDisplay from './TokenDisplay';
import AnimatedTokenDisplay from './AnimatedTokenDisplay';
import InsufficientTokensModal from './InsufficientTokensModal';
import { useTranslation } from 'react-i18next';

export const checkTokenBalance = async () => {
  try {
    // Get the authentication provider and the appropriate token
    const authProvider = localStorage.getItem('auth_provider');
    let token;
    
    if (authProvider === 'email') {
      token = localStorage.getItem('email_token');
    } else {
      // Default to wechat token for backward compatibility
      token = localStorage.getItem('wechat_token');
    }
    
    // Use the access_token as fallback if specific token not found
    if (!token) {
      token = localStorage.getItem('access_token');
    }
    
    if (!token) {
      throw new Error('No authentication token found');
    }
    
    // Use the same API endpoint for both auth providers
    const response = await fetch('https://api.labcat.com.cn/api/tokens/balance', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (response.ok) {
      const data = await response.json();
      return data.tokens_remaining;
    }
    throw new Error('Failed to fetch token balance');
  } catch (error) {
    console.error('Error checking token balance:', error);
    return 0;
  }
};

export const generateText = async (prompt, text, model, onContentReceived, onComplete, minimumTokens = 1000) => {
  const balance = await checkTokenBalance();
  
  if (balance < minimumTokens) {
    throw new Error('INSUFFICIENT_TOKENS');
  }

  const API_URL = 'https://api.labcat.com.cn/moonshot/generate_moonshot';
  const MAX_RETRIES = 3;
  let retries = 0;

  // Get the authentication provider and the appropriate token
  const authProvider = localStorage.getItem('auth_provider');
  let token;
  
  if (authProvider === 'email') {
    token = localStorage.getItem('email_token');
  } else {
    // Default to wechat token for backward compatibility
    token = localStorage.getItem('wechat_token');
  }
  
  // Use the access_token as fallback if specific token not found
  if (!token) {
    token = localStorage.getItem('access_token');
  }
  
  if (!token) {
    throw new Error('No authentication token found. Please login.');
  }

  // Token estimation
  const estimateTokens = (str) => str.split(/\s+/).length;
  const MAX_TOKENS = 15000;

  if (estimateTokens(prompt + text) > MAX_TOKENS) {
    console.warn("Warning: The combined prompt and text may exceed the token limit.");
    onContentReceived("Warning: The input text is too long and may exceed the token limit. The response might be incomplete.\n\n");
  }

  while (retries < MAX_RETRIES) {
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,  // Use token directly
        },
        body: JSON.stringify({
          prompt,
          text,
          model,
        }),
      });

      if (!response.ok) {
        // Handle specific error cases
        if (response.status === 401) {
          throw new Error('Authentication failed. Please login again.');
        }
        if (response.status === 402) {
          throw new Error('Insufficient tokens. Please purchase more tokens.');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.body) {
        throw new Error('Failed to get a valid response body from the API');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        
        const lines = chunk.split('\n');
        for (const line of lines) {
          if (line.trim().startsWith('data:') && line.trim() !== 'data: [DONE]') {
            try {
              const jsonData = JSON.parse(line.substring(5));
              if (jsonData.error) {
                // Handle specific error messages
                if (jsonData.error.includes('Insufficient tokens')) {
                  onContentReceived('\n\nError: Insufficient tokens. Please purchase more tokens.\n');
                  throw new Error('Insufficient tokens');
                }
                console.error('Error from server:', jsonData.error);
                throw new Error(jsonData.error);
              }
              jsonData.choices?.forEach((choice) => {
                if (choice.delta?.content) {
                  onContentReceived(choice.delta.content);
                }
              });
            } catch (error) {
              if (error.message === 'Insufficient tokens') {
                throw error; // Rethrow token errors to be handled by the caller
              }
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }
      
      if (onComplete) {
        onComplete();
      }
      
      return;
    } catch (error) {
      console.error(`Error during text generation (attempt ${retries + 1}):`, error);
      
      // Don't retry certain errors
      if (
        error.message.includes('Authentication failed') || 
        error.message.includes('Insufficient tokens')
      ) {
        throw error;
      }
      
      retries++;
      if (retries >= MAX_RETRIES) {
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
};

const ChatUI = ({ fullPDFText, activePdfName, isDarkMode, isAuthenticated = true, onLogin }) => {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isAIThinking, setIsAIThinking] = useState(false);
  const [isAIResponding, setIsAIResponding] = useState(false);
  const chatContainerRef = useRef(null);
  
  const [exampleQuestions] = useState([
    { display: "¿De qué trata principalmente este artículo?", actual: "Como experto investigador de alto nivel, describe profesionalmente de qué trata este artículo. Enfócate en el campo profesional, sin necesidad de mencionar el apoyo financiero, los agradecimientos y las referencias." },
    { display: "用中文做一个PPT总结，3页左右", actual: "请你扮演一位经验丰富的学术告专家，基于这篇论文的内容，用中文设计一个简洁而全面的PPT大纲，总共3页左右。每页列出要点，确保涵盖论文的主要内容、方法和结论。不需要讲资金支持，致谢和参考文献。" },
    { display: "Please explain and evaluate the statistical methods in this paper.", actual: "As a statistics expert, please explain in detail the statistical methods used in this paper. Include but not limited to: the specific statistical techniques used, why these methods were chosen, how these methods were applied to the research data, and the advantages and disadvantages of these statistical methods." },
    { display: "Quelles sont les limites de cet article?", actual: "En tant qu'expert en évaluation par les pairs rigoureux, veuillez analyser et identifier soigneusement les limites potentielles de cet article de recherche. Considérez tous les aspects tels que la conception de l'étude, la sélection des échantillons, la collecte des données et les méthodes d'analyse, et expliquez brièvement comment ces limites peuvent affecter les résultats et les conclusions de l'étude." },
  ]);

  const [showInsufficientWarning, setShowInsufficientWarning] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [showTokenPurchase, setShowTokenPurchase] = useState(false);
  const [forceTokenUpdate, setForceTokenUpdate] = useState(0);
  const [showTokenDisplay, setShowTokenDisplay] = useState(false);
  const tokenDisplayTimeoutRef = useRef(null);

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleExampleClick = (question) => {
    setMessage(question.display);
    handleSendMessage(question.actual, question.display);
  };

  const handleSendMessage = async (customMessage = null, displayMessage = null) => {
    const messageToSend = customMessage || message.trim();
    const messageToDisplay = displayMessage || messageToSend;
    
    if (messageToSend !== '') {
      try {
        // Check balance first
        const balance = await checkTokenBalance();
        setCurrentBalance(balance);
        
        const CHAT_MINIMUM_TOKENS = 9000;  // Higher minimum for chat
        
        if (balance < CHAT_MINIMUM_TOKENS) {
          setShowInsufficientWarning(true);
          return;
        }

        setChatMessages((prevMessages) => [
          ...prevMessages,
          { text: messageToDisplay, type: 'user' },
        ]);
        setMessage('');
        setIsAIThinking(true);
      
        try {
          const prompt = "You are a great scientist and researcher. Please respond to the following question or request based on the context (which is an academic journal paper) I provided, provide your response in the most appropriate language for the user:";
          const context = fullPDFText;
          const text = `${context}\n\nQuestion: ${messageToSend}`;
          
          // NOTE: The model parameter is currently ignored by the backend, which always uses 'GLM-4-Flash'
          // This is kept for consistency and to support future model selection features
          const model = 'GLM-4-Flash';
      
          let fullResponse = '';
          await generateText(
            prompt,
            text,
            model,
            (content) => {
              if (!isAIResponding) {
                setIsAIResponding(true);
                setIsAIThinking(false);
              }
              fullResponse += content;
              setChatMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                if (
                  prevMessages.length &&
                  prevMessages[prevMessages.length - 1].type === 'response'
                ) {
                  newMessages[prevMessages.length - 1].text = fullResponse;
                } else {
                  newMessages.push({ text: fullResponse, type: 'response' });
                }
                return newMessages;
              });
            },
            () => setForceTokenUpdate(prev => prev + 1),
            CHAT_MINIMUM_TOKENS  // Pass the minimum tokens requirement
          );
        } catch (error) {
          console.error('Error generating response:', error);
          
          // Check if the error is due to insufficient tokens
          if (error.message === 'INSUFFICIENT_TOKENS' || 
              error.message.includes('Insufficient tokens')) {
            setShowInsufficientWarning(true);
          } else {
            setChatMessages((prevMessages) => [
              ...prevMessages,
              { text: 'Failed to process request.', type: 'error' },
            ]);
          }
          
          setIsAIThinking(false);
          setIsAIResponding(false);
        }
      } catch (error) {
        console.error('Error in handleSendMessage:', error);
        if (error.message === 'INSUFFICIENT_TOKENS' || 
            error.message.includes('Insufficient tokens')) {
          setShowInsufficientWarning(true);
        } else {
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { text: 'Failed to process request.', type: 'error' },
          ]);
        }
      }
    }

    setShowTokenDisplay(true); // Show token display when sending message
    
    // Clear any existing timeout
    if (tokenDisplayTimeoutRef.current) {
      clearTimeout(tokenDisplayTimeoutRef.current);
    }
    
    // Hide token display after 1 minute
    tokenDisplayTimeoutRef.current = setTimeout(() => {
      setShowTokenDisplay(false);
    }, 60000);
  };

  // Scroll to the bottom of the chat when messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (tokenDisplayTimeoutRef.current) {
        clearTimeout(tokenDisplayTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className={`flex flex-col h-full ${isDarkMode ? 'bg-[#0d1117]' : 'bg-white'}`}>
      <div className={`px-4 border-b ${isDarkMode ? 'border-[#21262d]' : 'border-gray-200'} h-10 flex items-center justify-between flex-shrink-0`}>
        <h2 className={`text-sm font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>{t('chat.askAboutPDF')}</h2>
        {showTokenDisplay && (
          <div className={`${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            <TokenDisplay forceUpdate={forceTokenUpdate} />
          </div>
        )}
      </div>
      <ul className="flex-grow overflow-y-auto p-4" ref={chatContainerRef}>
        {activePdfName && (
          <li className="mb-2 text-xs text-center">
            Current PDF: {activePdfName}
          </li>
        )}
        {chatMessages.map((msg, index) => (
          <li key={index} className={`max-w-full flex gap-x-2 mb-2 ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className={`rounded-lg p-2 break-words ${
              msg.type === 'user' 
                ? (isDarkMode ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white')
                : (isDarkMode ? 'bg-[#21262d] text-gray-200' : 'bg-gray-100 text-gray-800')
            }`}>
              <ReactMarkdown className="text-sm">{msg.text}</ReactMarkdown>
            </div>
          </li>
        ))}
        {isAIThinking && !isAIResponding && (
          <li className="max-w-full flex gap-x-2 mb-2">
            <div className={`rounded-lg p-2 ${isDarkMode ? 'bg-[#21262d] text-gray-200' : 'bg-gray-100 text-gray-800'}`}>
              <p className="text-sm">
                <span className="typing-animation">...</span>
              </p>
            </div>
          </li>
        )}
      </ul>
      <div className={`p-4 border-t ${isDarkMode ? 'border-[#21262d]' : 'border-gray-200'}`}>
        <div className="mb-2">
          <p className={`text-sm mb-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Example questions:</p>
          <div className="flex flex-wrap gap-2">
            {exampleQuestions.map((question, index) => (
              <button
                key={index}
                onClick={() => handleExampleClick(question)}
                className={`text-xs px-2 py-1 rounded text-left ${
                  isDarkMode 
                    ? 'bg-[#21262d] text-gray-300 hover:bg-[#30363d]' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                {question.display}
              </button>
            ))}
          </div>
        </div>
        <div className="flex">
          <input
            type="text"
            placeholder={t('chat.typeYourQuestion')}
            value={message}
            onChange={handleInputChange}
            className={`flex-grow mr-2 px-3 py-2 text-sm rounded-md ${
              isDarkMode 
                ? 'bg-[#21262d] text-gray-200 border-gray-600' 
                : 'bg-[#f0f0f4] text-gray-800 border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <button
            onClick={() => handleSendMessage()}
            className={`${
              isDarkMode 
                ? 'bg-[#0071e3] hover:bg-[#0077ed] text-white' 
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            } font-medium py-2 px-4 text-sm rounded-md transition-all duration-300`}
            disabled={isAIThinking || isAIResponding || message.trim() === ''}
          >
            {t('chat.send')}
          </button>
        </div>
        {chatMessages.length > 0 && (
          <button
            onClick={() => setChatMessages([])}
            className={`mt-2 text-sm ${isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-700'}`}
          >
            {t('chat.clearChat')}
          </button>
        )}
      </div>
      
      {showInsufficientWarning && (
        <InsufficientTokensModal
          onClose={() => setShowInsufficientWarning(false)}
          onPurchase={() => {
            setShowInsufficientWarning(false);
            setShowTokenPurchase(true);
          }}
          requiredTokens={'至少 9000 '}
          currentBalance={currentBalance}
          isDarkMode={isDarkMode}
          isAuthenticated={isAuthenticated}
          onLogin={onLogin}
        />
      )}
      
      {showTokenPurchase && (
        <TokenPurchase 
          isDarkMode={isDarkMode} 
          onClose={() => setShowTokenPurchase(false)}
        />
      )}
    </div>
  );
};

export default ChatUI;

import React, { useState, useEffect, useCallback } from 'react';
import { openDB } from 'idb';
import { useTranslation } from 'react-i18next';

const GeneratedPoem = ({ isDarkMode }) => {
  const [poem, setPoem] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { i18n, t } = useTranslation();

  // Sample poems for unauthenticated users
  const samplePoems = {
    en: "Through CRISPR's dance with DNA's design,\nAnd language models' wisdom vast and deep,\nAI guides healers on their grand design,\nAs ancient ailments fade while patients sleep.\nLike stars that light the darkest human night,\nThese tools of science bring new hope and care,\nWhere data flows and algorithms bright\nSeek cures that mortal eyes could never dare.\nIn labs where genes are edited with grace,\nAnd neural nets predict what lies ahead,\nWe glimpse a future for our human race\nWhere suffering and pain at last lie dead.\nThrough technology's transforming art,\nWe heal both body, mind, and human heart.",
    zh: "基因编辑展新篇，\n智能医疗济世间。\n数据如泉昼夜涌，\n算法似剑破难关。\n科研报国兴百业，\n创新济世利千年。\n但愿众生皆安康，\n春风化雨满人间。"
  };

  const getPDFTitles = async () => {
    try {
      const db = await openDB('PDFStore', 4);
      const allMetadata = await db.getAll('metadata');
      
      if (!allMetadata || allMetadata.length === 0) {
        return ['默认论文题目']; // Fallback title if no PDFs
      }
      
      return allMetadata.map(metadata => metadata.name || 'Untitled');
    } catch (error) {
      console.error('Error getting PDF titles:', error);
      return ['默认论文题目']; // Fallback title if can't get PDFs
    }
  };

  const generatePoem = useCallback(async () => {
    setIsLoading(true);
    setError('');
    
    try {
      const titles = await getPDFTitles();
      if (!titles || titles.length === 0) {
        setError('No PDF titles found for poem generation');
        setIsLoading(false);
        return;
      }

      // Determine language and set appropriate prompt
      const isEnglish = i18n.language === 'en';
      
      // This specific prompt is recognized by the backend as a free feature
      // Keep the Chinese prompt for Chinese users since the backend recognizes it as free
      // For English users, we still need to include the starting phrase that the backend checks for
      let prompt;
      if (isEnglish) {
        prompt = "我要你扮演诗人。I want you to act as a poet. Create a beautiful and impactful poem based on the following academic paper titles. Ensure your words convey the feelings you're trying to express in a beautiful and meaningful way. Write one top-level poem, in Shakespeare's style, in English:";
      } else {
        prompt = "我要你扮演诗人。用以下的文章题目做首古诗，你同时也是这些学术文章的科学家，你将创作出能唤起情感并具有触动人心的力量的诗歌。但要确保您的文字以优美而有意义的方式传达您试图表达的感觉。用以下的文章题目合并起来一共做1首古诗,只写一首，顶级水平七言律诗：";
      }
      
      const text = titles.join(", ");
      
      // NOTE: The model parameter is currently ignored by the backend, which always uses 'GLM-4-Flash'
      // This is kept for consistency and to support future model selection features
      // Use GLM-4-Flash model instead of Moonshot
      const model = 'GLM-4-Flash';

      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('No authentication token found. Please login.');
      }

      // Direct API call to the backend
      const API_URL = 'https://api.labcat.com.cn/moonshot/generate_moonshot';
      
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          prompt,
          text,
          model,
        }),
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      if (!response.body) {
        throw new Error('Failed to get a valid response body from the API');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let fullPoem = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');
        
        for (const line of lines) {
          if (line.trim().startsWith('data:') && line.trim() !== 'data: [DONE]') {
            try {
              // Skip token usage data
              if (line.includes('"token_usage"')) continue;
              
              const jsonData = JSON.parse(line.substring(5));
              if (jsonData.error) {
                console.error('Error from server:', jsonData.error);
                throw new Error(jsonData.error);
              }
              
              if (jsonData.choices && jsonData.choices[0]?.delta?.content) {
                const content = jsonData.choices[0].delta.content;
                fullPoem += content;
                setPoem(fullPoem);
                localStorage.setItem('generatedPoem', fullPoem);
                localStorage.setItem('poemLanguage', isEnglish ? 'en' : 'zh');
              }
            } catch (error) {
              if (error.message !== 'Unexpected end of JSON input') {
                console.error('Error parsing JSON:', error);
              }
            }
          }
        }
      }
      
      if (!fullPoem) {
        throw new Error('No poem content received from the API');
      }
    } catch (error) {
      console.error('Error generating poem:', error);
      setError('Failed to generate poem: ' + error.message);
      setPoem('');
    } finally {
      setIsLoading(false);
    }
  }, [i18n.language]);

  useEffect(() => {
    const checkAuthentication = () => {
      // Check authentication first
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      return !!token;
    };

    const isUserAuthenticated = checkAuthentication();
    setIsAuthenticated(isUserAuthenticated);

    if (isUserAuthenticated) {
      const checkAndGeneratePoem = async () => {
        try {
          // Get existing poem and language
          const existingPoem = localStorage.getItem('generatedPoem');
          const poemLanguage = localStorage.getItem('poemLanguage');
          const currentLang = i18n.language;
          const lastGeneratedTime = localStorage.getItem('lastPoemGeneratedTime');
          const currentTime = new Date().getTime();

          // Generate a new poem if:
          // 1. There's no existing poem OR
          // 2. It's been more than 24 hours since the last generation OR
          // 3. The app language has changed since the last poem was generated
          if (!existingPoem || !lastGeneratedTime || 
              (currentTime - parseInt(lastGeneratedTime) > 24 * 60 * 60 * 1000) ||
              (poemLanguage !== currentLang)) {
            await generatePoem();
            localStorage.setItem('lastPoemGeneratedTime', currentTime.toString());
            localStorage.setItem('poemLanguage', currentLang);
          } else {
            setPoem(existingPoem);
          }
        } catch (err) {
          console.error('Error in checkAndGeneratePoem:', err);
          setError('Failed to check or generate poem');
        }
      };

      checkAndGeneratePoem();

      // Check every hour if a new poem should be generated
      const interval = setInterval(() => {
        const lastGeneratedTime = localStorage.getItem('lastPoemGeneratedTime');
        const currentTime = new Date().getTime();
        const poemLanguage = localStorage.getItem('poemLanguage');
        const currentLang = i18n.language;
        
        if (!lastGeneratedTime || 
            (currentTime - parseInt(lastGeneratedTime) > 24 * 60 * 60 * 1000) ||
            (poemLanguage !== currentLang)) {
          checkAndGeneratePoem();
        }
      }, 60 * 60 * 1000); // Check every hour

      return () => clearInterval(interval);
    } else {
      // For unauthenticated users, show sample poem
      const currentLang = i18n.language;
      setPoem(samplePoems[currentLang] || samplePoems.en);
      setError('');
    }
  }, [generatePoem, i18n.language]);

  const getPoemTitle = () => {
    return i18n.language === 'en' ? 'Daily Scholar\'s Verse' : '文献作诗';
  };
  
  // Format the poem for better display
  const formatPoem = (poemText, isEnglish) => {
    if (!poemText) return '';
    
    if (isEnglish) {
      // For English sonnets - split by newlines and add proper indentation
      return poemText.split('\n').map((line, i) => (
        <div key={i} className="mb-1 font-sans">
          {line}
        </div>
      ));
    } else {
      // For Chinese poems - split by newlines
      return poemText.split('\n').map((line, i) => (
        <div key={i} className="mb-1 font-sans tracking-wider">
          {line}
        </div>
      ));
    }
  };

  return (
    <div className={`mt-4 p-4 rounded-lg relative ${isDarkMode ? 'bg-[#21262d] text-gray-200' : 'bg-white text-gray-800'} font-sans`}>
      <h3 className="text-lg font-semibold mb-3">{getPoemTitle()}</h3>
      
      {/* AI Generated Tag */}
      <div className="absolute top-3 right-3">
        <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
          isDarkMode ? 'bg-blue-900/50 text-blue-300' : 'bg-blue-100 text-blue-800'
        }`}>
          AI Generated
        </span>
      </div>
      
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : isLoading ? (
        <p>{i18n.language === 'en' ? 'Crafting your poem...' : '科研喵正在作诗...'}</p>
      ) : (
        <div className="max-h-64 overflow-y-auto pr-1 pb-2" style={{ scrollbarWidth: 'thin' }}>
          <div className={`whitespace-pre-wrap ${i18n.language === 'en' ? 
            'leading-normal tracking-wide text-center py-3 px-2' : 
            'leading-normal tracking-wide text-lg'}`}>
            {formatPoem(poem, i18n.language === 'en')}
          </div>
          {!isAuthenticated && (
            <p className="mt-3 text-sm italic text-gray-500 border-t pt-2 border-gray-300">
              {i18n.language === 'en' 
                ? 'Login to get personalized poems based on your papers!' 
                : '登录后即可获取基于您的论文的个性化诗作！'}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default GeneratedPoem;
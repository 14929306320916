// TranslationPage.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import ChatUI from './ChatUI';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "./ui/resizable";
import { openDB } from 'idb';
import axios from 'axios';
import { toast } from 'react-hot-toast'; 
import TokenPurchase from './TokenPurchase';
import { useTranslation } from 'react-i18next';
import { languageOptions, documentTranslationStrings } from '../utils/translation';
import { IoLanguage, IoClose } from 'react-icons/io5';
import { motion, AnimatePresence } from 'framer-motion';

const checkTokenBalance = async () => {
  try {
    // Get the authentication provider and the appropriate token
    const authProvider = localStorage.getItem('auth_provider');
    let token;
    
    if (authProvider === 'email') {
      token = localStorage.getItem('email_token');
    } else {
      // Default to wechat token for backward compatibility
      token = localStorage.getItem('wechat_token');
    }
    
    // Use the access_token as fallback if specific token not found
    if (!token) {
      token = localStorage.getItem('access_token');
    }
    
    if (!token) {
      throw new Error('No authentication token found');
    }
    
    const response = await fetch('https://api.labcat.com.cn/api/tokens/balance', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (response.ok) {
      const data = await response.json();
      return data.tokens_remaining;
    }
    throw new Error('Failed to fetch token balance');
  } catch (error) {
    console.error('Error checking token balance:', error);
    return 0;
  }
};

const InsufficientTokensModal = ({ onClose, onPurchase, requiredTokens, currentBalance, isDarkMode }) => {
  const { t, i18n } = useTranslation();
  const strings = documentTranslationStrings[i18n.language] || documentTranslationStrings.en;
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50">
      <div className={`relative w-full max-w-md rounded-lg shadow-2xl p-6 ${isDarkMode ? 'bg-[#1a1b26] text-white' : 'bg-white text-black'}`}>
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 to-orange-500"></div>
        
        <div className="mb-4 text-center">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h2 className="text-2xl font-bold mb-2">{strings.insufficientBalance}</h2>
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
            {strings.currentBalance}: <span className="font-bold text-orange-500">{currentBalance}</span> tokens
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {strings.required}: <span className="font-bold text-green-500">{requiredTokens}</span> tokens
          </p>
        </div>

        <div className="flex justify-center gap-4">
          <button
            onClick={onClose}
            className={`px-4 py-2 rounded-md ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600' 
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            {strings.cancel}
          </button>
          <button
            onClick={onPurchase}
            className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white"
          >
            {strings.purchaseTokens}
          </button>
        </div>
      </div>
    </div>
  );
};

// Add a new language selection modal component
const LanguageSelectionModal = ({ isOpen, onClose, onSelectLanguage, isDarkMode, existingTranslations = [] }) => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('zh');
  
  // Get appropriate translation strings based on current language
  const strings = documentTranslationStrings[i18n.language] || documentTranslationStrings.en;
  
  // Common language options - subset of all languages that work best with document translation
  const documentLanguageOptions = [
    { value: 'zh', label: 'Chinese (Simplified)' },
    { value: 'cht', label: 'Chinese (Traditional)' },
    { value: 'en', label: 'English' },
    { value: 'spa', label: 'Spanish' },
    { value: 'fra', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'jp', label: 'Japanese' },
    { value: 'kor', label: 'Korean' },
    { value: 'ru', label: 'Russian' }
  ];
  
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.2 }}
        className={`w-full max-w-md rounded-lg shadow-2xl p-6 relative ${isDarkMode ? 'bg-[#1a1b26] text-white' : 'bg-white text-black'}`}
      >
        {/* Close button */}
        <button 
          onClick={onClose}
          className={`absolute top-2 right-2 p-2 rounded-full ${
            isDarkMode ? 'bg-gray-800 hover:bg-gray-700 text-gray-300' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
          }`}
          aria-label="Close"
        >
          <IoClose size={18} />
        </button>
        
        <div className="mb-4 text-center">
          <div className={`inline-flex items-center justify-center p-2 mb-4 rounded-full ${
            isDarkMode ? 'bg-blue-900/30' : 'bg-blue-100'
          }`}>
            <IoLanguage size={24} className={isDarkMode ? 'text-blue-400' : 'text-blue-600'} />
          </div>
          <h2 className="text-xl font-bold mb-2">{strings.selectTargetLanguage}</h2>
          <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {strings.chooseLanguageForDocument}
          </p>
        </div>

        <div className="grid grid-cols-2 gap-2 mb-6">
          {documentLanguageOptions.map((language) => {
            const hasExistingTranslation = existingTranslations.includes(language.value);
            return (
              <button
                key={language.value}
                onClick={() => setSelectedLanguage(language.value)}
                className={`p-3 rounded-lg text-center transition-all relative ${
                  selectedLanguage === language.value
                    ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                    : (isDarkMode ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-gray-100 text-gray-800 hover:bg-gray-200')
                }`}
              >
                {language.label}
                {hasExistingTranslation && (
                  <span className="absolute top-1 right-2 w-2 h-2 rounded-full bg-green-500"></span>
                )}
              </button>
            );
          })}
        </div>

        <div className="flex justify-between gap-3">
          <div className="text-xs text-gray-500">
            <span className="inline-block w-2 h-2 rounded-full bg-green-500 mr-1"></span>
            {strings.alreadyTranslated || 'Already translated'}
          </div>
          <div className="flex gap-2">
            <button
              onClick={onClose}
              className={`px-4 py-2 rounded-md ${
                isDarkMode 
                  ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' 
                  : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
              }`}
            >
              {strings.cancel}
            </button>
            <button
              onClick={() => onSelectLanguage(selectedLanguage)}
              className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white"
            >
              {strings.translate}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const TranslationPage = ({ fullPDFText, activePdfName, isDarkMode, onLogin }) => {
  const { pdfId } = useParams();
  const navigate = useNavigate();
  const [isTranslating, setIsTranslating] = useState(false);
  const [translationProgress, setTranslationProgress] = useState(0);
  const [translationError, setTranslationError] = useState(null);
  const [translatedPdfUrl, setTranslatedPdfUrl] = useState(null);
  const [showTokenPurchase, setShowTokenPurchase] = useState(false);
  const [showInsufficientWarning, setShowInsufficientWarning] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [targetLanguage, setTargetLanguage] = useState('zh'); // Default target language
  const [existingTranslations, setExistingTranslations] = useState([]);

  const translationStartedRef = useRef(false);
  const progressIntervalRef = useRef(null);

  const { t, i18n } = useTranslation();
  // Get appropriate translation strings based on current language
  const strings = documentTranslationStrings[i18n.language] || documentTranslationStrings.en;

  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes

  // Check for existing translations in any language
  const checkExistingTranslations = async () => {
    try {
      const db = await openDB('PDFStore', 4);
      const languagesToCheck = ['zh', 'cht', 'en', 'spa', 'fra', 'de', 'jp', 'kor', 'ru'];
      const existingLangs = [];
      
      for (const lang of languagesToCheck) {
        const translatedPdfId = `${pdfId}_translated_${lang}`;
        try {
          const existingTranslation = await db.get('pdfs', translatedPdfId);
          if (existingTranslation) {
            existingLangs.push(lang);
          }
        } catch (e) {
          // Ignore errors, just means translation doesn't exist
        }
      }
      
      setExistingTranslations(existingLangs);
      return existingLangs;
    } catch (error) {
      console.error('Error checking existing translations:', error);
      return [];
    }
  };

  // Handle showing the language selection modal
  const handleShowLanguageModal = async () => {
    await checkExistingTranslations();
    setShowLanguageModal(true);
  };

  const handleLanguageSelection = async (language) => {
    setTargetLanguage(language);
    setShowLanguageModal(false);
    
    // Get the PDF and start translation
    try {
      const db = await openDB('PDFStore', 4);
      
      // First check if we already have a translated version in this language
      const translatedPdfId = `${pdfId}_translated_${language}`;
      const existingTranslation = await db.get('pdfs', translatedPdfId);
      
      if (existingTranslation) {
        // We already have this translation, just display it
        const blobUrl = URL.createObjectURL(existingTranslation);
        setTranslatedPdfUrl(blobUrl);
        return;
      }
      
      // No existing translation found, proceed with new translation
      const originalPdfBlob = await db.get('pdfs', pdfId);
      
      if (originalPdfBlob) {
        await translateDocument(originalPdfBlob, language);
      } else {
        throw new Error('Original PDF not found in IndexedDB');
      }
    } catch (error) {
      console.error('Error getting PDF for translation:', error);
      setTranslationError(error.message);
    }
  };

  const startProgressBar = () => {
    let progress = 0;
    const totalDuration = 50000; // 50 seconds
    const interval = 100; // Update every 100ms
    const increment = (interval / totalDuration) * 100;

    progressIntervalRef.current = setInterval(() => {
      progress += increment;
      if (progress >= 99) {
        clearInterval(progressIntervalRef.current);
        progress = 99;
      }
      setTranslationProgress(Math.min(Math.round(progress), 99));
    }, interval);
  };

  const translateDocument = async (pdfBlob, targetLang = 'zh') => {
    try {
      const balance = await checkTokenBalance();
      setCurrentBalance(balance);
      if (balance < 50000) {
        setShowInsufficientWarning(true);
        return;
      }

      if (pdfBlob.size > MAX_FILE_SIZE) {
        toast.error(strings.fileTooLarge);
        return;
      }

      setIsTranslating(true);
      setTranslationProgress(0);
      setTranslationError(null);
      startProgressBar();

      try {
        // Get the authentication provider and the appropriate token
        const authProvider = localStorage.getItem('auth_provider');
        let token;
        
        if (authProvider === 'email') {
          token = localStorage.getItem('email_token');
        } else {
          // Default to wechat token for backward compatibility
          token = localStorage.getItem('wechat_token');
        }
        
        // Use the access_token as fallback if specific token not found
        if (!token) {
          token = localStorage.getItem('access_token');
        }
        
        if (!token) {
          // No token found, set error and return early
          setTranslationError(strings.loginRequired);
          toast.error(strings.pleaseLoginFirst);
          if (onLogin) setTimeout(() => onLogin(), 1000); // Slight delay to show the error first
          return;
        }

        const base64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.readAsDataURL(pdfBlob);
        });
      
        console.log('PDF converted to base64');

        // Add authorization header to all requests
        const headers = {
          'Authorization': `Bearer ${token}`
        };

        const translationResponse = await axios.post('https://api.labcat.com.cn/translation/translate-document', {
          content: base64,
          from_lang: 'en',
          to: targetLang,
          filename: activePdfName
        }, { headers });

        if (translationResponse.data.code !== 0) {
          throw new Error(translationResponse.data.msg);
        }

        const requestId = translationResponse.data.data.requestId;

        while (true) {
          await new Promise(resolve => setTimeout(resolve, 2000));

          const statusResponse = await axios.post('https://api.labcat.com.cn/translation/check-translation', 
            { requestId },
            { headers }
          );

          if (statusResponse.data.code !== 0) {
            throw new Error(statusResponse.data.msg);
          }

          const status = statusResponse.data.data.status;

          if (status === 1) {
            const fileSrcUrl = statusResponse.data.data.fileSrcUrl;

            const pdfResponse = await axios.post('https://api.labcat.com.cn/translation/download-translated-pdf', 
              { fileSrcUrl }, 
              { 
                responseType: 'blob',
                headers 
              }
            );

            const translatedPdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });

            const db = await openDB('PDFStore', 4);
            const translatedPdfId = `${pdfId}_translated_${targetLang}`;
            await db.put('pdfs', translatedPdfBlob, translatedPdfId);

            const blobUrl = URL.createObjectURL(translatedPdfBlob);
            setTranslatedPdfUrl(blobUrl);
            break;
          } else if (status === 2) {
            throw new Error(statusResponse.data.data.reason || 'Translation failed');
          }
        }
      } catch (error) {
        console.error('Error translating PDF:', error);
        if (error.message === strings.loginRequired) {
          navigate('/');
          toast.error(strings.pleaseLoginFirst);
          if (onLogin) onLogin();
        } else if (error.response?.status === 413) {
          setTranslationError(strings.fileTooLarge);
          toast.error(strings.fileTooLarge);
        } else if (error.response?.status === 401) {
          navigate('/');
          toast.error(strings.sessionExpired);
          if (onLogin) onLogin();
        } else {
          setTranslationError(error.message);
          toast.error(strings.translationFailed);
        }
      } finally {
        clearInterval(progressIntervalRef.current);
        setTranslationProgress(100);
        setIsTranslating(false);
      }
    } catch (error) {
      console.error('Error translating PDF:', error);
      if (error.message.includes('Insufficient tokens')) {
        setShowInsufficientWarning(true);
      } else {
        setTranslationError(error.message);
        toast.error(strings.translationFailed);
      }
    }
  };

  useEffect(() => {
    let isActive = true;

    const initializeTranslation = async () => {
      if (!isActive) return;  // Prevents execution if component is unmounting
      
      try {
        // Check if token exists first
        const authProvider = localStorage.getItem('auth_provider');
        let token;
        
        if (authProvider === 'email') {
          token = localStorage.getItem('email_token');
        } else {
          token = localStorage.getItem('wechat_token');
        }
        
        // Use the access_token as fallback
        if (!token) {
          token = localStorage.getItem('access_token');
        }
        
        if (!token) {
          // No token found, set error and return early
          setTranslationError(strings.loginRequired);
          toast.error(strings.pleaseLoginFirst);
          if (onLogin) setTimeout(() => onLogin(), 1000); // Slight delay to show the error first
          return;
        }

        // Check existing translations
        const existingLangs = await checkExistingTranslations();

        // Check if we already have a translated version in the target language
        const db = await openDB('PDFStore', 4);
        const translatedPdfId = `${pdfId}_translated_${targetLanguage}`;
        const translatedPdfBlob = await db.get('pdfs', translatedPdfId);

        if (translatedPdfBlob && isActive) {
          const blobUrl = URL.createObjectURL(translatedPdfBlob);
          setTranslatedPdfUrl(blobUrl);
          setTranslationProgress(100);
        } else {
          // Show language selection dialog instead of auto-starting translation
          setShowLanguageModal(true);
        }
      } catch (error) {
        if (isActive) {
          console.error('Error initializing translation:', error);
          setTranslationError(error.message);
        }
      }
    };

    initializeTranslation();

    return () => {
      isActive = false;  // Cleanup function
    };
  }, [pdfId, activePdfName, strings.loginRequired, targetLanguage]);

  const downloadTranslatedPDF = async () => {
    try {
      const db = await openDB('PDFStore', 4);
      const translatedPdfId = `${pdfId}_translated_${targetLanguage}`;
      const translatedPdfBlob = await db.get('pdfs', translatedPdfId);

      if (translatedPdfBlob) {
        const url = window.URL.createObjectURL(translatedPdfBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${activePdfName}_translated_${targetLanguage}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(strings.translatedPdfNotFound);
      }
    } catch (error) {
      console.error('Error downloading translated PDF:', error);
      toast.error(strings.downloadFailed);
    }
  };

  return (
    <div className={`flex flex-col h-screen overflow-hidden ${isDarkMode ? 'dark bg-gray-900 text-white' : 'bg-white text-black'}`}>
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} p-3 flex items-center justify-between relative`}>
        {/* Back to Reading Button - Cyberpunk Style */}
        <button
          onClick={() => navigate('/')}
          className={`relative overflow-hidden group flex items-center gap-2 px-5 py-2.5 rounded-md font-medium 
            ${isDarkMode 
              ? 'bg-gradient-to-r from-blue-900 to-indigo-900 text-blue-100 hover:from-blue-800 hover:to-indigo-800' 
              : 'bg-gradient-to-r from-blue-500 to-indigo-500 text-white hover:from-blue-600 hover:to-indigo-600'
            } 
            shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105`}
        >
          <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-cyan-500/30 to-purple-500/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
          <span className="z-10 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            {strings.backToReading || 'Back to Reading'}
          </span>
        </button>

        {/* Download Button - Cyberpunk Style */}
        {translatedPdfUrl && (
          <button
            onClick={() => downloadTranslatedPDF()}
            className={`relative overflow-hidden group flex items-center gap-2 px-5 py-2.5 rounded-md font-medium
              ${isDarkMode 
                ? 'bg-gradient-to-r from-emerald-800 to-green-900 text-green-100 hover:from-emerald-700 hover:to-green-800' 
                : 'bg-gradient-to-r from-emerald-500 to-green-600 text-white hover:from-emerald-600 hover:to-green-700'
              } 
              shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105`}
          >
            <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-green-400/30 to-teal-400/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
            <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-green-400/10 to-teal-400/10 blur opacity-0 group-hover:opacity-75 transition-opacity duration-300"></span>
            <span className="z-10 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
              {strings.downloadTranslatedPDF}
            </span>
          </button>
        )}
      </div>
      <ResizablePanelGroup direction="horizontal" className="flex-1">
        {/* Original PDF */}
        <ResizablePanel defaultSize={41} minSize={20}>
          <PDFViewer pdfId={pdfId} onTextSelection={() => {}} onPDFLoad={() => {}} isDarkMode={isDarkMode} />
        </ResizablePanel>

        <ResizableHandle />

        {/* Translated PDF */}
        <ResizablePanel defaultSize={40} minSize={20}>
          {isTranslating ? (
            // Update loading UI for dark mode
            <div className={`h-full flex flex-col items-center justify-center ${isDarkMode ? 'text-white' : 'text-black'}`}>
              <p>{strings.translating || 'Translating...'}</p>
              <div className={`w-64 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-2.5 mt-2`}>
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${translationProgress}%` }}
                ></div>
              </div>
              <p className="mt-2">{translationProgress}%</p>
            </div>
          ) : translationError ? (
            // Update error UI for dark mode
            <div className={`h-full flex flex-col items-center justify-center p-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
              <p className="text-red-500 font-bold">{strings.translationFailed || 'Translation Failed'}</p>
              <p className="mt-2 text-center">{translationError}</p>
              
              {translationError === strings.loginRequired ? (
                // If the error is about authentication, show login button
                <button
                  onClick={() => {
                    navigate('/');
                    if (onLogin) onLogin();
                  }}
                  className={`relative overflow-hidden group flex items-center gap-2 px-5 py-2.5 mt-6 rounded-md font-medium
                    ${isDarkMode 
                      ? 'bg-gradient-to-r from-amber-700 to-orange-800 text-amber-100 hover:from-amber-600 hover:to-orange-700' 
                      : 'bg-gradient-to-r from-amber-500 to-orange-600 text-white hover:from-amber-600 hover:to-orange-700'
                    } 
                    shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105`}
                  >
                    <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-yellow-400/30 to-orange-400/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                    <span className="z-10 flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 005 10a6 6 0 0012 0c0-.35-.035-.691-.1-1.021A5 5 0 0010 11z" clipRule="evenodd" />
                      </svg>
                      {strings.pleaseLoginFirst}
                    </span>
                  </button>
              ) : (
                // For other errors, show retry button
                <button
                  onClick={() => handleShowLanguageModal()}
                  className={`relative overflow-hidden group flex items-center gap-2 px-5 py-2.5 mt-6 rounded-md font-medium
                    ${isDarkMode 
                      ? 'bg-gradient-to-r from-purple-800 to-fuchsia-900 text-purple-100 hover:from-purple-700 hover:to-fuchsia-800' 
                      : 'bg-gradient-to-r from-purple-500 to-fuchsia-600 text-white hover:from-purple-600 hover:to-fuchsia-700'
                    } 
                    shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105`}
                  >
                    <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-400/30 to-fuchsia-400/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                    <span className="z-10 flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                      </svg>
                      {strings.retry || 'Retry'}
                    </span>
                  </button>
              )}
            </div>
          ) : translatedPdfUrl ? (
            // Translated PDF Viewer
            <div className="h-full">
              <PDFViewer
                pdfId={`${pdfId}_translated_${targetLanguage}`}
                pdfUrl={translatedPdfUrl}
                onTextSelection={() => {}}
                onPDFLoad={() => {}}
                isDarkMode={isDarkMode}
              />
            </div>
          ) : (
            // Initial state - show translation button
            <div className={`h-full flex flex-col items-center justify-center p-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
              <p className="mb-6 text-center text-lg">{strings.clickToStart}</p>
              <button
                onClick={() => handleShowLanguageModal()}
                className={`relative overflow-hidden group flex items-center gap-2 px-6 py-3 rounded-md font-medium
                  ${isDarkMode 
                    ? 'bg-gradient-to-r from-blue-900 to-purple-900 text-blue-100 hover:from-blue-800 hover:to-purple-800' 
                    : 'bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-blue-600 hover:to-purple-700'
                  } 
                  shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105`}
              >
                <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-cyan-500/30 to-fuchsia-500/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-cyan-500/10 to-fuchsia-500/10 blur opacity-0 group-hover:opacity-75 transition-opacity duration-300"></span>
                <span className="relative z-10 flex items-center text-lg">
                  <IoLanguage className="h-6 w-6 mr-2" />
                  {strings.translateDocument}
                </span>
              </button>
            </div>
          )}
        </ResizablePanel>

        <ResizableHandle />

        {/* Chat UI */}
        <ResizablePanel defaultSize={19} minSize={15}>
          <div className="h-full flex flex-col">
            <ChatUI fullPDFText={fullPDFText} activePdfName={activePdfName} isDarkMode={isDarkMode} />
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
      
      {/* Language Selection Modal */}
      <AnimatePresence>
        {showLanguageModal && (
          <LanguageSelectionModal
            isOpen={showLanguageModal}
            onClose={() => setShowLanguageModal(false)}
            onSelectLanguage={handleLanguageSelection}
            isDarkMode={isDarkMode}
            existingTranslations={existingTranslations}
          />
        )}
      </AnimatePresence>
      
      {showInsufficientWarning && (
        <InsufficientTokensModal
          onClose={() => setShowInsufficientWarning(false)}
          onPurchase={() => {
            setShowInsufficientWarning(false);
            setShowTokenPurchase(true);
          }}
          requiredTokens={50000}
          currentBalance={currentBalance}
          isDarkMode={isDarkMode}
        />
      )}
      
      {showTokenPurchase && (
        <TokenPurchase
          isDarkMode={isDarkMode}
          onClose={() => setShowTokenPurchase(false)}
        />
      )}
    </div>
  );
};

export default TranslationPage;

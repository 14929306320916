import React, { useState, useEffect, useRef } from 'react';
import { extractJournalInfo } from '../utils/journalExtractor';

const RefPopup = ({ citationInfo, position, isDarkMode }) => {
  const [popupPosition, setPopupPosition] = useState(null);
  const [journalInfo, setJournalInfo] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!popupPosition) {
      setPopupPosition(position);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      // This timeout is now only used for cleanup purposes
    }, 1000);

    // Extract journal info
    const extractedInfo = extractJournalInfo(citationInfo.text);
    console.log('extractedInfo for impactor', extractedInfo);
    setJournalInfo(extractedInfo);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [position, citationInfo]);

  if (!citationInfo || !popupPosition) return null;

  return (
    <div 
      className={`fixed p-6 rounded-2xl shadow-2xl max-w-md ${
        isDarkMode ? 'bg-gray-900/80 text-white' : 'bg-white/80 text-gray-800'
      }`}
      style={{
        left: `${popupPosition.x}px`,
        top: `${popupPosition.y}px`,
        zIndex: 1000,
        boxShadow: isDarkMode ? '0 10px 25px rgba(0, 0, 0, 0.5)' : '0 10px 25px rgba(0, 0, 0, 0.1)',
        width: '360px',
        aspectRatio: '16 / 9',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        border: isDarkMode ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      <div className="flex items-center  mb-3">
        <div className="flex items-center">
          <p className="font-semibold text-lg">文献 {citationInfo.number}</p>
          <p className="text-sm opacity-75 italic ml-2">beta测试</p>
        </div>
      </div>
      <p className="text-sm leading-relaxed opacity-90 mb-2">{citationInfo.text}</p>
      {journalInfo && (
        <div className="mt-2">
          <p className="text-sm font-semibold mb-2">{journalInfo.name}</p>
          <div className="flex flex-wrap gap-2">
            {journalInfo.if && (
              <span className={`px-2 py-1 text-xs rounded-full ${isDarkMode ? 'bg-blue-900 text-blue-200' : 'bg-blue-100 text-blue-800'}`}>
                IF: {journalInfo.if}
              </span>
            )}
            {journalInfo.q && (
              <span className={`px-2 py-1 text-xs rounded-full ${isDarkMode ? 'bg-green-900 text-green-200' : 'bg-green-100 text-green-800'}`}>
                Q: {journalInfo.q}
              </span>
            )}
            {journalInfo.b && (
              <span className={`px-2 py-1 text-xs rounded-full ${isDarkMode ? 'bg-yellow-900 text-yellow-200' : 'bg-yellow-100 text-yellow-800'}`}>
                中科院：{journalInfo.b.replace('B', '')}区
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RefPopup;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoClose, IoInformationCircle, IoWarning, IoAlert } from "react-icons/io5";

const MessageBanner = ({ 
  isDarkMode, 
  isVisible, 
  onDismiss, 
  dismissedMessages,
  customMessages = [] // Add support for custom messages
}) => {
  const [messages, setMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const fetchMessages = async () => {
    try {
      const response = await axios.get('https://api.labcat.com.cn/admin/messages/active');
      const activeMessages = response.data.filter(msg => !dismissedMessages[msg.id]);
      if (activeMessages.length > 0) {
        // Combine fetched messages with custom messages
        setMessages([...customMessages, ...activeMessages]);
      } else if (customMessages.length > 0) {
        // If no fetched messages but we have custom messages
        setMessages(customMessages);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      // If API fails but we have custom messages, still show them
      if (customMessages.length > 0) {
        setMessages(customMessages);
      }
    }
  };

  // Initial fetch and setup polling
  useEffect(() => {
    // If we have custom messages, set them immediately
    if (customMessages.length > 0) {
      setMessages(customMessages);
    } else {
      fetchMessages(); // Initial fetch
    }
    
    // Poll every 1 minute
    const interval = setInterval(fetchMessages, 60000);
    
    return () => clearInterval(interval);
  }, [dismissedMessages, customMessages]);

  // Rotate messages if there are multiple
  useEffect(() => {
    if (messages.length > 1) {
      const rotationInterval = setInterval(() => {
        setCurrentMessageIndex((prev) => (prev + 1) % messages.length);
      }, 5000);
      return () => clearInterval(rotationInterval);
    }
  }, [messages.length]);

  const handleDismiss = (messageId) => {
    if (!messageId) return;
    onDismiss(messageId);
    setMessages(prev => prev.filter(msg => msg.id !== messageId));
  };

  if (!isVisible || messages.length === 0) return null;

  const currentMessage = messages[currentMessageIndex];

  const getPriorityStyles = (priority) => {
    const baseStyles = `
      relative overflow-hidden
      border border-opacity-40 shadow-lg
      before:absolute before:inset-0 before:opacity-20 before:animate-pulse
      after:absolute after:inset-0 after:opacity-10 after:animate-pulse after:delay-1000
    `;
    
    switch (priority) {
      case 3: // Urgent
        return isDarkMode 
          ? `${baseStyles} bg-black/60 text-red-100 border-red-500 
             shadow-[0_0_15px_rgba(239,68,68,0.3)] 
             before:bg-gradient-to-r before:from-red-500/0 before:via-red-500/30 before:to-red-500/0
             after:bg-gradient-to-r after:from-red-500/0 after:via-red-500/20 after:to-red-500/0`
          : `${baseStyles} bg-white/80 text-red-800 border-red-500
             shadow-[0_0_15px_rgba(239,68,68,0.2)]
             before:bg-gradient-to-r before:from-red-500/0 before:via-red-500/20 before:to-red-500/0
             after:bg-gradient-to-r after:from-red-500/0 after:via-red-500/10 after:to-red-500/0`;
      case 2: // Important
        return isDarkMode 
          ? `${baseStyles} bg-black/60 text-yellow-100 border-yellow-500
             shadow-[0_0_15px_rgba(234,179,8,0.3)]
             before:bg-gradient-to-r before:from-yellow-500/0 before:via-yellow-500/30 before:to-yellow-500/0
             after:bg-gradient-to-r after:from-yellow-500/0 after:via-yellow-500/20 after:to-yellow-500/0`
          : `${baseStyles} bg-white/80 text-yellow-800 border-yellow-500
             shadow-[0_0_15px_rgba(234,179,8,0.2)]
             before:bg-gradient-to-r before:from-yellow-500/0 before:via-yellow-500/20 before:to-yellow-500/0
             after:bg-gradient-to-r after:from-yellow-500/0 after:via-yellow-500/10 after:to-yellow-500/0`;
      default: // Normal
        return isDarkMode 
          ? `${baseStyles} bg-black/60 text-blue-100 border-blue-500
             shadow-[0_0_15px_rgba(59,130,246,0.3)]
             before:bg-gradient-to-r before:from-blue-500/0 before:via-blue-500/30 before:to-blue-500/0
             after:bg-gradient-to-r after:from-blue-500/0 after:via-blue-500/20 after:to-blue-500/0`
          : `${baseStyles} bg-white/80 text-blue-800 border-blue-500
             shadow-[0_0_15px_rgba(59,130,246,0.2)]
             before:bg-gradient-to-r before:from-blue-500/0 before:via-blue-500/20 before:to-blue-500/0
             after:bg-gradient-to-r after:from-blue-500/0 after:via-blue-500/10 after:to-blue-500/0`;
    }
  };

  const getPriorityIcon = (priority) => {
    const iconClass = "w-5 h-5 animate-pulse";
    switch (priority) {
      case 3:
        return <IoAlert className={iconClass} />;
      case 2:
        return <IoWarning className={iconClass} />;
      default:
        return <IoInformationCircle className={iconClass} />;
    }
  };

  return (
    <div className={`
      fixed top-6 right-6 z-50
      transition-all duration-500 ease-out
      ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-8 opacity-0'}
      animate-slideIn
    `}>
      <div className={`
        backdrop-blur-md rounded-lg
        max-w-md w-full transform
        hover:scale-[1.02] transition-all duration-300
        relative
        ${getPriorityStyles(currentMessage.priority)}
      `}>
        <div className="px-4 py-3">
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0 relative">
              {getPriorityIcon(currentMessage.priority)}
              <div className="absolute inset-0 animate-ping opacity-30">
                {getPriorityIcon(currentMessage.priority)}
              </div>
            </div>
            
            <div className="flex-grow min-w-0">
              <p className="text-sm font-medium tracking-wide truncate">
                {currentMessage.content}
              </p>
              
              {messages.length > 1 && (
                <div className="flex items-center space-x-1.5 mt-2">
                  {messages.map((_, index) => (
                    <span
                      key={index}
                      className={`h-1 w-6 rounded-full transition-all duration-300 
                        ${index === currentMessageIndex
                          ? 'bg-current opacity-100'
                          : 'bg-current opacity-30'
                        }`}
                    />
                  ))}
                </div>
              )}
            </div>
            
            <button
              onClick={() => {
                console.log('Close button clicked for message:', currentMessage);
                handleDismiss(currentMessage.id);
              }}
              className="flex-shrink-0 ml-2 p-1
                rounded-full
                hover:bg-white/10
                transition-all duration-200
                transform hover:scale-105 active:scale-95
                hover:rotate-90
                focus:outline-none focus:ring-1 focus:ring-current focus:ring-opacity-50
                cursor-pointer
                z-[60]"
            >
              <IoClose className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBanner; 
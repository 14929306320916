// PDFViewer.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { openDB } from 'idb';
import { useNavigate, useLocation } from 'react-router-dom';
import RefPopup from './RefPopup';

const PDFViewer = ({ pdfId, pdfUrl, onTextSelection, onPDFLoad, isDarkMode }) => {
  const iframeRef = useRef(null);
  const [viewerUrl, setViewerUrl] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [citationInfo, setCitationInfo] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const extractTextFromAllPages = async (pdfDocument) => {
    if (!pdfDocument) {
      console.error('PDF document is null or undefined');
      return '';
    }
    const numPages = pdfDocument.numPages;
    let fullText = '';
    for (let i = 1; i <= numPages; i++) {
      try {
        const page = await pdfDocument.getPage(i);
        const content = await page.getTextContent();
        const pageText = content.items.map(item => item.str).join(' ');
        fullText += pageText + '\n';
      } catch (error) {
        console.error(`Error extracting text from page ${i}:`, error);
      }
    }
    return fullText;
  };

  const saveAnnotationsToStorage = useCallback(async (pdfId, annotations) => {
    try {
      const db = await openDB('PDFStore', 4, {
        upgrade(db, oldVersion, newVersion, transaction) {
          if (!db.objectStoreNames.contains('annotations')) {
            db.createObjectStore('annotations');
          }
        },
      });

      // Directly save the annotations object
      await db.put('annotations', annotations, pdfId);
    } catch (error) {
      console.error('Error saving annotations:', error);
    }
  }, []);

  const getAnnotationsFromStorage = useCallback(async (pdfId) => {
    const db = await openDB('PDFStore', 4);
    const annotations = await db.get('annotations', pdfId);
    return annotations;
  }, []);

  const updateLastOpened = useCallback(async (pdfId) => {
    try {
      const db = await openDB('PDFStore', 4);
      const metadata = await db.get('metadata', pdfId);
      if (metadata) {
        const updatedMetadata = { ...metadata, lastOpened: Date.now() };
        await db.put('metadata', updatedMetadata, pdfId);
      }
    } catch (error) {
      console.error('Error updating lastOpened:', error);
    }
  }, []);

  const handleTranslateFullText = () => {
    navigate(`/translate/${pdfId}`);
  };

  const waitForPDFLoad = async (iframeWindow, maxRetries = 5, retryDelay = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
      if (iframeWindow.PDFViewerApplication.pdfDocument) {
        return true;
      }
      await new Promise(resolve => setTimeout(resolve, retryDelay));
    }
    return false;
  };

  useEffect(() => {
    const loadPDF = async () => {
      try {
        let blobUrl;
        let isWebUrl = false;
        let directWebUrl = null;

        // First check if we have a direct web PDF URL from the web PDF feature
        if (pdfUrl && typeof pdfUrl === 'object' && pdfUrl.isWebUrl) {
          isWebUrl = true;
          directWebUrl = pdfUrl.webUrl;
          console.log("Loading PDF directly from URL:", directWebUrl);
        } else if (pdfUrl) {
          // Use the provided pdfUrl directly (blob URL)
          blobUrl = pdfUrl;
        } else {
          // Load from IndexedDB using pdfId
          const db = await openDB('PDFStore', 4);
          const pdfBlob = await db.get('pdfs', pdfId);

          if (pdfBlob) {
            blobUrl = URL.createObjectURL(pdfBlob);

            // Update lastOpened when PDF is loaded
            await updateLastOpened(pdfId);
          } else {
            console.log('PDF blob not found in database');
          }
        }

        if (blobUrl || directWebUrl) {
          // Build the URL for the PDF.js viewer
          let viewerUrlWithParams;
          
          if (isWebUrl && directWebUrl) {
            // For direct web URLs, pass the URL directly to the viewer
            viewerUrlWithParams = `${process.env.PUBLIC_URL}/pdfjs/web/viewer.html?file=${encodeURIComponent(directWebUrl)}`;
          } else if (blobUrl) {
            // For blob URLs (either from uploaded file or database)
            viewerUrlWithParams = `${process.env.PUBLIC_URL}/pdfjs/web/viewer.html?file=${encodeURIComponent(blobUrl)}`;
          }
          
          // Add pdfId if we have one (might not for direct web PDFs)
          if (pdfId) {
            viewerUrlWithParams += `&pdfId=${encodeURIComponent(pdfId)}`;
          }
          
          // Add zoom and page mode settings
          viewerUrlWithParams += '#zoom=auto&pagemode=none';
          
          setViewerUrl(viewerUrlWithParams);
        }
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPDF();
  }, [pdfId, pdfUrl, updateLastOpened]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && viewerUrl) {
      iframe.onload = async () => {
        const iframeWindow = iframe.contentWindow;

        // Ensure PDF.js is initialized in the iframe
        await new Promise(resolve => {
          const checkPDFJS = setInterval(() => {
            if (iframeWindow.PDFViewerApplication && iframeWindow.PDFViewerApplication.initialized) {
              clearInterval(checkPDFJS);
              resolve();
            }
          }, 100);
        });

        // Wait for the PDF to be fully loaded with retry mechanism
        const isPDFLoaded = await waitForPDFLoad(iframeWindow);

        if (isPDFLoaded) {
          try {
            const pdfDocument = iframeWindow.PDFViewerApplication.pdfDocument;
            const fullText = await extractTextFromAllPages(pdfDocument);
            if (onPDFLoad) {
              onPDFLoad(fullText);
            }
          } catch (error) {
            console.error('Error extracting text from PDF:', error);
          }
        } else {
          console.error('PDF document failed to load after multiple attempts');
        }

        iframeWindow.postMessage({ type: 'ENABLE_TEXT_SELECTION_AND_ANNOTATIONS' }, '*');

        // Send dark mode state to iframe
        iframeWindow.postMessage({ type: 'SET_DARK_MODE', isDark: isDarkMode }, '*');
      };
    }
  }, [viewerUrl, pdfId, onPDFLoad, getAnnotationsFromStorage, isDarkMode]);

  const handleCitationHover = useCallback(async (event) => {
    let target = event.target;
  
    if (target.tagName !== 'A') {
      target = target.closest('a');
    }
  
    if (target && target.tagName === 'A' && target.getAttribute('data-element-id')) {
      const citationId = target.getAttribute('data-element-id');
      const href = target.getAttribute('href');
  
      // Extract the destination from the href
      const destination = href.split('#')[1];
  
      // Safely decode the destination
      const safelyDecodedDestination = safeDecodeURIComponent(destination);
  
      // Get the reference content from PDF.js
      const iframeWindow = iframeRef.current.contentWindow;
      const PDFViewerApplication = iframeWindow.PDFViewerApplication;
      const pdfLinkService = PDFViewerApplication.pdfLinkService;
  
      try {
        const destInfo = await pdfLinkService.getDestinationInfo(safelyDecodedDestination);
  
        if (destInfo) {
          const { pageNumber, refNumber, refText } = destInfo;
  
          setCitationInfo(prevInfo => {
            if (prevInfo && prevInfo.id === citationId) {
              return prevInfo;
            }
            return {
              id: citationId,
              number: refNumber,
              text: refText,
              href: href
            };
          });
          setHoverPosition({ x: event.clientX, y: event.clientY });
          setIsPopupVisible(true);
        }
      } catch (error) {
        console.error('Error getting destination info:', error);
      }
    } else {
      setIsPopupVisible(false);
    }
  }, []);
  

const safeDecodeURIComponent = (str) => { 
  // Check if str is undefined or null
  if (str == null) {
    console.warn('Attempted to decode undefined or null string');
    return '';
  }

  // Ensure str is a string
  str = String(str);

  // Helper function to attempt decoding
  const attemptDecode = (s) => {
    try {
      return decodeURIComponent(s);
    } catch (e) {
      console.warn('Failed to decode:', s);
      return s;
    }
  };

  // Correctly handle special characters
  let decoded = str
    .replace(/%uFEFF/g, '\uFEFF')  // Zero width no-break space
    .replace(/%u2013/g, '–')       // En dash
    .replace(/%A0/g, '\u00A0');    // Non-breaking space
  
  // Attempt decoding multiple times to ensure all sequences are decoded
  let prevDecoded;
  do {
    prevDecoded = decoded;
    decoded = attemptDecode(decoded);
  } while (decoded !== prevDecoded && decoded.includes('%'));

  // If there are still encoded characters, decode them individually
  if (decoded.includes('%')) {
    decoded = decoded.replace(/%[0-9A-Fa-f]{2}/g, match => {
      return attemptDecode(match);
    });
  }

  return decoded;
};


  const handleMouseMove = useCallback((event) => {
    handleCitationHover(event);
  }, [handleCitationHover]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const handleIframeLoad = () => {
        const iframeWindow = iframe.contentWindow;
        iframeWindow.addEventListener('mousemove', handleMouseMove);
      };

      iframe.addEventListener('load', handleIframeLoad);

      return () => {
        iframe.removeEventListener('load', handleIframeLoad);
        if (iframe.contentWindow) {
          iframe.contentWindow.removeEventListener('mousemove', handleMouseMove);
        }
      };
    }
  }, [handleMouseMove]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'textSelection') {
        onTextSelection(event.data.text);
      } else if (event.data && event.data.type === 'ANNOTATIONS_SAVED') {
        saveAnnotationsToStorage(pdfId, event.data.annotations);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [onTextSelection, pdfId, saveAnnotationsToStorage]);


  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'citationHover') {
        const { pageNumber, refNumber, refText, x, y } = event.data.data;
        setCitationInfo({
          number: refNumber,
          text: refText,
          page: pageNumber
        });
        setHoverPosition({ x, y });
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <div className="relative h-full">
      <iframe
        ref={iframeRef}
        src={viewerUrl}
        width="100%"
        height="100%"
        title="PDF Viewer"
        className="absolute inset-0"
      />
      {!location.pathname.includes('/translate') && !pdfUrl && (
        <button
          onClick={handleTranslateFullText}
          className="absolute top-0 right-8 bg-[#272729] hover:bg-blue-600 text-white font-bold py-2 px-6 rounded z-10"
        >
          🔥 全文翻译 🔥
        </button>
      )}
      {isPopupVisible && (
        <RefPopup
          citationInfo={citationInfo}
          position={hoverPosition}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default PDFViewer;

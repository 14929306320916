import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function VerifyEmail() {
  const [status, setStatus] = useState('verifying');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if we have status and message in the URL (redirected from backend)
    const params = new URLSearchParams(location.search);
    const urlStatus = params.get('status');
    const urlMessage = params.get('message');
    
    if (urlStatus && urlMessage) {
      // We were redirected from the backend with status and message
      setStatus(urlStatus === 'success' ? 'success' : 'error');
      setMessage(decodeURIComponent(urlMessage));
      
      if (urlStatus === 'success') {
        const urlEmail = params.get('email');
        if (urlEmail) {
          setEmail(decodeURIComponent(urlEmail));
          localStorage.setItem('verifiedEmail', decodeURIComponent(urlEmail));
          localStorage.setItem('justVerified', 'true');
        }
      }
      return;
    }
    
    // If no status/message in URL, proceed with token verification
    const verifyEmail = async () => {
      try {
        // Get token from URL
        const token = params.get('token');
        
        if (!token) {
          setStatus('error');
          setMessage('Verification token is missing');
          return;
        }
        
        console.log('Verifying email with token:', token);
        
        // Call backend API
        const response = await axios.get(`https://api.labcat.com.cn/auth/verify-email?token=${token}`);
        
        console.log('Verification response:', response.data);
        
        setStatus('success');
        setMessage(response.data.message || 'Email verified successfully. You can now log in.');
        
        // Save email if returned from backend
        if (response.data.email) {
          setEmail(response.data.email);
          // Store in localStorage for the login form
          localStorage.setItem('verifiedEmail', response.data.email);
          localStorage.setItem('justVerified', 'true');
        }
      } catch (error) {
        console.error('Verification error:', error);
        setStatus('error');
        setMessage(error.response?.data?.detail || 'Failed to verify email. Please try again.');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  // No countdown effect - completely removed

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');
    setIsLoggingIn(true);
    
    try {
      const response = await axios.post('https://api.labcat.com.cn/auth/login', {
        email,
        password
      });
      
      // Save token and user info
      localStorage.setItem('email_token', response.data.token);
      localStorage.setItem('email_user_info', JSON.stringify(response.data.user_info));
      localStorage.setItem('access_token', response.data.token);
      localStorage.setItem('auth_provider', 'email');
      
      // Redirect to home page
      navigate('/');
      window.location.reload(); // Force reload to apply the login
    } catch (error) {
      console.error('Login error:', error);
      setLoginError(error.response?.data?.detail || 'Invalid email or password');
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="max-w-md w-full p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
        {status === 'verifying' && (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
            <h2 className="text-2xl font-bold mb-4 dark:text-white">Verifying Your Email</h2>
            <p className="text-gray-600 dark:text-gray-300">Please wait while we verify your email address...</p>
          </div>
        )}
        
        {status === 'success' && (
          <div className="text-center">
            <svg className="h-16 w-16 text-green-500 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <h2 className="text-2xl font-bold mb-4 text-green-600">Verification Successful</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{message}</p>
            
            {/* Login form */}
            <div className="mt-4">
              <h3 className="text-lg font-medium mb-2 dark:text-white">Log in to your account</h3>
              <form onSubmit={handleLogin}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 text-left">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    required
                    readOnly={!!email}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 text-left">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    required
                    placeholder="Enter your password"
                    autoFocus
                  />
                </div>
                {loginError && (
                  <div className="mb-4 p-2 bg-red-100 text-red-700 rounded-md text-sm">
                    {loginError}
                  </div>
                )}
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? 'Logging in...' : 'Log In'}
                </button>
              </form>
            </div>
          </div>
        )}
        
        {status === 'error' && (
          <div className="text-center">
            <svg className="h-16 w-16 text-red-500 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            <h2 className="text-2xl font-bold mb-4 text-red-600">Verification Failed</h2>
            <p className="text-gray-600 dark:text-gray-300">{message}</p>
            <button 
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => navigate('/?login=email')}
            >
              Go to Login
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail; 
import React from 'react';
import './Chatbox.css';
import SelectedTextPanel from './SelectedTextPanel';
import ChatUI from './ChatUI';

const Chatbox = ({ selectedText, fullPDFText, activePdfName, isDarkMode, isAuthenticated = true, onLogin }) => {
  return (
    <div className={`flex h-full relative overflow-hidden ${isDarkMode ? 'bg-[#0d1117]' : 'bg-gray-100'}`}>
      {/* Blur effect background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute h-full w-full opacity-70 blur-3xl">
          {/* You can add a dark background color here if needed */}
        </div>
      </div>

      {/* Content */}
      <div className={`relative z-10 flex w-full h-full backdrop-blur-sm ${isDarkMode ? 'bg-[#0d1117]/30' : 'bg-white/30'}`}>
        <div className="w-1/2 min-w-[250px] flex-shrink-0 overflow-hidden">
          <SelectedTextPanel
            selectedText={selectedText}
            fullPDFText={fullPDFText}
            isDarkMode={isDarkMode}
            isAuthenticated={isAuthenticated}
            onLogin={onLogin}
          />
        </div>
        {/* Vertical separator */}
        <div className={`w-px ${isDarkMode ? 'bg-[#21262d]' : 'bg-gray-200'}`}></div>
        <div className="w-1/2 min-w-[250px] flex-grow overflow-hidden">
          <ChatUI
            fullPDFText={fullPDFText}
            activePdfName={activePdfName}
            isDarkMode={isDarkMode}
            isAuthenticated={isAuthenticated}
            onLogin={onLogin}
          />
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
import React, { useEffect, useState } from 'react';
import { openDB } from 'idb';

function ensurePDFJS() {
  return new Promise((resolve) => {
    if (window.pdfjsLib) {
      resolve(window.pdfjsLib);
    } else {
      const checkPDFJS = () => {
        if (window.pdfjsLib) {
          resolve(window.pdfjsLib);
        } else {
          setTimeout(checkPDFJS, 100);
        }
      };
      checkPDFJS();
    }
  });
}

const PDFThumbnail = ({ pdfId, metadata }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadThumbnail = async () => {
      try {
        const db = await openDB('PDFStore', 4);
        const cachedThumbnail = await db.get('thumbnails', pdfId);

        if (cachedThumbnail) {
          setThumbnail(cachedThumbnail);
        } else {
          const generatedThumbnail = await generateThumbnail(pdfId);
          setThumbnail(generatedThumbnail);
          await db.put('thumbnails', generatedThumbnail, pdfId);
        }
      } catch (error) {
        console.error('Error loading thumbnail:', error);
        setError(error.message);
      }
    };

    loadThumbnail();
  }, [pdfId, metadata]);

  const generateThumbnail = async (pdfId) => {
    try {
      console.log('Starting to load PDF:', pdfId);
      const dbVersion = await indexedDB.databases().then(dbs => {
        const dbInfo = dbs.find(db => db.name === 'PDFStore');
        return dbInfo ? dbInfo.version : 1;
      });

      const db = await openDB('PDFStore', 4);
      const pdfBlob = await db.get('pdfs', pdfId);
      if (!pdfBlob) {
        throw new Error('PDF not found in the database');
      }

      const pdfjsLib = await ensurePDFJS();

      const arrayBuffer = await pdfBlob.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({data: arrayBuffer});
      const pdf = await loadingTask.promise;
      console.log('PDF document loaded');

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      const scale = 3; // Adjust this value to increase/decrease resolution
      const scaledViewport = page.getViewport({ scale: scale * (300 / viewport.width) });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = 300 * scale; // Larger canvas size for higher resolution
      canvas.height = (300 * scale / scaledViewport.width) * scaledViewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport
      };

      await page.render(renderContext).promise;

      return canvas.toDataURL('image/jpeg', 0.8); // Use JPEG for smaller file size
    } catch (error) {
      console.error('Error generating PDF thumbnail:', error);
      throw error;
    }
  };

  if (error) {
    return (
      <div className="bg-red-100 text-red-700 p-2 rounded flex items-center justify-center h-full">
        <span className="text-sm">Error loading thumbnail: {error}</span>
      </div>
    );
  }

  return (
    <div className="w-full h-48 overflow-hidden"> {/* Reduced height from h-48 to h-36 */}
      {thumbnail ? (
        <img 
          src={thumbnail} 
          alt="PDF thumbnail" 
          className="w-full h-48 object-cover object-top -mt-4" /* Added -mt-4 to move image up */
        />
      ) : (
        <div className="flex items-center justify-center h-full">
          <span className="text-gray-400">Loading...</span>
        </div>
      )}
    </div>
  );
};

export default PDFThumbnail;

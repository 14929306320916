import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import HomeTab from './components/HomeTab';
import PDFViewer from './components/PDFViewer';
import Chatbox from './components/Chatbox';
import TabBar from './components/TabBar';
import TranslationPage from './components/TranslationPage';
import VerifyEmail from './components/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancel from './components/PaymentCancel';
import { openDB } from 'idb';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "./components/ui/resizable"
import { getJournalMetrics } from './utils/journalData';
import CryptoJS from 'crypto-js';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import axios from 'axios';
import LoginModal from './components/WeChatLoginModal';
import UpdateNotification from './components/UpdateNotification';
import MessageBanner from './components/MessageBanner';
import { useTranslation } from 'react-i18next';


// Set up axios to always send the token if it exists
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');
  console.log('Interceptor - Token from localStorage:', token);  // Debug log
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    console.log('Interceptor - Authorization header set');  // Debug log
  }
  return config;
}, function (error) {
  console.error('Interceptor - Request error:', error);  // Debug log
  return Promise.reject(error);
});




function ensurePDFJS() {
  return new Promise((resolve) => {
    if (window.pdfjsLib) {
      resolve(window.pdfjsLib);
    } else {
      const checkPDFJS = () => {
        if (window.pdfjsLib) {
          resolve(window.pdfjsLib);
        } else {
          setTimeout(checkPDFJS, 100);
        }
      };
      checkPDFJS();
    }
  });
}

const dbPromise = (async () => {
  const desiredDbVersion = 4; // Increment the version

  return openDB('PDFStore', desiredDbVersion, {
    upgrade(db, oldVersion, newVersion, transaction) {
      if (oldVersion < 1) {
        if (!db.objectStoreNames.contains('pdfs')) {
          db.createObjectStore('pdfs');
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata');
        }
      }
      if (oldVersion < 2) {
        if (!db.objectStoreNames.contains('annotations')) {
          db.createObjectStore('annotations');
        }
      }
      if (oldVersion < 3) {
        if (!db.objectStoreNames.contains('keywords')) {
          db.createObjectStore('keywords', { keyPath: 'keyword' });
        }
      }
      if (oldVersion < 4) {
        if (!db.objectStoreNames.contains('openPDFs')) {
          db.createObjectStore('openPDFs', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('recentPDFs')) {
          db.createObjectStore('recentPDFs', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('thumbnails')) {
          db.createObjectStore('thumbnails');
        }
      }
    },
  });
})();


function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [pdfs, setPdfs] = useState([]);
  const [activePdfId, setActivePdfId] = useState(null);
  const [activePdfText, setActivePdfText] = useState('');
  const [activePdfName, setActivePdfName] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  // Define the default size first
  const defaultChatboxSize = 38;  // Increased to 48 for a wider chat panel by default
  const minChatboxSize = 1;
  
  // Then use it in the state initialization
  const [chatboxSize, setChatboxSize] = useState(defaultChatboxSize);
  
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // First check if there's a saved preference
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      return savedMode === 'true';
    }
    // Otherwise check system preference
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [messages, setMessages] = useState([]);
  const [dismissedMessages, setDismissedMessages] = useState(() => {
    const saved = localStorage.getItem('dismissedMessages');
    return saved ? JSON.parse(saved) : [];
  });

  // Detect system language on component mount
  useEffect(() => {
    // This is just for logging - i18next-browser-languagedetector 
    // will automatically detect and set the language
    const systemLanguage = navigator.language || navigator.userLanguage;
    console.log('Detected system language:', systemLanguage);
    
    // If no language is set in localStorage, set it based on system language
    if (!localStorage.getItem('i18nextLng')) {
      // Check if the system language starts with 'zh' (Chinese)
      if (systemLanguage.startsWith('zh')) {
        i18n.changeLanguage('zh');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }, [i18n]);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [messageVisible, setMessageVisible] = useState(true);
  const [openPdfs, setOpenPdfs] = useState([]);
  const [recentPdfs, setRecentPdfs] = useState([]);
  const [fullPDFText, setFullPDFText] = useState('');
  const [isChatboxVisible, setIsChatboxVisible] = useState(true);

  useEffect(() => {
    // Apply dark mode class to body when component mounts or when isDarkMode changes
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    // Listen for changes in system color scheme preference
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setIsDarkMode(e.matches);
      if (e.matches) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    };

    mediaQuery.addListener(handleChange);

    // Cleanup function to remove the listener
    return () => mediaQuery.removeListener(handleChange);
  }, [isDarkMode]);

  useEffect(() => {
    ensurePDFJS().then((pdfjsLib) => {
      // You can put any PDF.js-dependent initialization here if needed
    });
  }, []);


  useEffect(() => {
    const checkAuth = async () => {
      // First check URL parameters for verification status
      const urlParams = new URLSearchParams(window.location.search);
      const verification = urlParams.get('verification');
      const email = urlParams.get('email');
      const reason = urlParams.get('reason');
      
      if (verification) {
        // Handle verification status from backend redirect
        if (verification === 'success' && email) {
          console.log('Email verification successful:', email);
          localStorage.setItem('verifiedEmail', email);
          localStorage.setItem('justVerified', 'true');
          setIsLoginModalOpen(true);
          
          // Show success message
          setMessages(prev => [...prev, {
            id: 'email-verification-success',
            type: 'success',
            title: 'Email Verified',
            content: 'Your email has been verified successfully. You can now log in.'
          }]);
        } else if (verification === 'failed') {
          console.error('Email verification failed:', reason);
          
          // Show error message
          let errorMessage = 'Failed to verify your email.';
          if (reason === 'invalid_token') {
            errorMessage = 'Invalid verification token. Please request a new verification email.';
          } else if (reason === 'expired_token') {
            errorMessage = 'Verification token has expired. Please request a new verification email.';
          }
          
          setMessages(prev => [...prev, {
            id: 'email-verification-error',
            type: 'error',
            title: 'Verification Failed',
            content: errorMessage
          }]);
        }
        
        // Clean up URL parameters
        window.history.replaceState({}, '', window.location.pathname);
      }
      
      // First check URL parameters for WeChat login
      const loginData = urlParams.get('wechat_login_data');
      
      if (loginData) {
        try {
          const data = JSON.parse(decodeURIComponent(loginData));
          console.log('Received WeChat login data:', data);
          
          // Save both user info and clean token as token to localStorage, the token is cleaned to remove any 'b' prefix and quotes if they exist
          localStorage.setItem('wechat_user_info', JSON.stringify(data.user_info));
          const cleanToken = data.token.replace(/^b['"]|['"]$/g, '');
          localStorage.setItem('wechat_token', cleanToken);
          localStorage.setItem('access_token', cleanToken); // Set as main token
          localStorage.setItem('auth_provider', 'wechat'); // Track auth provider
          setUserInfo(data.user_info);
          setIsAuthenticated(true);
          
          // Clear URL parameters
          window.history.replaceState({}, '', window.location.pathname);
          return;
        } catch (error) {
          console.error('Error parsing login data:', error);
        }
      }

      // Check for email login
      const emailToken = localStorage.getItem('email_token');
      const emailUserInfo = localStorage.getItem('email_user_info');
      
      if (emailToken && emailUserInfo) {
        try {
          const userInfo = JSON.parse(emailUserInfo);
          console.log('Found email login data');
          
          // Set as main token
          localStorage.setItem('access_token', emailToken);
          localStorage.setItem('auth_provider', 'email');
          
          // Verify token with backend
          const response = await fetch('https://api.labcat.com.cn/auth/check-login', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${emailToken}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          });
          
          if (response.ok) {
            const data = await response.json();
            setIsAuthenticated(true);
            setUserInfo(data.user_info);
            return;
          } else {
            console.error('Email token validation failed');
            // Clear invalid tokens
            localStorage.removeItem('email_token');
            localStorage.removeItem('email_user_info');
            localStorage.removeItem('access_token');
            localStorage.removeItem('auth_provider');
          }
        } catch (error) {
          console.error('Error checking email auth:', error);
        }
      }

      // If no email login, check WeChat token
      const token = localStorage.getItem('wechat_token');
      console.log('Stored WeChat token:', token);
      
      if (token) {
        try {
          const response = await fetch('https://api.labcat.com.cn/wechat/check-login', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            credentials: 'include'  // Include cookies if any
          });
          
          console.log('Check login response status:', response.status);
          
          if (response.ok) {
            const data = await response.json();
            console.log('Check login response:', data);
            setIsAuthenticated(true);
            setUserInfo(data.user_info);
            localStorage.setItem('access_token', token); // Set as main token
            localStorage.setItem('auth_provider', 'wechat'); // Track auth provider
          } else {
            console.error('Token validation failed:', await response.text());
            // For Spotify-like behavior, we don't open login modal automatically
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Error checking auth:', error);
          // For Spotify-like behavior, we don't open login modal automatically
          setIsAuthenticated(false);
        }
      } else {
        console.log('No token found in localStorage');
        // For Spotify-like behavior, we don't open login modal automatically
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  // Add event listener for postMessage from WeChat login bridge
  useEffect(() => {
    const handleMessage = (event) => {
      // Only accept messages from our own origins
      const validOrigins = [
        'https://api.labcat.com.cn',
        window.location.origin, 
        'http://localhost:3000'
      ];
      
      if (!validOrigins.includes(event.origin)) {
        return;
      }
      
      try {
        // Check if the message contains WeChat login data
        if (event.data && typeof event.data === 'string' && event.data.includes('wechat_login_data')) {
          const urlParams = new URLSearchParams(event.data);
          const loginData = urlParams.get('wechat_login_data');
          
          if (loginData) {
            const data = JSON.parse(decodeURIComponent(loginData));
            console.log('Received WeChat login data via postMessage:', data);
            
            // Store the token and user info
            localStorage.setItem('wechat_user_info', JSON.stringify(data.user_info));
            const cleanToken = data.token.replace(/^b['"]|['"]$/g, '');
            localStorage.setItem('wechat_token', cleanToken);
            localStorage.setItem('access_token', cleanToken); // Set as main token
            localStorage.setItem('auth_provider', 'wechat'); // Track auth provider
            setUserInfo(data.user_info);
            setIsAuthenticated(true);
            
            // Close login modal if open
            setIsLoginModalOpen(false);
          }
        }
      } catch (error) {
        console.error('Error processing message:', error);
      }
    };
    
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleLogout = () => {
    const authProvider = localStorage.getItem('auth_provider');
    
    // Clear all auth tokens
    localStorage.removeItem('wechat_token');
    localStorage.removeItem('wechat_user_info');
    localStorage.removeItem('email_token');
    localStorage.removeItem('email_user_info');
    localStorage.removeItem('access_token');
    localStorage.removeItem('auth_provider');
    
    setUserInfo(null);
    setIsAuthenticated(false);
    setIsLoginModalOpen(true);
    
    // Call the appropriate logout endpoint
    if (authProvider === 'wechat') {
      fetch('https://api.labcat.com.cn/wechat/logout', { method: 'POST' })
        .catch(error => console.error('Error during logout:', error));
    } else if (authProvider === 'email') {
      fetch('https://api.labcat.com.cn/auth/logout', { method: 'POST' })
        .catch(error => console.error('Error during logout:', error));
    }
  };


  useEffect(() => {
    const loadPDFs = async () => {
      try {
        const db = await dbPromise;
        const openPDFsData = await db.getAll('openPDFs');
        const recentPDFsData = await db.getAll('recentPDFs');
        
        setOpenPdfs(openPDFsData);
        setRecentPdfs(recentPDFsData);
        
        const sortedOpenPDFs = openPDFsData.sort((a, b) => (b.lastViewed || 0) - (a.lastViewed || 0));
        
        if (sortedOpenPDFs.length > 0) {
          setActivePdfId(sortedOpenPDFs[0].id);
        } else {
          setActivePdfId(null);
        }
      } catch (error) {
        console.error('Error loading PDFs:', error);
      }
    };

    loadPDFs();
  }, []);

  useEffect(() => {
    if (activePdfId) {
      localStorage.setItem('activePdfId', activePdfId);
    } else {
      localStorage.removeItem('activePdfId');
    }
  }, [activePdfId]);

  const handleTextSelection = (text) => {
    setSelectedText(text);
  };

  const handlePDFLoad = (text) => {
    setFullPDFText(text);
  };

  const extractMetadata = async (pdfData) => {
    const pdfjsLib = await ensurePDFJS();
    const loadingTask = pdfjsLib.getDocument({data: pdfData});
    const pdf = await loadingTask.promise;
    const metadata = await pdf.getMetadata();

  // Helper function to get the first non-empty value
  const getFirstNonEmpty = (...values) => values.find(v => v && v.trim() !== '') || '';
  
  const subject = getFirstNonEmpty(metadata.info.Subject, '');
  
  // Extract journal name and handle potential numbers after it
  let journalName = subject.split(',')[0].trim();
  
  // Regular expression to match journal name followed by year/volume/page numbers
  const journalRegex = /^(.*?)(?:\s+\d{4}\.?\d*:?\d*-?\d*)/;
  const match = journalName.match(journalRegex);
  
  if (match) {
    journalName = match[1].trim();
  }
  
  const journalMetrics = getJournalMetrics(journalName);

    return {
      name: getFirstNonEmpty(metadata.info.Title, 'Untitled'),
      author: getFirstNonEmpty(metadata.info.Author, metadata.info.Creator, 'Unknown'),
      subject,
      journalName,
      journalMetrics,
      keywords: getFirstNonEmpty(metadata.info.Keywords, ''),
      creationDate: getFirstNonEmpty(metadata.info.CreationDate, ''),
      modificationDate: getFirstNonEmpty(metadata.info.ModDate, ''),
      creator: getFirstNonEmpty(metadata.info.Creator, ''),
      producer: getFirstNonEmpty(metadata.info.Producer, ''),
    };
  };

  const generateMD5 = (arrayBuffer) => {
    const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
    return CryptoJS.MD5(wordArray).toString();
  };

  const handleAddPdf = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const blob = new Blob([arrayBuffer], { type: file.type });
    const newId = generateMD5(arrayBuffer);
    
    try {
      const metadata = await extractMetadata(arrayBuffer);
      
      const db = await dbPromise;
      
      // Check if the PDF already exists in recentPDFs
      const existingPdf = await db.get('recentPDFs', newId);
      if (existingPdf) {
        console.log('PDF already exists in recent, updating lastViewed');
        const updatedMetadata = { ...existingPdf, lastViewed: Date.now() };
        await db.put('recentPDFs', updatedMetadata);
        await db.put('openPDFs', updatedMetadata);
      } else {
        const newPdf = { 
          id: newId, 
          name: metadata.name || file.name, 
          ...metadata, 
          lastViewed: Date.now() 
        };
        await db.put('pdfs', blob, newId);
        await db.put('metadata', newPdf, newId);
        await db.put('recentPDFs', newPdf);
        await db.put('openPDFs', newPdf);
        setRecentPdfs((prevPdfs) => [...prevPdfs, newPdf]);
      }
      
      setOpenPdfs((prevPdfs) => {
        const updatedPdfs = prevPdfs.filter(pdf => pdf.id !== newId);
        return [...updatedPdfs, { id: newId, name: metadata.name || file.name, ...metadata, lastViewed: Date.now() }];
      });
      setActivePdfId(newId);
    } catch (error) {
      console.error('Error adding PDF:', error);
    }
  };

  const handleTabChange = async (id) => {
    console.log('handleTabChange called with id:', id);
    
    if (id === null) {
      setActivePdfId(null);
      return;
    }

    if (!id) {
      console.error('Invalid id provided to handleTabChange:', id);
      return;
    }

    setActivePdfId(id);
    setFullPDFText('');

    try {
      const db = await dbPromise;
      console.log('Fetching metadata for id:', id);
      const metadata = await db.get('recentPDFs', id);
      
      if (metadata) {
        console.log('Metadata found:', metadata);
        const updatedMetadata = { ...metadata, lastViewed: Date.now() };
        await db.put('recentPDFs', updatedMetadata);

        // Check if the PDF is already in openPDFs
        const isAlreadyOpen = openPdfs.some(pdf => pdf.id === id);

        if (!isAlreadyOpen) {
          // If not, add it to openPDFs
          setOpenPdfs(prevOpenPdfs => [...prevOpenPdfs, updatedMetadata]);
          await db.put('openPDFs', updatedMetadata);
        } else {
          // If it's already open, just update its metadata
          setOpenPdfs(prevOpenPdfs => 
            prevOpenPdfs.map(pdf => pdf.id === id ? updatedMetadata : pdf)
          );
          await db.put('openPDFs', updatedMetadata);
        }
      } else {
        console.error('PDF metadata not found in recentPDFs for id:', id);
      }
    } catch (error) {
      console.error('Error updating lastViewed timestamp:', error);
      console.error('Error details:', error.stack);
    }
  };

  const handleClosePdf = async (id) => {
    setOpenPdfs((prevPdfs) => prevPdfs.filter(pdf => pdf.id !== id));
    if (activePdfId === id) {
      setActivePdfId((prevId) => {
        const remainingPdfs = openPdfs.filter(pdf => pdf.id !== id);
        const newActiveId = remainingPdfs.length > 0 ? remainingPdfs[remainingPdfs.length - 1].id : null;
        localStorage.setItem('activePdfId', newActiveId);
        return newActiveId;
      });
    }
    
    // Remove PDF from openPDFs in IndexedDB
    const db = await dbPromise;
    await db.delete('openPDFs', id);
  };

  const handleRemoveFromRecent = async (id) => {
    setRecentPdfs((prevPdfs) => prevPdfs.filter(pdf => pdf.id !== id));
    
    // Remove PDF from openPDFs if it's currently open
    setOpenPdfs((prevOpenPdfs) => prevOpenPdfs.filter(pdf => pdf.id !== id));
    
    // If the removed PDF is the active one, set a new active PDF or null
    if (activePdfId === id) {
      const remainingOpenPdfs = openPdfs.filter(pdf => pdf.id !== id);
      setActivePdfId(remainingOpenPdfs.length > 0 ? remainingOpenPdfs[0].id : null);
    }
    
    // Remove PDF from IndexedDB
    const db = await dbPromise;
    await db.delete('recentPDFs', id);
    await db.delete('openPDFs', id);
    await db.delete('pdfs', id);
    await db.delete('metadata', id);
    await db.delete('thumbnails', id);
  };

  const handleCollapseToggle = () => {
    setIsChatboxVisible(prev => {
      const newVisibility = !prev;
      
      if (newVisibility) {
        setChatboxSize(defaultChatboxSize);
      } else {
        setChatboxSize(minChatboxSize);
      }
      
      return newVisibility;
    });
  };

  const handleChatboxResize = (sizes) => {
    const newChatboxSize = sizes[1];
    
    if (typeof newChatboxSize === 'number' && !isNaN(newChatboxSize)) {
      setChatboxSize(newChatboxSize);
      setIsChatboxVisible(newChatboxSize > minChatboxSize);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      if (newMode) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
      return newMode;
    });
  };

  const handleMessageDismiss = (messageId) => {
    const newDismissedMessages = {
      ...dismissedMessages,
      [messageId]: true
    };
    setDismissedMessages(newDismissedMessages);
    localStorage.setItem('dismissedMessages', JSON.stringify(newDismissedMessages));
    setMessageVisible(false);
  };

  // Enhanced resizable panel with animations
  const resizablePanelGroup = (
    <ResizablePanelGroup 
      direction="horizontal" 
      onLayout={handleChatboxResize}
      className="flex-1 overflow-hidden relative"
    >
      <ResizablePanel 
        minSize={40}
        defaultSize={isChatboxVisible ? 100 - chatboxSize : 100}
        className="overflow-hidden"
      >      
        <motion.div 
          className="h-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <PDFViewer
            key={activePdfId}
            pdfId={activePdfId}
            onTextSelection={handleTextSelection}
            onPDFLoad={handlePDFLoad}
            isDarkMode={isDarkMode}
          />
        </motion.div>
      </ResizablePanel>
      <ResizableHandle className={`w-2 ${isDarkMode ? 'bg-gray-700 hover:bg-blue-600 transition-colors duration-300' : 'bg-gray-300 hover:bg-blue-400 transition-colors duration-300'}`}>
        <div className={`absolute inset-y-0 left-1/2 transform -translate-x-1/2 w-1 ${isDarkMode ? 'bg-gray-600' : 'bg-gray-400'} rounded-full opacity-50`}></div>
      </ResizableHandle>
      <ResizablePanel 
        defaultSize={chatboxSize}
        minSize={minChatboxSize}
        maxSize={60}
        className="overflow-hidden relative"
        style={{ display: isChatboxVisible ? 'block' : 'none' }}
      >
        {isChatboxVisible && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            className="h-full"
          >
            <motion.button
              onClick={handleCollapseToggle}
              className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-50 p-1.5 rounded-full transition-all duration-300 ease-in-out
                ${isDarkMode 
                  ? 'bg-gray-800 text-gray-200 hover:bg-gray-700' 
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-100'
                }
                shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2
                ${isDarkMode ? 'focus:ring-gray-500' : 'focus:ring-gray-300'}
              `}
              aria-label="Collapse chatbox"
              whileHover={{ scale: 1.1, x: -2 }}
              whileTap={{ scale: 0.9 }}
            >
              <IoChevronForward className="h-4 w-4" />
            </motion.button>
            <Chatbox 
              selectedText={selectedText} 
              fullPDFText={fullPDFText} 
              activePdfName={openPdfs.find(pdf => pdf.id === activePdfId)?.name}
              isDarkMode={isDarkMode}
              isAuthenticated={isAuthenticated}
              onLogin={() => setIsLoginModalOpen(true)}
            />
          </motion.div>
        )}
      </ResizablePanel>
      {!isChatboxVisible && (
        <motion.button
          onClick={handleCollapseToggle}
          className={`absolute right-2 top-1/2 transform -translate-y-1/2 z-50 p-1.5 rounded-full transition-all duration-300 ease-in-out
            ${isDarkMode 
              ? 'bg-blue-800 text-gray-200 hover:bg-blue-700' 
              : 'bg-blue-200 text-gray-600 hover:bg-blue-300'
            }
            shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2
            ${isDarkMode ? 'focus:ring-gray-500' : 'focus:ring-gray-300'}
          `}
          aria-label="Expand chatbox"
          whileHover={{ scale: 1.1, x: 2 }}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
        >
          <IoChevronBack className="h-4 w-4" />
        </motion.button>
      )}
    </ResizablePanelGroup>
  );

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'bg-[#0d1117]' : 'bg-white'}`}>
      <MessageBanner 
        isDarkMode={isDarkMode} 
        isVisible={messageVisible}
        onDismiss={handleMessageDismiss}
        dismissedMessages={dismissedMessages}
      />
      <motion.div 
        className="flex-1 overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={
              openPdfs.length === 0 || activePdfId === null ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <HomeTab 
                    recentPdfs={recentPdfs} 
                    onOpenPdf={handleTabChange} 
                    onAddPdf={handleAddPdf} 
                    onRemoveFromRecent={handleRemoveFromRecent}
                    isDarkMode={isDarkMode}
                    toggleDarkMode={toggleDarkMode}
                    userInfo={userInfo}
                    onLogout={handleLogout}
                    messageVisible={messageVisible}
                    onMessageDismiss={handleMessageDismiss}
                    dismissedMessages={dismissedMessages}
                    isAuthenticated={isAuthenticated}
                    onLogin={() => setIsLoginModalOpen(true)}
                  />
                </motion.div>
              ) : (
                <motion.div 
                  className="flex flex-col h-full overflow-hidden"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <TabBar 
                    pdfs={openPdfs} 
                    activePdfId={activePdfId} 
                    onTabChange={handleTabChange} 
                    onAddPdf={handleAddPdf}
                    onCloseTab={handleClosePdf}
                    isDarkMode={isDarkMode}
                    toggleDarkMode={toggleDarkMode}
                    onLogout={handleLogout}
                    isAuthenticated={isAuthenticated}
                    onLogin={() => setIsLoginModalOpen(true)}
                  />
                  <div className="flex-1 overflow-hidden">
                    {resizablePanelGroup}
                  </div>
                </motion.div>
              )
            } />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/translation" element={<TranslationPage isDarkMode={isDarkMode} />} />
            <Route path="/translate/:pdfId" element={
              <TranslationPage 
                fullPDFText={activePdfText}
                activePdfName={activePdfName} 
                isDarkMode={isDarkMode}
                onLogin={() => setIsLoginModalOpen(true)}
              />
            } />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-cancel" element={<PaymentCancel />} />
          </Routes>
        </AnimatePresence>
      </motion.div>
      
      <AnimatePresence>
        {isLoginModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoginModal
              isOpen={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <UpdateNotification />
    </div>
  );
}

export default App;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// The AllOrigins CORS proxy URL - the only one that's working reliably
const ALLORIGINS_PROXY = 'https://api.allorigins.win/raw?url=';

const FileUploadArea = ({ onAddPdf, isDarkMode }) => {
  const { t } = useTranslation();
  const [showUrlModal, setShowUrlModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [useDirectUrl, setUseDirectUrl] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onAddPdf(acceptedFiles[0]);
    }
  }, [onAddPdf]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    multiple: false
  });

  const handleOpenWebPdf = () => {
    setShowUrlModal(true);
    setError('');
    setRetryCount(0);
  };

  // Function to fetch PDF with retry capability
  const fetchPdfWithRetry = async (url, isDirectUrl, maxRetries = 2) => {
    setIsLoading(true);
    
    try {
      // If direct URL option is selected, pass URL directly to PDF viewer
      if (isDirectUrl) {
        // Create a special file object that signals to use the URL directly
        const fileObj = {
          name: url.split('/').pop() || 'web-pdf.pdf',
          type: 'application/pdf',
          size: 0,
          isWebUrl: true,
          webUrl: url
        };
        
        onAddPdf(fileObj);
        setShowUrlModal(false);
        setPdfUrl('');
        setIsLoading(false);
        return true;
      }
      
      // Otherwise proceed with AllOrigins proxy-based loading
      let fetchUrl = `${ALLORIGINS_PROXY}${encodeURIComponent(url)}`;
      
      // Fetch the PDF file using AllOrigins proxy
      const response = await fetch(fetchUrl, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const pdfBlob = await response.blob();
      
      // Ensure the blob is correctly identified as a PDF
      const pdfBlobWithType = new Blob([pdfBlob], { type: 'application/pdf' });
      
      // Create a File object from the blob
      const fileName = url.split('/').pop() || 'web-pdf.pdf';
      const file = new File([pdfBlobWithType], fileName, { type: 'application/pdf' });
      
      // Pass the file to the same handler used for uploaded PDFs
      onAddPdf(file);
      
      // Close the modal and reset the URL
      setShowUrlModal(false);
      setPdfUrl('');
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error(`Attempt ${retryCount + 1} failed:`, error);
      
      if (retryCount < maxRetries) {
        // Increment retry count and try again
        setRetryCount(prev => prev + 1);
        return fetchPdfWithRetry(url, isDirectUrl, maxRetries);
      } else {
        // All retries failed
        console.error('Error loading PDF from URL after retries:', error);
        if (!isDirectUrl) {
          setError(`${error.message}. ${t('pdf.tryDirectOption')}`);
        } else {
          setError(`${error.message || t('pdf.invalidUrl')}`);
        }
        setIsLoading(false);
        return false;
      }
    }
  };

  const handleUrlSubmit = async (e) => {
    e.preventDefault();
    if (!pdfUrl.trim()) return;
    
    try {
      setIsLoading(true);
      setError('');
      setRetryCount(0);
      
      // Simple validation for PDF URL
      if (!pdfUrl.trim().toLowerCase().endsWith('.pdf')) {
        throw new Error(t('pdf.invalidUrl'));
      }
      
      // Start the fetch process with retry
      await fetchPdfWithRetry(pdfUrl, useDirectUrl);
      
    } catch (error) {
      console.error('Error in submission:', error);
      setError(error.message || t('pdf.invalidUrl'));
      setIsLoading(false);
    }
  };

  return (
    <div className="relative font-sans">
      <div className={`rounded-xl overflow-hidden shadow-lg ${isDarkMode ? 'bg-[#161b22]/80' : 'bg-white/80'} backdrop-blur-md transition-all hover:shadow-xl`}>
        <div
          {...getRootProps()}
          className={`
            border-2 border-dashed rounded-xl p-6 text-center transition-all duration-300
            ${isDarkMode 
              ? 'border-blue-500/40 bg-gradient-to-b from-[#1a1f2e]/70 to-[#161b22]/70 hover:from-[#1a1f2e]/90 hover:to-[#161b22]/90' 
              : 'border-blue-400/40 bg-gradient-to-b from-gray-50/90 to-white/90 hover:from-gray-50 hover:to-white'
            }
            ${isDragActive 
              ? 'border-blue-500 shadow-lg shadow-blue-500/20 scale-[1.02]' 
              : 'hover:border-blue-500/60 hover:shadow-lg hover:-translate-y-1'
            }
          `}
        >
          <input {...getInputProps()} />
          <label className="cursor-pointer flex flex-col items-center justify-center py-4">
            <div className={`text-5xl mb-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`}>
              📄
            </div>
            <span className={`text-xl font-medium tracking-tight ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`}>
              {t('pdf.clickToUpload')}
            </span>
            <span className={`text-base mt-2 font-light ${
              isDarkMode ? 'text-gray-300/80' : 'text-gray-600/80'
            }`}>
              {t('pdf.or')} {t('pdf.dropPDFHere')}
            </span>
          </label>
        </div>
      </div>
      
      {/* Web PDF Button */}
      {/* <button
        onClick={handleOpenWebPdf}
        className={`w-full mt-2 py-2 rounded-md transition-all duration-200 font-semibold
          ${isDarkMode 
            ? 'bg-[#1a1f2e]/80 text-blue-400 hover:bg-[#1a1f2e] hover:text-blue-300 border border-blue-500/30' 
            : 'bg-white/80 text-blue-600 hover:bg-white hover:text-blue-500 border border-blue-400/30'
          }
          hover:shadow-md hover:-translate-y-0.5
        `}
      >
        🌐 {t('pdf.openWebPdf')}
      </button> */}
      
      {/* URL Modal */}
      {showUrlModal && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className={`w-full max-w-md p-6 rounded-lg shadow-xl 
            ${isDarkMode ? 'bg-[#21262d] text-white' : 'bg-white text-gray-800'}`}
          >
            <h3 className="text-xl font-bold mb-4">{t('pdf.openWebPdf')}</h3>
            <form onSubmit={handleUrlSubmit}>
              <input
                type="url"
                value={pdfUrl}
                onChange={(e) => setPdfUrl(e.target.value)}
                placeholder="https://example.com/document.pdf"
                className={`w-full p-2 mb-3 rounded border 
                  ${isDarkMode 
                    ? 'bg-[#0d1117] border-gray-600 text-white' 
                    : 'bg-white border-gray-300 text-gray-800'
                  } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                required
              />
              
              {/* Loading method selection */}
              <div className="mb-4 flex items-center">
                <input
                  id="direct-url-checkbox"
                  type="checkbox"
                  checked={useDirectUrl}
                  onChange={(e) => setUseDirectUrl(e.target.checked)}
                  className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 rounded"
                />
                <label htmlFor="direct-url-checkbox" className="text-sm">
                  {t('pdf.useDirectUrl')}
                </label>
                <div className="ml-1 group relative">
                  <span className="text-gray-500 cursor-help">ⓘ</span>
                  <div className={`absolute left-6 bottom-0 w-64 p-2 rounded shadow-lg text-xs hidden group-hover:block
                    ${isDarkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800'}`}>
                    {t('pdf.useDirectUrlHelp')}
                  </div>
                </div>
              </div>
              
              {!useDirectUrl && (
                <div className="mb-4">
                  <p className="text-xs text-gray-500">
                    {t('pdf.proxyNotice')}
                  </p>
                </div>
              )}
              
              {error && (
                <div className="mb-4 text-red-500 text-sm">{error}</div>
              )}
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowUrlModal(false)}
                  className={`px-4 py-2 rounded 
                    ${isDarkMode 
                      ? 'bg-gray-700 hover:bg-gray-600' 
                      : 'bg-gray-200 hover:bg-gray-300'
                    } transition-colors`}
                >
                  {t('common.cancel')}
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`px-4 py-2 rounded bg-blue-600 hover:bg-blue-700 text-white transition-colors flex items-center ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                >
                  {isLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      {retryCount > 0 ? `${t('common.loading')} (${t('pdf.retrying')} ${retryCount}/2)` : t('common.loading')}
                    </>
                  ) : t('pdf.openPdf')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadArea;

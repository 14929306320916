import React, { useRef, useState, useEffect } from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import { IoLogIn } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const TabBar = ({ pdfs, activePdfId, onTabChange, onAddPdf, onCloseTab, isAuthenticated = true, onLogin }) => {
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [tabWidth, setTabWidth] = useState(200); // Default tab width
  const allTabsRef = useRef(null);
  const pdfTabsRef = useRef(null);
  const plusButtonRef = useRef(null);
  const { t } = useTranslation();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onAddPdf(file);
    }
  };

  const scrollTabs = (direction) => {
    if (pdfTabsRef.current) {
      pdfTabsRef.current.scrollBy({ left: direction * 100, behavior: 'smooth' });
    }
  };

  const handleTabChange = (id) => {
    console.log('Tab change requested for id:', id);
    if (id === null || pdfs.some(pdf => pdf.id === id)) {
      onTabChange(id);
    } else {
      console.error('Invalid PDF id:', id);
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      if (allTabsRef.current && pdfTabsRef.current && plusButtonRef.current) {
        const containerWidth = allTabsRef.current.clientWidth;
        const tabsWidth = pdfTabsRef.current.scrollWidth;
        const plusButtonWidth = plusButtonRef.current.offsetWidth;
        const maxPlusButtonPosition = containerWidth - plusButtonWidth - 10; // 10px buffer

        setShowLeftScroll(pdfTabsRef.current.scrollLeft > 0);

        // Adjust tab width if necessary
        if (tabsWidth + plusButtonWidth > containerWidth) {
          const newTabWidth = Math.max(120, (containerWidth - plusButtonWidth - 50) / (pdfs.length + 1));
          setTabWidth(newTabWidth);
        } else {
          setTabWidth(200); // Reset to default width if there's enough space
        }

        // Position the plus button
        if (tabsWidth > maxPlusButtonPosition) {
          plusButtonRef.current.style.position = 'absolute';
          plusButtonRef.current.style.right = '10px';
        } else {
          plusButtonRef.current.style.position = 'static';
        }
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [pdfs]);

  return (
    <div ref={allTabsRef} className="flex items-center bg-gray-900 p-1 pt-1 w-full relative overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-[#21262d]"></div>
      {showLeftScroll && (
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-900 hover:bg-gray-700 text-white px-2 py-1 rounded-l z-10"
          onClick={() => scrollTabs(-1)}
        >
          &lt;
        </button>
      )}
      <div 
        ref={pdfTabsRef} 
        className="flex space-x-1 overflow-x-auto scrollbar-hide relative z-10"
      >
        <button
          className={`px-3 py-1 rounded-t-lg flex-shrink-0 ${
            activePdfId === null 
              ? 'bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-500 text-white shadow-lg' 
              : 'bg-gradient-to-r from-indigo-500/25 via-purple-500/25 to-pink-500/25 text-gray-100 hover:from-indigo-500/40 hover:via-purple-500/40 hover:to-pink-500/40'
          }`}
          onClick={() => handleTabChange(null)}
          style={{ minWidth: `${tabWidth}px`, maxWidth: `${tabWidth}px` }}
        >
          <span className="flex items-center justify-center space-x-2">
            <span className="text-lg">🏠</span>
            <span className="font-semibold">Home</span>
          </span>
        </button>
        {pdfs.map((pdf) => (
          <button
            key={pdf.id}
            className={`px-3 py-1 rounded-t-lg flex items-center justify-between flex-shrink-0 ${
              pdf.id === activePdfId 
                ? 'bg-[#21262d] text-white' 
                : 'bg-gray-900 text-gray-200 hover:bg-gray-800'
            }`}
            onClick={() => handleTabChange(pdf.id)}
            style={{ minWidth: `${tabWidth}px`, maxWidth: `${tabWidth}px` }}
          >
            <span className="truncate flex-grow text-left">{pdf.name}</span>
            <span 
              className="ml-2 text-gray-400 hover:text-gray-200"
              onClick={(e) => {
                e.stopPropagation();
                onCloseTab(pdf.id);
              }}
            >
              ×
            </span>
          </button>
        ))}
      </div>
      <label ref={plusButtonRef} className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-lg ml-1 flex-shrink-0">
        <input
          type="file"
          accept=".pdf"
          className="hidden"
          onChange={handleFileChange}
        />
        +
      </label>
      
      {/* Login Button */}
      {!isAuthenticated && (
        <button
          onClick={onLogin}
          className="ml-auto mr-2 bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white px-3 py-1 rounded-lg flex items-center gap-1 shadow-md hover:shadow-lg transition-all duration-200"
        >
          <IoLogIn className="text-sm" />
          <span className="font-medium">{t('common.login')}</span>
        </button>
      )}
      
      {/* Language Switcher */}
      <div className={`${!isAuthenticated ? 'mr-2' : 'ml-auto mr-2'} relative z-50`}>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default TabBar;
import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; 
import { IoFlash, IoClose, IoRocket, IoSparkles, IoTrophy, IoLogoWechat, IoCardSharp, IoWalletOutline } from "react-icons/io5";
import TokenDisplay from './TokenDisplay';

// Fallback packages in case API fails
const DEFAULT_PACKAGES = [
  {
    amount: 100000,
    priceYuan: 10,
    priceInCents: 1000,
    bonus: 20,
    description: "中策",
    features: [
      "试用期特惠价格",
      "正式版补偿token"
    ],
    highlight: false,
    callToAction: "立即体验!"
  },
  {
    amount: 500000,
    priceYuan: 39.99,
    priceInCents: 3999,
    bonus: 20,
    description: "上策",
    features: [
      "试用期特惠价格",
      "正式版补偿token"
    ],
    highlight: true,
    savePercent: 30,
    callToAction: "超值优惠！立即购买"
  },
  {
    amount: 1000000,
    priceYuan: 69.99,
    priceInCents: 6999,
    bonus: 40,
    description: "上上策",
    features: [
      "试用期特惠价格",
      "正式版补偿token"
    ],
    highlight: false,
    savePercent: 40,
    callToAction: "尊享特权！"
  }
];

// Ensure all required fields exist
const transformPackage = (pkg) => ({
  ...pkg,
  features: pkg.features || DEFAULT_PACKAGES.find(p => p.amount === pkg.amount)?.features || ["Basic features"],
  highlight: !!pkg.highlight,
  savePercent: pkg.savePercent || null
});

const TokenPurchase = ({ isDarkMode, onClose }) => {
  const [qrUrl, setQrUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [packages, setPackages] = useState(DEFAULT_PACKAGES.map(transformPackage));
  const [stripePackages, setStripePackages] = useState([]);
  const [error, setError] = useState(null);
  const [initialBalance, setInitialBalance] = useState(null);
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [paymentInterval, setPaymentInterval] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('wechat'); // 'wechat' or 'stripe'
  const [stripeLoading, setStripeLoading] = useState(false);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        // Fetch WeChat packages
        const response = await fetch('https://api.labcat.com.cn/wechat/pay/packages');
        if (!response.ok) {
          throw new Error('Failed to fetch packages');
        }
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setPackages(data.map(transformPackage));
        }

        // Fetch Stripe packages
        const stripeResponse = await fetch('https://api.labcat.com.cn/stripe/packages');
        if (stripeResponse.ok) {
          const stripeData = await stripeResponse.json();
          if (Array.isArray(stripeData) && stripeData.length > 0) {
            setStripePackages(stripeData);
          }
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
        // Keep using DEFAULT_PACKAGES if API fails
        
        // Create default Stripe packages if API fails
        if (stripePackages.length === 0) {
          setStripePackages([
            {
              amount: 100000,
              priceUsd: 1.99,
              priceInCents: 199,
              bonus: 20,
              description: "Starter",
              features: ["Trial period special price", "Token compensation in official version"],
              total: 120000
            },
            {
              amount: 500000,
              priceUsd: 7.99,
              priceInCents: 799,
              bonus: 20,
              description: "Popular",
              features: ["Trial period special price", "Token compensation in official version"],
              highlight: true,
              savePercent: 30,
              total: 600000
            },
            {
              amount: 1000000,
              priceUsd: 14.99,
              priceInCents: 1499,
              bonus: 40,
              description: "Premium",
              features: ["Trial period special price", "Token compensation in official version"],
              savePercent: 40,
              total: 1400000
            }
          ]);
        }
      }
    };

    fetchPackages();
  }, []);

  useEffect(() => {
    return () => {
      if (paymentInterval) {
        clearInterval(paymentInterval);
      }
    };
  }, [paymentInterval]);

  const startPaymentCheck = async () => {
    try {
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await fetch('https://api.labcat.com.cn/api/tokens/balance', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setInitialBalance(data.tokens_remaining);
        setCheckingPayment(true);

        // Start polling for payment status
        const intervalId = setInterval(async () => {
          const checkResponse = await fetch('https://api.labcat.com.cn/api/tokens/balance', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (checkResponse.ok) {
            const checkData = await checkResponse.json();
            if (checkData.tokens_remaining > data.tokens_remaining) {
              // Payment successful!
              clearInterval(intervalId);
              setCheckingPayment(false);
              setQrUrl('');
              setShowThankYou(true);
              setTimeout(() => {
                setShowThankYou(false);
                onClose();
              }, 2500);
            }
          }
        }, 2000); // Check every 2 seconds

        setPaymentInterval(intervalId);
      }
    } catch (error) {
      console.error('Error checking payment:', error);
      setError('检查支付状态时出错');
    }
  };

  const handleBalanceChange = (newBalance) => {
    console.log('Balance change detected:', initialBalance, '->', newBalance);
    if (initialBalance !== null && newBalance > initialBalance) {
      // Payment successful!
      setCheckingPayment(false);
      // Show success message
      setError('Payment successful! Closing window...');
      setTimeout(() => {
        onClose();
      }, 1500);
    }
  };

  const handleWeChatPurchase = async (packageInfo) => {
    setSelectedAmount(packageInfo.amount);
    try {
      setLoading(true);
      setError(null);
      
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('Please login first');
      }

      const response = await fetch('https://api.labcat.com.cn/wechat/pay/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          amount: packageInfo.priceInCents
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create payment');
      }

      const data = await response.json();
      if (data.code_url) {
        setQrUrl(data.code_url);
        startPaymentCheck(); // Start checking for payment
      } else {
        throw new Error('Invalid payment response');
      }
    } catch (error) {
      console.error('Payment error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStripePurchase = async (packageInfo) => {
    setSelectedAmount(packageInfo.amount);
    try {
      setStripeLoading(true);
      setError(null);
      
      // Get the authentication provider and the appropriate token
      const authProvider = localStorage.getItem('auth_provider');
      let token;
      
      if (authProvider === 'email') {
        token = localStorage.getItem('email_token');
      } else {
        // Default to wechat token for backward compatibility
        token = localStorage.getItem('wechat_token');
      }
      
      // Use the access_token as fallback if specific token not found
      if (!token) {
        token = localStorage.getItem('access_token');
      }
      
      if (!token) {
        throw new Error('Please login first');
      }

      // Create a checkout session
      const response = await fetch('https://api.labcat.com.cn/stripe/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          amount: packageInfo.amount,
          success_url: window.location.origin + '/payment-success',
          cancel_url: window.location.origin + '/payment-cancel'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to create checkout session');
      }

      const data = await response.json();
      
      // Redirect to Stripe Checkout
      window.location.href = data.url;
      
    } catch (error) {
      console.error('Stripe payment error:', error);
      setError(error.message);
      setStripeLoading(false);
    }
  };

  const handlePurchase = (packageInfo) => {
    if (paymentMethod === 'wechat') {
      handleWeChatPurchase(packageInfo);
    } else {
      handleStripePurchase(packageInfo);
    }
  };

  // Get the current package set based on payment method
  const currentPackages = paymentMethod === 'wechat' ? packages : stripePackages;

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center p-4 backdrop-blur-md ${isDarkMode ? 'bg-black/80' : 'bg-gray-600/70'}`}>
      <div className={`relative w-full max-w-4xl rounded-xl shadow-2xl overflow-hidden
        ${isDarkMode ? 'bg-[#0d1117]' : 'bg-gray-50'}`}>
        
        {/* Cyberpunk background effects */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute h-full w-1/2 -left-1/4 top-0 opacity-30">
            <div className="h-full w-full blur-3xl">
              <div className={`rotate-12 h-full w-full bg-gradient-to-r from-green-500 to-emerald-500`}></div>
            </div>
          </div>
          <div className="absolute h-full w-1/2 right-0 top-0 opacity-30">
            <div className="h-full w-full blur-3xl">
              <div className={`-rotate-12 h-full w-full bg-gradient-to-l from-blue-500 to-purple-500`}></div>
            </div>
          </div>
        </div>

        {/* Close button - fixed version */}
        <button
          onClick={() => {
            setQrUrl(''); // Clear QR code if it exists
            onClose(); // Call the onClose prop
          }}
          className="absolute right-4 top-4 text-gray-400 hover:text-white z-20 transition-colors duration-200 p-2"
        >
          <IoClose size={24} />
        </button>

        {/* Glowing border effect */}
        <div className="absolute inset-0 border border-green-500/30 rounded-xl"></div>
        <div className="absolute inset-0 border border-green-500/20 rounded-xl animate-pulse"></div>
        
        <div className="relative z-10 p-8">
          <h2 className="text-4xl font-bold mb-8 text-center font-rajdhani">
            <span className="bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
              UPGRADE YOUR RESEARCH
            </span>
            <div className="text-lg text-gray-400 mt-2 font-normal">
              解锁更多AI研究助手功能
            </div>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {packages?.map((pkg, index) => {
              // Find matching Stripe package by amount
              const stripePkg = stripePackages.find(p => p.amount === pkg.amount) || {
                priceUsd: pkg.priceInCents / 100,
                priceInCents: pkg.priceInCents / 10 * 2 // Approximate
              };
              
              return (
                <div
                  key={pkg.amount}
                  className={`relative overflow-hidden rounded-xl transition-all duration-300
                    ${pkg.amount === 1000000 ? 'transform-gpu hover:scale-105' : pkg.highlight ? 'transform-gpu hover:scale-105' : 'transform-gpu hover:scale-102'}
                    ${selectedAmount === pkg.amount 
                      ? 'btn-cyber border-2' 
                      : pkg.amount === 1000000  // Special styling for the most expensive package
                        ? `${isDarkMode ? 'bg-gray-900/50' : 'bg-white/90'} border-2 border-purple-500/50`
                        : `${isDarkMode ? 'bg-gray-900/50' : 'bg-white/90'} border border-gray-700/30`}
                    backdrop-blur-sm
                  `}
                >
                  {pkg.highlight && (
                    <div className="absolute -right-12 top-6 rotate-45 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black text-sm py-1 px-12 font-bold shadow-lg">
                      BEST DEAL
                    </div>
                  )}
                  {pkg.amount === 1000000 && (
                    <div className="absolute -right-12 top-6 rotate-45 bg-gradient-to-r from-purple-500 via-blue-500 to-purple-500 text-white text-sm py-1 px-12 font-bold shadow-lg">
                      PREMIUM
                    </div>
                  )}

                  <div className="p-6 flex flex-col h-full relative z-10">
                    <h3 className="text-xl font-rajdhani font-bold mb-2 text-center">
                      <span className={`
                        ${pkg.highlight ? 'text-yellow-500' : ''}
                        ${pkg.amount === 1000000 ? 'bg-gradient-to-r from-purple-400 via-blue-500 to-purple-400 bg-clip-text text-transparent' : 'text-cyber'}
                      `}>
                        {pkg.description}
                      </span>
                    </h3>

                    {/* Show both prices */}
                    <div className="flex justify-center items-center space-x-4 mb-4">
                      <div className={`text-2xl font-bold text-center font-rajdhani
                        ${pkg.highlight ? 'text-yellow-500' : ''}
                        ${pkg.amount === 1000000 ? 'bg-gradient-to-r from-purple-400 via-blue-500 to-purple-400 bg-clip-text text-transparent' : 'text-cyber'}
                      `}>
                        <div>
                          ¥{pkg.priceYuan}
                          <span className="text-xs ml-1 opacity-75">CNY</span>
                        </div>
                        <div>
                          ${stripePkg.priceUsd}
                          <span className="text-xs ml-1 opacity-75">USD</span>
                        </div>
                      </div>
                    </div>

                    <div className={`text-lg font-rajdhani mb-4 text-center
                      ${pkg.amount === 1000000 ? 'text-purple-400' : 'text-cyber'}
                    `}>
                      {pkg.amount.toLocaleString()} TOKENS
                      {pkg.bonus > 0 && (
                        <div className={`text-sm font-bold animate-pulse mt-1
                          ${pkg.amount === 1000000 ? 'text-blue-400' : 'text-green-500'}
                        `}>
                          +{pkg.bonus}% BONUS
                        </div>
                      )}
                    </div>

                    <ul className="space-y-3 mb-6 flex-grow text-sm">
                      {pkg.features ? pkg.features.map((feature, index) => (
                        <li key={index} className="flex items-center text-gray-300">
                          <span className={`mr-2 ${pkg.amount === 1000000 ? 'text-purple-500' : 'text-green-500'}`}>✓</span> {feature}
                        </li>
                      )) : (
                        <li className="flex items-center text-gray-300">
                          <span className="mr-2 text-green-500">✓</span> Basic features
                        </li>
                      )}
                    </ul>

                    {/* Payment buttons */}
                    <div className="flex flex-col space-y-2">
                      {/* WeChat Pay Button */}
                      <button 
                        onClick={() => handleWeChatPurchase(pkg)}
                        className={`py-2 px-4 rounded-lg font-rajdhani font-bold text-sm
                          transition-all duration-300 uppercase tracking-wider flex items-center justify-center 
                          space-x-2 bg-green-500 hover:bg-green-600 text-white`}
                        disabled={loading || stripeLoading}
                      >
                        <IoLogoWechat className="text-xl" />
                        <span>
                          {(loading && selectedAmount === pkg.amount) ? '处理中...' : '微信支付 ¥' + pkg.priceYuan}
                        </span>
                      </button>
                      
                      {/* Credit Card Button */}
                      <button 
                        onClick={() => handleStripePurchase(stripePkg)}
                        className={`py-2 px-4 rounded-lg font-rajdhani font-bold text-sm
                          transition-all duration-300 uppercase tracking-wider flex items-center justify-center 
                          space-x-2 bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white`}
                        disabled={loading || stripeLoading}
                      >
                        <IoCardSharp className="text-xl" />
                        <span>
                          {(stripeLoading && selectedAmount === pkg.amount) ? 'Processing...' : 'Credit Card $' + stripePkg.priceUsd}
                        </span>
                      </button>
                    </div>
                  </div>

                  {/* Background gradient effect */}
                  <div className={`absolute inset-0 
                    ${pkg.amount === 1000000 
                      ? 'bg-gradient-to-br from-transparent via-purple-500/5 to-blue-500/5' 
                      : 'bg-gradient-to-br from-transparent via-green-500/5 to-blue-500/5'}`}>
                  </div>
                </div>
              );
            })}
          </div>

          {/* WeChat QR Code Modal */}
          {qrUrl && (
            <div className="fixed inset-0 z-50 flex items-center justify-center p-4 backdrop-blur-md bg-black/80" 
              onClick={(e) => {
                if (e.target === e.currentTarget) {
                  if (paymentInterval) {
                    clearInterval(paymentInterval);
                  }
                  setQrUrl('');
                }
              }}>
              <div className="bg-[#0d1117] rounded-xl border border-green-500/30 shadow-2xl p-8 max-w-md w-full relative">
                <button
                  onClick={() => {
                    if (paymentInterval) {
                      clearInterval(paymentInterval);
                    }
                    setQrUrl('');
                  }}
                  className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors duration-200"
                >
                  <IoClose size={24} />
                </button>
                
                <div className="flex flex-col items-center space-y-6">
                  <div className="flex items-center space-x-2 text-2xl font-bold text-green-500">
                    <IoLogoWechat className="text-3xl" />
                    <span className="font-rajdhani">微信支付</span>
                  </div>
                  
                  <div className="relative p-4 bg-white rounded-lg">
                    <QRCodeCanvas value={qrUrl} size={240} />
                    {checkingPayment && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-ping h-4 w-4 rounded-full bg-green-400 opacity-75"></div>
                      </div>
                    )}
                  </div>

                  <div className="space-y-2 text-center">
                    <p className="text-lg font-semibold text-gray-300 font-rajdhani">
                      请使用微信扫描二维完成支付
                    </p>
                    {checkingPayment && (
                      <p className="text-green-500 text-base animate-pulse font-medium">
                        正在等待支付确认...
                      </p>
                    )}
                  </div>

                  <div className="text-sm text-gray-400 text-center">
                    <p>支付完成后将自动关闭窗口</p>
                    <p>如遇问题请刷新页面重试</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {error && (
            <div className="mt-4 p-3 rounded bg-red-500/20 border border-red-500/30 text-red-400 text-center">
              {error}
            </div>
          )}

          <p className="text-center text-gray-400 text-sm mt-8">
            <span className="text-green-500 font-bold">创始用户特权：</span> 
            试用期购买的token将在正式版上线后获得等额返还
            <IoRocket className="inline-block ml-2 text-blue-500 animate-pulse" />
          </p>
        </div>
      </div>

      {/* Thank You Modal */}
      {showThankYou && (
        <div className="fixed inset-0 z-[60] flex items-center justify-center p-4 backdrop-blur-md bg-black/80">
          <div className="bg-[#0d1117] rounded-xl border border-green-500/30 shadow-2xl p-8 max-w-md w-full relative animate-fade-up">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="text-6xl mb-2">🎉</div>
              <h3 className="text-2xl font-bold text-green-500 font-rajdhani">
                感谢您的购买！
              </h3>
              <p className="text-gray-300">
                您的Token已经添加到账户
              </p>
              <div className="text-sm text-gray-400 mt-2">
                让我们开始探索AI的无限可能
              </div>
              <div className="text-green-500 animate-pulse mt-2">
                <IoSparkles className="inline-block text-2xl" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TokenPurchase;
import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

const AnimatedTokenDisplay = ({ value, isPolling }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const prevValueRef = useRef(value);

  useEffect(() => {
    if (prevValueRef.current !== value) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  }, [value]);

  const isLowBalance = value < 10000;
  const glowClass = isPolling ? (isLowBalance ? 'animate-cyber-glow-warning' : 'animate-cyber-glow') : '';
  const textClass = isLowBalance ? 'text-cyber-warning' : 'text-cyber';

  return (
    <div className="inline-flex items-center">
      <div className="flex items-center">
        <CountUp
          start={prevValueRef.current}
          end={value}
          duration={shouldAnimate ? 1 : 0}
          separator=","
          decimal="."
          className={`text-lg font-bold ${textClass} ${glowClass}`}
          preserveValue={true}
        />
        <span className="ml-2 text-sm font-rajdhani font-bold opacity-75 uppercase tracking-wider"></span>
        {isPolling && (
          <span 
            className={`ml-2 w-1.5 h-1.5 rounded-full opacity-75 ${isLowBalance ? 'bg-orange-500' : 'bg-green-500'}`}
            style={{ 
              animation: 'pulse 1s infinite',
              boxShadow: `0 0 10px ${isLowBalance ? 'rgba(249, 115, 22, 0.5)' : 'rgba(34, 197, 94, 0.5)'}`
            }} 
          />
        )}
      </div>
    </div>
  );
};

export default AnimatedTokenDisplay;
import React, { useRef, useState, useEffect } from 'react';
import { IoAddCircle, IoClose, IoSearch, IoRefreshOutline, IoLanguage, IoChevronDown, IoChevronUp } from "react-icons/io5";
import { AiOutlineLoading } from "react-icons/ai";
import RecommendedPDF from './RecommendedPDF';
import { useTranslation } from 'react-i18next';
import { languageOptions } from '../utils/translation';
import { motion, AnimatePresence } from 'framer-motion';

const KeywordSubscriptions = ({
  isDarkMode,
  keywords,
  showSubscriptionInput,
  toggleSubscriptionInput,
  newKeyword,
  setNewKeyword,
  handleAddKeyword,
  handleRemoveKeyword,
  handleKeywordClick,
  selectedKeyword,
  displayedKeywords,
  isLoading,
  displayedPdfs,
  handlePubMedClick,
  showAllPapers,
  toggleShowAllPapers,
  loadingKeywords,
  handleUpdateAll
}) => {
  const { t, i18n } = useTranslation();
  const subscriptionInputRef = useRef(null);
  const langMenuRef = useRef(null);

  const [loadingCaption, setLoadingCaption] = useState('');
  const [isUpdateAllClicked, setIsUpdateAllClicked] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showLangDropdown, setShowLangDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'zh'
  );
  const [showLanguageNotification, setShowLanguageNotification] = useState(false);
  const [selectedLangName, setSelectedLangName] = useState('');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (subscriptionInputRef.current && !subscriptionInputRef.current.contains(event.target)) {
        toggleSubscriptionInput(false);
      }
      
      if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
        setShowLangDropdown(false);
      }
    };

    if (showSubscriptionInput || showLangDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSubscriptionInput, toggleSubscriptionInput, showLangDropdown]);

  useEffect(() => {
    if (isLoading) {
      setLoadingCaption(t('homeTab.searchingPubmed'));
    }
  }, [isLoading, t]);

  useEffect(() => {
    if (showSubscriptionInput && subscriptionInputRef.current) {
      subscriptionInputRef.current.querySelector('input').focus();
    }
  }, [showSubscriptionInput]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddKeyword();
    }
  };

  const handleUpdateAllClick = async () => {
    setIsUpdateAllClicked(true);
    setIsProcessing(true);
    await handleUpdateAll();
    setIsUpdateAllClicked(false);
    setIsProcessing(false);
  };

  const handleLanguageChange = (lang) => {
    const previousLang = selectedLanguage;
    setSelectedLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
    setShowLangDropdown(false);
    
    // Get the language name for the notification
    const langOption = languageOptions.find(option => option.value === lang);
    setSelectedLangName(langOption ? langOption.label : 'Selected Language');
    
    // Show notification
    setShowLanguageNotification(true);
    setTimeout(() => setShowLanguageNotification(false), 2000);
    
    // Map Baidu language codes to i18n language codes
    const langMap = {
      'zh': 'zh',      // Chinese Simplified
      'cht': 'zh-TW',  // Chinese Traditional
      'spa': 'es',     // Spanish
      'fra': 'fr',     // French
      'de': 'de',      // German
      'jp': 'ja',      // Japanese
      'ru': 'ru',      // Russian
    };
    
    // Only change i18n language if supported
    if (langMap[lang] && ['zh', 'en'].includes(langMap[lang])) {
      i18n.changeLanguage(langMap[lang]);
    }
  };

  const getSelectedLanguageLabel = () => {
    const lang = languageOptions.find(lang => lang.value === selectedLanguage);
    return lang ? lang.label.split(' ')[0] : 'Language';
  };

  return (
    <div className="mt-2 relative">
      {/* Language change notification */}
      <AnimatePresence>
        {showLanguageNotification && (
          <motion.div 
            className={`absolute top-0 right-0 px-3 py-1.5 rounded-md z-50 shadow-lg ${
              isDarkMode ? 'bg-blue-900/80 text-blue-200' : 'bg-blue-100 text-blue-700'
            } backdrop-blur-sm text-xs flex items-center`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <IoLanguage className="mr-1.5" size={14} />
            {t('common.languageChanged')}: <span className="font-semibold ml-1">{selectedLangName}</span>
          </motion.div>
        )}
      </AnimatePresence>
      
      <div className="flex items-center mb-2">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <h2 className={`text-xl font-bold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              {t('homeTab.keywords')}
            </h2>
            <button className={`text-xl font-bold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              <IoAddCircle
                className={`${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}`}
                size={25}
                onClick={toggleSubscriptionInput}
              />
            </button>
            <p className={`text-sm ml-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {t('homeTab.latestPapers')} ({new Date().toLocaleDateString(i18n.language === 'zh' ? 'zh-CN' : 'en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })})
            </p>
            <button
              onClick={handleUpdateAllClick}
              className={`ml-2 p-1 rounded-full transition-colors ${
                isDarkMode 
                  ? 'text-blue-400 hover:text-blue-300 hover:bg-blue-900/30' 
                  : 'text-blue-600 hover:text-blue-500 hover:bg-blue-100'
              }`}
              title={t('homeTab.updateAllKeywords')}
            >
              <IoRefreshOutline size={20} />
            </button>
            
            {/* Language Selection Button */}
            <div className="relative ml-2" ref={langMenuRef}>
              <motion.button
                onClick={() => setShowLangDropdown(!showLangDropdown)}
                className={`flex items-center space-x-1 px-2.5 py-1 rounded-md transition-all duration-200 ${
                  isDarkMode 
                    ? 'bg-blue-900/20 text-blue-400 hover:bg-blue-900/30 border border-blue-800/30' 
                    : 'bg-blue-100/80 text-blue-600 hover:bg-blue-200/80 border border-blue-200'
                } backdrop-blur-sm text-xs`}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: isDarkMode ? "0 0 8px rgba(59, 130, 246, 0.3)" : "0 0 8px rgba(59, 130, 246, 0.3)"
                }}
                whileTap={{ scale: 0.95 }}
              >
                <IoLanguage className={`mr-1 ${showLangDropdown ? 'animate-pulse' : ''}`} size={16} />
                <span>{getSelectedLanguageLabel()}</span>
                <motion.span
                  animate={{ 
                    rotate: showLangDropdown ? 180 : 0 
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <IoChevronDown className="ml-1" size={14} />
                </motion.span>
              </motion.button>
              
              {showLangDropdown && (
                <motion.div 
                  className={`absolute left-0 mt-1 z-50 rounded-md shadow-lg py-1 ${
                    isDarkMode 
                      ? 'bg-[#21262d] border border-[#30363d]' 
                      : 'bg-white border border-gray-200'
                  } max-h-52 overflow-y-auto w-48`}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  {languageOptions.map((lang) => (
                    <motion.button
                      key={lang.value}
                      onClick={() => handleLanguageChange(lang.value)}
                      className={`w-full text-left px-3 py-1.5 text-xs ${
                        selectedLanguage === lang.value 
                          ? (isDarkMode 
                              ? 'bg-blue-900/30 text-blue-300' 
                              : 'bg-blue-100 text-blue-700'
                            )
                          : (isDarkMode 
                              ? 'text-gray-300 hover:bg-[#30363d]' 
                              : 'text-gray-700 hover:bg-gray-100'
                            )
                      } transition-colors`}
                      whileHover={{ 
                        x: 2,
                        backgroundColor: isDarkMode ? 'rgba(59, 130, 246, 0.2)' : 'rgba(239, 246, 255, 0.9)'
                      }}
                    >
                      {lang.label}
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </div>
            
            {isUpdateAllClicked && (
              <span className={`ml-2 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                {t('common.loading')}
              </span>
            )}
          </div>
          <button
            onClick={toggleShowAllPapers}
            className={`text-sm font-medium ${isDarkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-600 hover:text-gray-800'}`}
          >
            {showAllPapers ? t('homeTab.hideAll') : t('homeTab.showAll')}
          </button>
        </div>
      </div>

      {showSubscriptionInput && (
        <div className="mb-2" ref={subscriptionInputRef}>
          <div className={`flex items-center rounded-full overflow-hidden ${isDarkMode ? 'bg-[#21262d]' : 'bg-white'} shadow-md w-64`}>
            <input
              type="text"
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder={t('homeTab.enterKeyword')}
              className={`flex-grow p-2 pl-4 outline-none ${isDarkMode ? 'bg-[#21262d] text-gray-200' : 'bg-white text-gray-800'}`}
            />
            <button
              onClick={handleAddKeyword}
              className={`p-2 ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}`}
            >
              <IoSearch size={20} />
            </button>
          </div>
        </div>
      )}

      <div className="flex flex-wrap gap-1 mb-2">
        {keywords.map((k) => (
          <div
            key={k.id}
            className={`flex items-center px-3 py-1 rounded-full text-xs cursor-pointer ${
              k.keyword === selectedKeyword || displayedKeywords.has(k.keyword)
                ? isDarkMode
                  ? 'bg-blue-600 text-white'
                  : 'bg-blue-500 text-white'
                : isDarkMode
                ? 'bg-[#30363d] text-gray-200'
                : 'bg-gray-200 text-gray-800'
            }`}
          >
            <span className="mr-2" onClick={() => handleKeywordClick(k.keyword)}>
              {k.keyword}
              {loadingKeywords[k.keyword] && (
                <AiOutlineLoading className="inline-block ml-1 animate-spin" />
              )}
            </span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveKeyword(k.id, k.keyword);
              }}
              className={`p-1 rounded-full ${isDarkMode ? 'hover:bg-yellow-700' : 'hover:bg-yellow-200'}`}
            >
              <IoClose size={12} />
            </button>
          </div>
        ))}
      </div>

      {isLoading && (
        <div className="flex flex-col justify-center items-center my-4">
          <AiOutlineLoading className={`animate-spin text-4xl ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
          <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {loadingCaption}
          </p>
        </div>
      )}

      {!isLoading && (
        <>
          {displayedPdfs.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 [&>*]:h-fit">
              {displayedPdfs.map((pdf, index) => (
                <RecommendedPDF
                  key={`${pdf.PMID}-${pdf.keyword || ''}-${index}`}
                  PMID={pdf.PMID}
                  journalName={pdf.JT}
                  title={pdf.TI}
                  authors={pdf.AU}
                  institution={pdf.AD}
                  metrics={{
                    if: pdf.IF,
                    casBlock: pdf.B,
                    jcrQ: pdf.Q
                  }}
                  keyword={pdf.keyword}
                  isDarkMode={isDarkMode}
                  onClick={() => handlePubMedClick(pdf.PMID)}
                  pubDate={pdf.PubDate}
                />
              ))}
            </div>
          ) : (
            <div className={`text-center py-8 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {isProcessing ? (
                <p className="text-lg font-semibold">{t('homeTab.processingPapers')}</p>
              ) : selectedKeyword ? (
                <>
                  <p className="text-lg font-semibold mb-2">{t('homeTab.noNewPapers')} "{selectedKeyword}"</p>
                  <p className="text-sm">{t('homeTab.noNewPublications')}</p>
                </>
              ) : (
                <p className="text-lg font-semibold">{t('homeTab.noPapersToDisplay')}</p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default KeywordSubscriptions;

import axios from 'axios';

// Language options for translation
export const languageOptions = [
  { value: 'zh', label: 'Chinese (Simplified)' },
  { value: 'cht', label: 'Chinese (Traditional)' },
  { value: 'spa', label: 'Spanish' },
  { value: 'ara', label: 'Arabic' },
  { value: 'fra', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'ru', label: 'Russian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'jp', label: 'Japanese' },
  { value: 'kor', label: 'Korean' },
  { value: 'it', label: 'Italian' },
  { value: 'vie', label: 'Vietnamese' },
  { value: 'th', label: 'Thai' },
  { value: 'hi', label: 'Hindi' }
];

// Translation strings for the document translation feature
export const documentTranslationStrings = {
  en: {
    selectTargetLanguage: "Select Target Language",
    chooseLanguageForDocument: "Choose the language to translate this document into with current layout",
    translate: "Translate",
    cancel: "Cancel",
    translateDocument: "Translate Document",
    clickToStart: "Click below to start document translation",
    downloading: "Downloading...",
    downloadTranslatedPDF: "Download Translated PDF",
    fileTooLarge: "File is too large. Please upload a file smaller than 20MB.",
    loginRequired: "No authentication token found. Please login.",
    pleaseLoginFirst: "Please login first",
    sessionExpired: "Session expired, please login again",
    translationFailed: "Translation failed. Please try again later.",
    downloadFailed: "Download failed. Please try again later.",
    translatedPdfNotFound: "Translated PDF not found in storage",
    insufficientBalance: "Insufficient Balance",
    currentBalance: "Current Balance",
    required: "Required",
    purchaseTokens: "Purchase Tokens",
    backToReading: "Back to Reading",
    translating: "Translating...",
    retry: "Retry",
    alreadyTranslated: "Already translated"
  },
  zh: {
    selectTargetLanguage: "选择目标语言",
    chooseLanguageForDocument: "选择要将此文档翻译成的语言",
    translate: "翻译",
    cancel: "取消",
    translateDocument: "全文翻译",
    clickToStart: "点击下方按钮开始翻译文档",
    downloading: "正在下载...",
    downloadTranslatedPDF: "下载翻译后的PDF",
    fileTooLarge: "文件太大，请上传小于 20MB 的文件。",
    loginRequired: "未找到认证令牌，请先登录。",
    pleaseLoginFirst: "请先登录",
    sessionExpired: "登录已过期，请重新登录",
    translationFailed: "翻译失败，请稍后重试。",
    downloadFailed: "下载失败，请稍后重试。",
    translatedPdfNotFound: "存储中未找到翻译后的PDF",
    insufficientBalance: "余额不足",
    currentBalance: "当前余额",
    required: "需要",
    purchaseTokens: "购买Token",
    backToReading: "返回阅读",
    translating: "正在翻译中...",
    retry: "重试",
    alreadyTranslated: "已翻译"
  }
};

export const translateWithBaidu = async (text, setShowGenerating = null, targetLang = 'zh') => {
  if (setShowGenerating) setShowGenerating(true);
  try {
    // Replace newline characters with spaces as baidu stops at \n
    const processedText = text.replace(/\n/g, ' ');
    
    const response = await axios.post("https://api.labcat.com.cn/translation/translation_baidu_common", {
      text: processedText,
      from_lang: 'en',
      to_lang: targetLang
    });

    if (response.data && response.data.translated_text) {
      return response.data.translated_text;
    }
    return null;
  } catch (error) {
    console.error('Error during Baidu translation:', error);
    return null;
  } finally {
    if (setShowGenerating) setShowGenerating(false);
  }
};

import { useState, useEffect, useCallback } from 'react';
import { fetchRecommendedPapers } from '../utils/fetchRecommendedpapers';
import { openDB } from 'idb';

const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const UPDATE_COOLDOWN = 5 * 60 * 1000; // 5 minutes cooldown between updates
const MAX_CONCURRENT_UPDATES = 3; // Maximum number of concurrent updates

// Sample data for initial CRISPR keyword if database is empty
const SAMPLE_CRISPR_PAPERS = [
  {
    keyword: "CRISPR",
    PMID: "12345678",
    TI: "CRISPR-Cas9 genome editing for therapeutic applications",
    AU: "Zhang F, Ding L, Wang H",
    JT: "Nature Biotechnology",
    PubDate: "2023/03/15",
    AD: "Broad Institute of MIT and Harvard, Cambridge, MA, USA",
    IF: "41.8",
    Q: "Q1",
    B: "B1",
    abstract: "CRISPR-Cas9 has revolutionized genome editing. This review discusses recent advances in CRISPR technology for therapeutic applications, including treatment of genetic disorders, cancer, and infectious diseases.",
    timestamp: Date.now()
  },
  {
    keyword: "CRISPR",
    PMID: "23456789",
    TI: "Improved base editors for precise genome engineering",
    AU: "Liu B, Doudna JA, Sternberg SH",
    JT: "Cell",
    PubDate: "2023/04/10",
    AD: "University of California, Berkeley, CA, USA",
    IF: "38.6",
    Q: "Q1",
    B: "B1",
    abstract: "Base editors enable precise C→T or A→G conversions without double-strand breaks. Here we report engineered base editors with improved targeting scope and reduced off-target effects for therapeutic genome editing.",
    timestamp: Date.now()
  },
  {
    keyword: "CRISPR",
    PMID: "34567890",
    TI: "Prime editing: precision genome editing without double-strand breaks",
    AU: "Chen K, Wang J, Lin R",
    JT: "Science",
    PubDate: "2023/05/22",
    AD: "Harvard Medical School, Boston, MA, USA",
    IF: "47.1",
    Q: "Q1",
    B: "B1",
    abstract: "Prime editing offers a versatile approach for making targeted insertions, deletions, and all possible base-to-base conversions without requiring double-strand breaks or donor DNA templates.",
    timestamp: Date.now()
  }
];

const useKeywords = () => {
  const [keywords, setKeywords] = useState([]);
  const [recommendedPdfs, setRecommendedPdfs] = useState({});
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [loadingKeywords, setLoadingKeywords] = useState({});
  const [lastUpdateTime, setLastUpdateTime] = useState({});

  const getDB = async () => {
    return openDB('PDFStore', 4);
  };

  const loadKeywordsAndPapers = useCallback(async () => {
    try {
      const db = await getDB();
      const keywordData = await db.getAll('keywords');

      const loadedKeywords = [];
      const loadedPdfs = {};

      keywordData.forEach((data) => {
        loadedKeywords.push({ id: data.keyword, keyword: data.keyword, timestamp: data.timestamp });
        loadedPdfs[data.keyword] = data.papers;
      });

      // Add default CRISPR keyword if no keywords in database AND it wasn't explicitly closed by user
      if (loadedKeywords.length === 0 && !localStorage.getItem('crispr_keyword_closed')) {
        const crisprKeyword = 'CRISPR';
        loadedKeywords.push({ id: crisprKeyword, keyword: crisprKeyword, timestamp: Date.now() });
        loadedPdfs[crisprKeyword] = SAMPLE_CRISPR_PAPERS;
        
        // Also save to database
        await db.put('keywords', { 
          keyword: crisprKeyword, 
          papers: SAMPLE_CRISPR_PAPERS, 
          timestamp: Date.now() 
        });
      }

      setKeywords(loadedKeywords);
      setRecommendedPdfs(loadedPdfs);

      // Set the first keyword as selected if there are any keywords
      if (loadedKeywords.length > 0) {
        setSelectedKeyword(loadedKeywords[0].keyword);
      }
    } catch (error) {
      console.error('Error loading keywords and papers from IndexedDB:', error);
    }
  }, []);

  useEffect(() => {
    loadKeywordsAndPapers();
  }, [loadKeywordsAndPapers]);

  const updateKeyword = useCallback(async (keyword) => {
    try {
      // Check if the keyword was recently updated
      if (lastUpdateTime[keyword] && 
          Date.now() - lastUpdateTime[keyword] < UPDATE_COOLDOWN) {
        console.log(`Skipping update for ${keyword}: Too soon since last update`);
        return;
      }

      setLoadingKeywords(prev => ({ ...prev, [keyword]: true }));
      
      const papers = await fetchRecommendedPapers(keyword);
      
      if (!papers || papers.length === 0) {
        console.log(`No papers found for keyword: ${keyword}`);
        setLoadingKeywords(prev => ({ ...prev, [keyword]: false }));
        return;
      }

      const db = await getDB();
      const keywordData = { 
        keyword, 
        papers, 
        timestamp: Date.now() 
      };
      
      await db.put('keywords', keywordData);

      setLastUpdateTime(prev => ({ ...prev, [keyword]: Date.now() }));
      setKeywords(prevKeywords => 
        prevKeywords.map(k => 
          k.keyword === keyword ? { ...k, timestamp: Date.now() } : k
        )
      );
      setRecommendedPdfs(prev => ({ ...prev, [keyword]: papers }));
      
    } catch (error) {
      console.error(`Error updating keyword ${keyword}:`, error);
    } finally {
      setLoadingKeywords(prev => ({ ...prev, [keyword]: false }));
    }
  }, [lastUpdateTime]);

  const checkAndUpdateKeyword = useCallback(async (keyword) => {
    const keywordObj = keywords.find(k => k.keyword === keyword);
    if (!keywordObj) return;

    const lastUpdate = lastUpdateTime[keyword] || keywordObj.timestamp;
    
    if (Date.now() - lastUpdate > CACHE_EXPIRATION) {
      await updateKeyword(keyword);
    } else {
      setSelectedKeyword(keyword);
    }
  }, [keywords, lastUpdateTime, updateKeyword]);

  const addKeyword = async (newKeyword) => {
    try {
      const trimmedKeyword = newKeyword.trim();
      
      // Clear the closed status if user is manually adding CRISPR back
      if (trimmedKeyword.toLowerCase() === 'crispr') {
        localStorage.removeItem('crispr_keyword_closed');
      }
      
      // Check if the keyword already exists
      const existingKeyword = keywords.find(k => k.keyword.toLowerCase() === trimmedKeyword.toLowerCase());
      if (existingKeyword) {
        setSelectedKeyword(existingKeyword.keyword);
        if (Date.now() - existingKeyword.timestamp > CACHE_EXPIRATION) {
          await updateKeyword(existingKeyword.keyword);
        }
        return;
      }

      const papers = await fetchRecommendedPapers(trimmedKeyword);
      const db = await getDB();
      const keywordData = { keyword: trimmedKeyword, papers, timestamp: Date.now() };
      await db.put('keywords', keywordData);

      setKeywords(prevKeywords => [...prevKeywords, { id: trimmedKeyword, keyword: trimmedKeyword, timestamp: Date.now() }]);
      setRecommendedPdfs(prev => ({ ...prev, [trimmedKeyword]: papers }));
      setSelectedKeyword(trimmedKeyword);
    } catch (error) {
      console.error('Error adding keyword:', error);
    }
  };

  const removeKeyword = async (id, keyword) => {
    try {
      const db = await getDB();
      await db.delete('keywords', keyword);

      // If removing the CRISPR keyword, mark it as closed
      if (keyword === 'CRISPR') {
        localStorage.setItem('crispr_keyword_closed', 'true');
      }

      setKeywords(prevKeywords => prevKeywords.filter(k => k.id !== id));
      setRecommendedPdfs(prev => {
        const { [keyword]: _, ...rest } = prev;
        return rest;
      });

      // If the removed keyword was selected, select the first available keyword
      if (selectedKeyword === keyword) {
        const remainingKeywords = keywords.filter(k => k.id !== id);
        setSelectedKeyword(remainingKeywords.length > 0 ? remainingKeywords[0].keyword : null);
      }
    } catch (error) {
      console.error('Error removing keyword:', error);
    }
  };

  const updateAllKeywords = async () => {
    console.log('Starting updateAllKeywords...');
    
    try {
      // Filter keywords that actually need updating
      const keywordsToUpdate = keywords.filter(({ keyword, timestamp }) => {
        const lastUpdate = lastUpdateTime[keyword] || timestamp;
        return Date.now() - lastUpdate > UPDATE_COOLDOWN;
      });

      if (keywordsToUpdate.length === 0) {
        console.log('No keywords need updating at this time');
        return;
      }

      // Process keywords in batches
      for (let i = 0; i < keywordsToUpdate.length; i += MAX_CONCURRENT_UPDATES) {
        const batch = keywordsToUpdate.slice(i, i + MAX_CONCURRENT_UPDATES);
        
        setLoadingKeywords(prev => 
          batch.reduce((acc, { keyword }) => ({ ...acc, [keyword]: true }), prev)
        );

        await Promise.all(
          batch.map(async ({ keyword }) => {
            try {
              await updateKeyword(keyword);
            } catch (error) {
              console.error(`Error updating keyword ${keyword} in batch:`, error);
            }
          })
        );

        // Add delay between batches to prevent rate limiting
        if (i + MAX_CONCURRENT_UPDATES < keywordsToUpdate.length) {
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }

    } catch (error) {
      console.error('Error in updateAllKeywords:', error);
    } finally {
      setLoadingKeywords({});
    }
  };

  return { 
    keywords, 
    recommendedPdfs, 
    selectedKeyword,
    setSelectedKeyword,
    addKeyword,
    removeKeyword,
    checkAndUpdateKeyword,
    updateKeyword,
    updateAllKeywords,
    loadingKeywords
  };
};

export default useKeywords;

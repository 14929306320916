import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import html2canvas from 'html2canvas';
import { IoShareSocialOutline } from "react-icons/io5";
import { createRoot } from 'react-dom/client';
import { fetchAbstractByPMID } from '../utils/fetchRecommendedpapers';
import { translateWithBaidu } from '../utils/translation';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const copyImageToClipboard = async (imageUrl) => {
  try {
    console.log('Starting to copy image to clipboard');
    
    if (navigator.clipboard && navigator.clipboard.write) {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
      console.log('Successfully copied using Clipboard API');
      return true;
    } 
    else {
      console.log('Clipboard API not available, falling back to download');
      const link = document.createElement('a');
      link.download = 'paper-share.png';
      link.href = imageUrl;
      link.click();
      return true;
    }
  } catch (err) {
    console.error('Failed to copy/download image:', err);
    try {
      const link = document.createElement('a');
      link.download = 'paper-share.png';
      link.href = imageUrl;
      link.click();
      return true;
    } catch (downloadErr) {
      console.error('Both clipboard and download failed:', downloadErr);
      return false;
    }
  }
};

const Notification = ({ message, onClose }) => (
  <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-black/75 text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in-up">
    <p className="text-sm">{message}</p>
  </div>
);

const SharePoster = ({ title, authors, institution, metrics, PMID, referralCode, isDarkMode, hidden = false }) => {
  const { t } = useTranslation();
  
  return (
    <div 
      className={`fixed ${hidden ? 'opacity-0' : ''} left-[-9999px]`} 
      style={{ width: '800px', height: '1200px' }}
    >
      <div className="w-full h-full bg-[#0a0b1a] p-10 relative overflow-hidden flex flex-col">
        {/* Grid background */}
        <div 
          className="absolute inset-0 opacity-10" 
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(6, 182, 212, 0.1) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(6, 182, 212, 0.1) 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px',
            transform: 'perspective(1000px) rotateX(60deg)',
            transformOrigin: 'center top'
          }}
        />
        
        {/* Content container with flex-col */}
        <div className="relative z-10 flex flex-col h-full">
          {/* Header - reduced margin */}
          <div className="mb-8">
            <div className="text-6xl font-bold text-cyan-400 mb-2">{t('sharePoster.interestingArticle')}</div>
            <div className="font-rajdhani text-2xl text-cyan-400 tracking-wider mb-1">www.labcat.com.cn</div>
            <div className="text-lg text-cyan-300">{t('sharePoster.aiDriven')}</div>
          </div>

          {/* Main content - reduced padding and margin */}
          <div className="bg-slate-900 p-8 rounded-lg border border-cyan-500 mb-6">
            <h2 className="text-2xl font-rajdhani text-cyan-50 font-bold mb-8 leading-relaxed">
              {title}
            </h2>
            
            <div className="space-y-6 mb-8">
              <p className="text-xl">
                <span className="text-cyan-400 font-semibold">{t('sharePoster.authors')}: </span>
                <span className="text-cyan-100 font-rajdhani">{typeof authors === 'string' ? authors : authors.join(', ')}</span>
              </p>
              <p className="text-xl">
                <span className="text-cyan-400 font-semibold">{t('sharePoster.institution')}: </span>
                <span className="text-cyan-100 font-rajdhani">{institution}</span>
              </p>
            </div>

            <div className="flex gap-8">
              {metrics.if && (
                <div className="flex-1">
                  <span className="text-xl text-cyan-300 font-bold font-rajdhani">IF: {metrics.if}</span>
                </div>
              )}
              {metrics.jcrQ && (
                <div className="flex-1">
                  <span className="text-xl text-purple-300 font-bold font-rajdhani">Q: {metrics.jcrQ}</span>
                </div>
              )}
              {metrics.casBlock && (
                <div className="flex-1">
                  <span className="text-xl text-pink-300 font-bold">中科院: {metrics.casBlock}区</span>
                </div>
              )}
            </div>
          </div>

          {/* QR code section - restored original styling */}
          <div className="bg-slate-900 p-6 rounded-lg border border-cyan-500 mt-auto flex items-center gap-6 mb-6">
            <div className="bg-white p-2 rounded-lg">
              <QRCodeSVG 
                value={`https://pubmed.ncbi.nlm.nih.gov/${PMID}/`} 
                size={120}
                level="L"
                includeMargin={true}
                fgColor="#0e7490"
                bgColor="#FFFFFF"
              />
            </div>
            <div>
              <p className="text-lg text-cyan-400 font-semibold mb-2">{t('sharePoster.scanQRCode')}</p>
              <p className="text-lg text-cyan-200 font-mono tracking-wide">
                pubmed.ncbi.nlm.nih.gov/{PMID}
              </p>
            </div>
          </div>

          {/* Website section remains at the bottom */}
          <div className="bg-slate-900 p-6 rounded-lg border border-purple-500 mb-6">
            <p className="text-3xl text-purple-300 font-mono font-bold mb-2">{t('sharePoster.visitWebsite')}</p>
            <p className="text-lg text-cyan-200">{t('sharePoster.experienceMore')}</p>
            <p className="text-lg text-cyan-200">{t('sharePoster.increaseEfficiency')}</p>
          </div>

          {/* Referral section - reduced padding */}
          <div className="bg-slate-900 p-6 rounded-lg border border-pink-500 mb-6">
            <h3 className="text-2xl font-bold text-pink-400 mb-3">
              {t('sharePoster.exclusiveReward')}
            </h3>
            <p className="text-xl text-cyan-300 mb-2">
              {t('sharePoster.useMyCode')} <span className="text-pink-400 font-bold font-rajdhani">{referralCode}</span>
            </p>
            <p className="text-lg text-cyan-200">{t('sharePoster.newUserBonus')}</p>
            <p className="text-lg text-cyan-200">{t('sharePoster.enterCode')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const RecommendedPDF = ({ PMID, title, authors, journalName, institution, metrics, keyword, isDarkMode, onClick, pubDate }) => {
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showSharePoster, setShowSharePoster] = useState(false);
  const [abstract, setAbstract] = useState('');
  const [translatedAbstract, setTranslatedAbstract] = useState('');
  const [showAbstract, setShowAbstract] = useState(false);
  const [isLoadingAbstract, setIsLoadingAbstract] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('selectedLanguage') || 'zh');

  const { t, i18n } = useTranslation();

  // Initialize currentLanguage from localStorage on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage') || 'zh';
    setCurrentLanguage(savedLanguage);
  }, []);

  const handleAbstractToggle = async (e) => {
    e.stopPropagation();
    setShowAbstract(!showAbstract);
    
    // Only fetch if we're showing and haven't loaded yet
    if (!showAbstract && !abstract && !isLoadingAbstract) {
      setIsLoadingAbstract(true);
      try {
        console.log('Fetching abstract for PMID:', PMID);
        const fetchedAbstract = await fetchAbstractByPMID(PMID);
        console.log('Received abstract:', fetchedAbstract);
        setAbstract(fetchedAbstract);
        
        // Automatically translate the abstract when it's fetched
        if (fetchedAbstract) {
          await translateAbstract(fetchedAbstract, currentLanguage);
        }
      } catch (error) {
        console.error('Error fetching abstract:', error);
      } finally {
        setIsLoadingAbstract(false);
      }
    }
  };

  // Listen for language changes in localStorage
  useEffect(() => {
    const checkLanguage = () => {
      const savedLanguage = localStorage.getItem('selectedLanguage') || 'zh';
      if (savedLanguage !== currentLanguage) {
        setCurrentLanguage(savedLanguage);
        
        // Retranslate the abstract if we have one
        if (abstract && abstract.trim() !== '') {
          translateAbstract(abstract, savedLanguage);
        }
      }
    };
    
    // Check initially
    checkLanguage();
    
    // Set up an interval to check periodically (every 1 second)
    const intervalId = setInterval(checkLanguage, 1000);
    
    return () => clearInterval(intervalId);
  }, [currentLanguage, abstract]);

  // Helper function to translate abstract
  const translateAbstract = async (abstractText, language) => {
    if (!abstractText || abstractText.trim() === '') return;
    
    setIsTranslating(true);
    try {
      const translation = await translateWithBaidu(abstractText, null, language);
      if (translation) {
        setTranslatedAbstract(translation);
      }
    } catch (error) {
      console.error('Error translating abstract:', error);
    } finally {
      setIsTranslating(false);
    }
  };

  useEffect(() => {
    const getReferralCode = async () => {
      try {
        const response = await fetch('https://api.labcat.com.cn/redemption/referral-code', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('wechat_token')}`
          }
        });
        const data = await response.json();
        setReferralCode(data.referral_code || '');
      } catch (error) {
        console.error('Error getting referral code:', error);
      }
    };
    getReferralCode();
  }, []);

  // Function to safely handle authors
  const formatAuthors = (authors) => {
    if (Array.isArray(authors)) {
      return authors.join(', ');
    } else if (typeof authors === 'string') {
      return authors;
    }
    return 'Unknown';
  };

  // Function to format CAS block based on language
  const formatCasBlock = (block) => {
    if (!block) return null;
    
    // In English, show as "CAS: B1", in Chinese show as "中科院: 1区"
    const isEnglish = i18n.language === 'en';
    const cleanBlock = block.replace('B', '');
    
    return isEnglish ? `CAS: B${cleanBlock}` : `${cleanBlock}区`;
  };

  const generateShareImage = async () => {
    // Create a container for our poster
    const container = document.createElement('div');
    document.body.appendChild(container);
    
    // Render the poster component
    const root = createRoot(container);
    root.render(
      <SharePoster 
        title={title}
        authors={authors}
        institution={institution}
        metrics={metrics}
        PMID={PMID}
        referralCode={referralCode}
        isDarkMode={isDarkMode}
      />
    );

    // Wait for fonts to load
    await document.fonts.ready;
    
    // Small delay to ensure everything is rendered
    await new Promise(resolve => setTimeout(resolve, 100));

    try {
      const canvas = await html2canvas(container.firstChild, {
        scale: 2, // Higher quality
        logging: false,
        useCORS: true,
        backgroundColor: null
      });

      const imageUrl = canvas.toDataURL('image/png', 0.8);

      // Cleanup
      root.unmount();
      document.body.removeChild(container);

      return imageUrl;
    } catch (error) {
      console.error('Failed to generate image:', error);
      root.unmount();
      document.body.removeChild(container);
      throw error;
    }
  };

  const handleImageShare = async (e) => {
    e.stopPropagation();
    setIsGeneratingImage(true);
    
    try {
      console.log('Starting image generation');
      const imageUrl = await generateShareImage();
      console.log('Image generated, attempting to copy');
      
      const success = await copyImageToClipboard(imageUrl);
      
      if (success) {
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      } else {
        // Show error notification
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      }
    } catch (error) {
      console.error('Image share failed:', error);
      // Show error notification
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } finally {
      setIsGeneratingImage(false);
    }
  };

  return (
    <>
      <div 
        className={`${
          isDarkMode ? 'bg-[#21262d]/50 hover:bg-[#30363d]/50' : 'bg-white/50 hover:bg-gray-100/50'
        } backdrop-blur-sm rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-xl transition duration-300 flex flex-col p-4 relative ${showAbstract ? 'h-fit' : 'h-[240px]'}`}
        onClick={onClick}
      >
        {/* Title section with fixed height */}
        <div className="h-[60px] mb-1">
          <div className="flex justify-between items-start">
            <h3 className={`font-semibold text-sm flex-grow line-clamp-3 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              {title}
            </h3>
            {keyword && (
              <span className={`ml-2 px-2 py-1 text-xs rounded-full whitespace-nowrap ${
                isDarkMode ? 'bg-[#30363d] text-gray-200' : 'bg-gray-200 text-gray-800'
              }`}>
                {keyword}
              </span>
            )}
          </div>
        </div>

        {/* Authors and Institution with fixed heights */}
        <div className="h-[60px]">
          <p className={`text-xs mb-1 line-clamp-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            <span className="font-medium">Authors:</span> {formatAuthors(authors)}
          </p>
          {institution && (
            <p className={`text-xs line-clamp-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              <span className="font-medium">Institution:</span> {institution}
            </p>
          )}
        </div>

        {/* Abstract Section */}
        <div className="flex flex-col">
          {/* Abstract Toggle Button - Apple style */}
          <button
            onClick={handleAbstractToggle}
            className={`text-xs px-2.5 py-1 rounded-full font-medium
              transition-all duration-300 ease-in-out
              inline-flex items-center gap-1.5 self-start mb-2
              ${isDarkMode 
                ? 'bg-cyan-800/50 text-cyan-200 hover:bg-cyan-700/50 border border-cyan-700/30' 
                : 'bg-cyan-100 text-cyan-800 hover:bg-cyan-200/80 border border-cyan-200'
              }
              backdrop-blur-sm shadow-sm
            `}
          >
            <span className={`w-1.5 h-1.5 rounded-full ${showAbstract 
              ? 'bg-current animate-pulse' 
              : 'bg-current'}`}
            />
            {showAbstract ? t('recommendedPDF.hideAbstract') : t('recommendedPDF.showAbstract')}
          </button>

          {/* Abstract Content */}
          {showAbstract && (
            <div className="mb-4 text-sm w-full">
              {isLoadingAbstract ? (
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  {t('recommendedPDF.loading')}
                </p>
              ) : (
                <div className="space-y-4">
                  {/* Translated Abstract */}
                  {abstract && (
                    <div>
                      <div className="flex items-center gap-2 mb-1">
                        <h3 className={`font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                          {t('recommendedPDF.abstract')}
                        </h3>
                        <span className={`text-xs px-1.5 py-0.5 rounded ${
                          isDarkMode ? 'bg-cyan-900/50 text-cyan-300' : 'bg-cyan-100 text-cyan-700'
                        }`}>
                          {t('recommendedPDF.machineTranslation')}
                        </span>
                      </div>
                      <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {isTranslating ? t('recommendedPDF.translating') : (translatedAbstract || t('recommendedPDF.translationFailed'))}
                      </p>
                    </div>
                  )}
                  
                  {/* Original Abstract */}
                  <div>
                    <h3 className={`font-medium mb-1 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                      {t('recommendedPDF.originalAbstract')}
                    </h3>
                    <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                      {abstract || t('recommendedPDF.noAbstract')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Bottom section with metrics */}
        <div className="mt-auto">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <p className={`text-xs font-bold line-clamp-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {journalName}
              </p>
              {pubDate && (
                <span className={`text-xs whitespace-nowrap ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {t('recommendedPDF.indexTime')}: {pubDate}
                </span>
              )}
            </div>

            <div className="flex items-center justify-between gap-2">
              <div className="flex flex-wrap gap-1">
                {metrics.if && (
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    isDarkMode ? 'bg-blue-800/50 text-blue-200' : 'bg-blue-100 text-blue-800'
                  }`}>
                    IF: {metrics.if}
                  </span>
                )}
                {metrics.jcrQ && (
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    isDarkMode ? 'bg-green-800/50 text-green-200' : 'bg-green-100 text-green-800'
                  }`}>
                    Q: {metrics.jcrQ}
                  </span>
                )}
                {metrics.casBlock && (
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    isDarkMode ? 'bg-yellow-800/50 text-yellow-200' : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {formatCasBlock(metrics.casBlock)}
                  </span>
                )}
              </div>

              <button
                onClick={handleImageShare}
                disabled={isGeneratingImage}
                className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium
                  transition-all duration-300 transform hover:scale-102
                  ${isDarkMode 
                    ? 'bg-blue-800/50 text-blue-200 hover:bg-blue-700/50 border border-blue-700/30' 
                    : 'bg-blue-100 text-blue-800 hover:bg-blue-200/80 border border-blue-200'
                  }
                  ${isGeneratingImage ? 'opacity-50 cursor-not-allowed' : ''}
                  group relative overflow-hidden backdrop-blur-sm`}
                aria-label="Share as Image"
                title="Share as Image"
              >
                {isGeneratingImage ? (
                  <span className="animate-pulse text-xs">{t('recommendedPDF.generating')}</span>
                ) : (
                  <>
                    <IoShareSocialOutline 
                      className="h-3.5 w-3.5 transition-transform group-hover:rotate-6" 
                    />
                    <span>{t('recommendedPDF.shareImage')}</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add notification */}
      {showNotification && (
        <Notification 
          message="海报已经复制到剪贴板，到微信粘贴复制给好友，获得token！" 
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default RecommendedPDF;

import axios from 'axios';
import { getJournalMetrics } from './journalData';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Add these constants at the top
const MIN_DELAY_BETWEEN_REQUESTS = 1000; // 1 second
const RETRY_DELAY = 2000; // 2 seconds
const MAX_RETRIES = 3;

// Create a clean axios instance without authorization headers for external APIs
const externalApiAxios = axios.create();
// Don't allow this instance to automatically add auth headers
delete externalApiAxios.defaults.headers.common['Authorization'];

async function searchPubMed(keyword, startDate, maxRetries = MAX_RETRIES) {
  const baseUrl = 'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/';
  const today = new Date();
  const endDate = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;
  
  // Sanitize the keyword for URL
  const sanitizedKeyword = encodeURIComponent(keyword.trim());
  
  const searchUrl = `${baseUrl}esearch.fcgi?db=pubmed&term=${sanitizedKeyword} AND (${startDate}[CRDT]:${endDate}[CRDT])&usehistory=y&retmax=100&retmode=json`;

  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      console.log(`Attempt ${attempt + 1} for keyword: ${keyword}`);
      
      const searchResponse = await externalApiAxios.get(searchUrl);
      const { webenv, querykey, count } = searchResponse.data.esearchresult;

      if (!webenv || !querykey) {
        throw new Error('WebEnv or QueryKey not found in the response');
      }

      // If no results found, return early
      if (count === '0') {
        console.log(`No results found for keyword: ${keyword}`);
        return null;
      }

      await delay(MIN_DELAY_BETWEEN_REQUESTS);

      const fetchUrl = `${baseUrl}efetch.fcgi?db=pubmed&query_key=${querykey}&WebEnv=${webenv}&retmode=xml&retmax=${count}`;
      console.log("fetchUrl", fetchUrl);
      const fetchResponse = await externalApiAxios.get(fetchUrl);

      return fetchResponse.data;
    } catch (error) {
      console.error(`Attempt ${attempt + 1} failed for ${keyword}:`, error.message);
      
      // If we get a 429 (Too Many Requests), wait longer
      if (error.response?.status === 429) {
        await delay(RETRY_DELAY * (attempt + 1));
      }
      
      if (attempt === maxRetries - 1) {
        throw new Error(`Failed to fetch data after ${maxRetries} attempts: ${error.message}`);
      }
      
      await delay(RETRY_DELAY);
    }
  }
}

function parseXmlToJson(xmlString) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  const articles = xmlDoc.getElementsByTagName('PubmedArticle');
  console.log("articles", articles);
  
  return Array.from(articles).map(article => {
    const title = article.querySelector('ArticleTitle')?.textContent || '';
    const journal = article.querySelector('Journal > Title')?.textContent || '';
    const pmid = article.querySelector('PMID')?.textContent || '';
    const firstAuthor = article.querySelector('AuthorList > Author:first-of-type > LastName')?.textContent || '';
    const firstAuthorInitials = article.querySelector('AuthorList > Author:first-of-type > Initials')?.textContent || '';
    const institution = article.querySelector('AuthorList > Author:first-of-type > AffiliationInfo > Affiliation')?.textContent || '';
    const abstract = article.querySelector('Abstract')?.textContent || '';

    // Update the PubDate extraction logic
    const pubDateElement = article.querySelector('PubmedData > History > PubMedPubDate[PubStatus="medline"], PubmedData > History > PubMedPubDate[PubStatus="pubmed"]');
    let pubDate = '';
    if (pubDateElement) {
      const year = pubDateElement.querySelector('Year')?.textContent || '';
      const month = pubDateElement.querySelector('Month')?.textContent || '';
      const day = pubDateElement.querySelector('Day')?.textContent || '';
      pubDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    return { 
      TI: title, 
      JT: journal, 
      PMID: pmid,
      AU: `${firstAuthor} ${firstAuthorInitials}`,
      AD: institution,
      AB: abstract,
      PubDate: pubDate
    };
  });
}

export async function fetchRecommendedPapers(keyword) {
  const daysAgo = new Date();
  daysAgo.setDate(daysAgo.getDate() - 1);
  const startDate = `${daysAgo.getFullYear()}/${daysAgo.getMonth() + 1}/${daysAgo.getDate()}`;

  try {
    const xmlData = await searchPubMed(keyword, startDate);
    const papers = parseXmlToJson(xmlData);

    const papersWithJT = papers.filter((paper) => 
      paper.JT && !paper.TI.startsWith('Erratum') && 
      !paper.TI.startsWith('&') && !paper.TI.startsWith('(')
    );

    const updatedPapers = papersWithJT.map(paper => {
      const metrics = getJournalMetrics(paper.JT);
      return {
        ...paper,
        IF: metrics ? metrics.if : 'N/A',
        Q: metrics ? metrics.q : 'N/A',
        B: metrics ? metrics.b : 'N/A',
        T: metrics ? metrics.t : 'N/A',
        keyword: keyword
      };
    });

    const sortedPapers = updatedPapers.sort((a, b) => {
      if (a.IF === 'N/A' && b.IF === 'N/A') return 0;
      if (a.IF === 'N/A') return 1;
      if (b.IF === 'N/A') return -1;
      return parseFloat(b.IF) - parseFloat(a.IF);
    });

    return sortedPapers.slice(0, 3);
  } catch (error) {
    console.error('Error fetching recommended papers:', error.message);
    return [];
  }
}

export async function fetchAbstractByPMID(pmid) {
  const baseUrl = 'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/';
  
  try {
    const fetchUrl = `${baseUrl}efetch.fcgi?db=pubmed&id=${pmid}&retmode=xml`;
    console.log('Fetching abstract URL:', fetchUrl);
    const response = await externalApiAxios.get(fetchUrl);
    
    // For debugging
    console.log('Response status:', response.status);
    
    // Create parser
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response.data, 'text/xml');
    
    // Check for parser errors
    const parserError = xmlDoc.querySelector('parsererror');
    if (parserError) {
      console.error('XML Parser Error:', parserError.textContent);
      return '';
    }
    
    // Try different ways to extract the abstract
    // First try the standard Abstract element
    let abstract = xmlDoc.querySelector('Abstract')?.textContent || '';
    
    // If that doesn't work, try AbstractText elements
    if (!abstract) {
      const abstractTexts = xmlDoc.querySelectorAll('AbstractText');
      if (abstractTexts && abstractTexts.length > 0) {
        abstract = Array.from(abstractTexts).map(el => el.textContent).join(' ');
      }
    }
    
    // As a last resort, try looking for OtherAbstract
    if (!abstract) {
      abstract = xmlDoc.querySelector('OtherAbstract')?.textContent || '';
    }
    
    console.log('Extracted abstract:', abstract ? abstract.substring(0, 100) + '...' : 'No abstract found');
    return abstract;
  } catch (error) {
    console.error(`Failed to fetch abstract for PMID ${pmid}:`, error);
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    }
    return '';
  }
}

//cdat is date create , early than pdat publication date, the pubstatus should use medline

import React, { useState, useMemo, useEffect, useRef } from 'react';
import { IoMoon, IoSunny, IoLogOutOutline, IoFlash, IoGift, IoSparkles, IoBookmark, IoLanguage, IoChevronDown, IoLogIn, IoCreateOutline } from "react-icons/io5";
import FileUploadArea from './FileUploadArea';
import GeneratedPoem from './GeneratedPoem';
import RecentPDFs from './RecentPDFs';
import KeywordSubscriptions from './KeywordSubscriptions';
import DailyMeme from './DailyMeme';
import usePDFMetadata from '../hooks/usePDFMetadata';
import useKeywords from '../hooks/useKeywords';
import TokenDisplay from './TokenDisplay';
import TokenPurchase from './TokenPurchase';
import TextPolishModal from './TextPolishModal';
import MessageBanner from './MessageBanner';
import ReferralModal from './ReferralModal';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import packageJson from '../../package.json';

const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const HomeTab = React.memo(({ 
  recentPdfs, 
  onOpenPdf, 
  onAddPdf, 
  onRemoveFromRecent, 
  isDarkMode, 
  toggleDarkMode, 
  userInfo, 
  onLogout,
  messageVisible,
  onMessageDismiss,
  dismissedMessages,
  isAuthenticated = true,
  onLogin
}) => {
  const sortedPdfs = usePDFMetadata(recentPdfs);
  const { keywords, recommendedPdfs, selectedKeyword, setSelectedKeyword, addKeyword, removeKeyword, checkAndUpdateKeyword, updateAllKeywords, loadingKeywords } = useKeywords();
  const { t } = useTranslation();

  const [showAllRecent, setShowAllRecent] = useState(false);
  const [showSubscriptionInput, setShowSubscriptionInput] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');
  const [showAllPapers, setShowAllPapers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMeme, setShowMeme] = useState(false);
  const [hasSeenMeme, setHasSeenMeme] = useState(() => {
    return localStorage.getItem('hasSeenMeme') === 'true';
  });
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const logoutButtonRef = useRef(null);
  const [showTokenPurchase, setShowTokenPurchase] = useState(false);
  const [tokenDisplayKey, setTokenDisplayKey] = useState(0);
  const [showPolishModal, setShowPolishModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [hoverButton, setHoverButton] = useState(null);

  // Check if user is on an older version (before 0.2.9)
  const isOlderVersion = useMemo(() => {
    const currentVersion = packageJson.version.split('.').map(Number);
    const targetVersion = [0, 2, 9];
    
    for (let i = 0; i < currentVersion.length; i++) {
      if (currentVersion[i] < targetVersion[i]) return true;
      if (currentVersion[i] > targetVersion[i]) return false;
    }
    return false;
  }, []);
  
  // Custom system message for older versions
  const [systemMessages, setSystemMessages] = useState([]);
  
  useEffect(() => {
    if (keywords.length > 0 && !selectedKeyword) {
      setSelectedKeyword(keywords[0].keyword);
    }
  }, [keywords, selectedKeyword, setSelectedKeyword]);

  useEffect(() => {
    const checkFirstKeyword = async () => {
      if (keywords.length > 0) {
        const firstKeyword = keywords[0];
        if (Date.now() - firstKeyword.timestamp > CACHE_EXPIRATION) {
          await checkAndUpdateKeyword(firstKeyword.keyword);
        }
      }
    };

    checkFirstKeyword();
  }, [keywords, checkAndUpdateKeyword]);

  useEffect(() => {
    // Add update guidance message for older versions
    if (isOlderVersion) {
      setSystemMessages([{
        id: 'update-guidance',
        title: '重要更新提示',
        content: '您正在使用旧版本的应用程序。请更新到最新版本以获得更好的体验和新功能。更新后，请确保完全关闭应用程序并重新启动。',
        priority: 'high',
        dismissible: true
      }]);
    }
  }, [isOlderVersion]);

  const displayedRecentPdfs = showAllRecent ? sortedPdfs : sortedPdfs.slice(0, 6);

  const handleAddKeyword = async () => {
    setShowSubscriptionInput(false);
    if (newKeyword.trim()) {
      setIsLoading(true);
      await addKeyword(newKeyword.trim());
      setShowAllPapers(false);
      setIsLoading(false);
    }
    setNewKeyword('');
  };

  const handleRemoveKeyword = async (id, keyword) => {
    await removeKeyword(id, keyword);
  };

  const memoizedGeneratedPoem = useMemo(() => <GeneratedPoem isDarkMode={isDarkMode} />, [isDarkMode]);

  const handlePubMedClick = (PMID) => {
    window.open(`https://pubmed.ncbi.nlm.nih.gov/${PMID}/`, '_blank', 'noopener,noreferrer');
  };

  const toggleSubscriptionInput = (value) => {
    setShowSubscriptionInput(prev => value === undefined ? !prev : value);
  };

  const handleKeywordClick = async (keyword) => {
    await checkAndUpdateKeyword(keyword);
    setSelectedKeyword(keyword);
    setShowAllPapers(false);
  };

  const toggleShowAllPapers = async () => {
    setShowAllPapers(!showAllPapers);
    if (!showAllPapers) {
      setSelectedKeyword(null);
      // Update all keywords when "Show all" is clicked
      for (const keyword of keywords) {
        if (Date.now() - keyword.timestamp > CACHE_EXPIRATION) {
          await updateAllKeywords(keyword.keyword);
        }
      }
    } else if (keywords.length > 0) {
      setSelectedKeyword(keywords[0].keyword);
    }
  };

  const toggleShowAllRecent = () => {
    setShowAllRecent(!showAllRecent);
  };

  const filteredPdfs = showAllPapers
    ? Object.values(recommendedPdfs).flat()
    : (selectedKeyword ? recommendedPdfs[selectedKeyword] || [] : []);

  const displayedPdfs = showAllPapers ? filteredPdfs : filteredPdfs.slice(0, 3);

  const displayedKeywords = useMemo(() => {
    return new Set(displayedPdfs.map(pdf => pdf.keyword));
  }, [displayedPdfs]);

  const handleShowMeme = () => {
    if (!showMeme) {
      setShowMeme(true);
      if (!hasSeenMeme) {
        setHasSeenMeme(true);
        localStorage.setItem('hasSeenMeme', 'true');
      }
    } else {
      setShowMeme(false);
    }
  };

  const toggleLogoutButton = () => {
    setShowLogoutButton(!showLogoutButton);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutButtonRef.current && !logoutButtonRef.current.contains(event.target)) {
        setShowLogoutButton(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTokenPurchaseClose = () => {
    setShowTokenPurchase(false);
    setTokenDisplayKey(prev => prev + 1);
  };

  const handlePolishModalClose = () => {
    setShowPolishModal(false);
  };

  return (
    <div className={`relative w-full h-full overflow-hidden z-0 ${isDarkMode ? 'bg-[#0d1117]' : 'bg-gray-100'}`}>
      {/* Show system messages if any */}
      {systemMessages.length > 0 && (
        <div className="mb-4">
          <MessageBanner
            isDarkMode={isDarkMode}
            isVisible={true}
            onDismiss={(id) => {
              setSystemMessages(systemMessages.filter(msg => msg.id !== id));
            }}
            dismissedMessages={{}}
            customMessages={systemMessages}
          />
        </div>
      )}
      
      {messageVisible && (
        <div className="mb-4">
          <MessageBanner
            isDarkMode={isDarkMode}
            isVisible={messageVisible}
            onDismiss={onMessageDismiss}
            dismissedMessages={dismissedMessages}
          />
        </div>
      )}
      
      {/* Enhanced blur effect background with animated gradients */}
      <div className="absolute inset-0 overflow-hidden">
        <motion.div 
          className="absolute h-3/6 w-2/5 -left-1/4 top-0 opacity-70"
          animate={{ 
            rotate: [0, 2, 0, -2, 0],
            scale: [1, 1.05, 1, 0.95, 1]
          }}
          transition={{ 
            duration: 20, 
            repeat: Infinity,
            ease: "easeInOut" 
          }}
        >
          <div className="h-full w-full opacity-70 blur-3xl">
            <div className="h-full w-full blur-3xl">
              <div className={`rotate-30 h-full w-full ${isDarkMode ? 'bg-[#21262d]' : 'bg-blue-500'} blur-3xl`} style={{ clipPath: 'polygon(40% 0%, 100% 30%, 80% 100%, 20% 100%, 0% 30%)' }}></div>
            </div>
          </div>
        </motion.div>
        <motion.div 
          className="absolute h-2/6 w-1/6 left-1/4 bottom-0 opacity-80"
          animate={{ 
            rotate: [0, -3, 0, 3, 0],
            scale: [1, 0.95, 1, 1.05, 1]
          }}
          transition={{ 
            duration: 15, 
            repeat: Infinity,
            ease: "easeInOut",
            delay: 2
          }}
        >
          <div className="h-full w-full opacity-80 blur-3xl">
            <div className="h-full w-full blur-3xl">
              <div className={`rotate-45 h-full w-full ${isDarkMode ? 'bg-[#21262d]' : 'bg-blue-500'}`} style={{ clipPath: 'polygon(60% 0%, 100% 40%, 70% 100%, 30% 100%, 0% 40%)' }}></div>
            </div>
          </div>
        </motion.div>
        <motion.div 
          className="absolute h-3/6 w-2/5 right-0 top-1/4 opacity-60"
          animate={{ 
            rotate: [0, 3, 0, -3, 0],
            y: [0, 10, 0, -10, 0]
          }}
          transition={{ 
            duration: 18, 
            repeat: Infinity,
            ease: "easeInOut",
            delay: 1
          }}
        >
          <div className="h-full w-full opacity-60 blur-3xl">
            <div className="h-full w-full blur-3xl">
              <div className={`rotate-12 h-full w-full ${isDarkMode ? 'bg-[#21262d]' : 'bg-pink-500'}`} style={{ clipPath: 'polygon(50% 0%, 100% 20%, 80% 100%, 20% 100%, 0% 20%)' }}></div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Content with enhanced glass morphism */}
      <div className={`relative z-10 h-full p-2 flex backdrop-blur-sm ${isDarkMode ? 'bg-[#0d1117]/40' : 'bg-white/40'}`}>
        <div className="w-1/4 pr-2 flex flex-col h-full overflow-hidden">
          <div className="flex-grow overflow-y-auto overflow-x-hidden pb-10" style={{ scrollbarWidth: 'thin' }}>
            <div className="flex justify-between items-center mb-4">
              <div className="flex-grow"></div>
              <div className="flex items-center flex-shrink-0">
                {userInfo && (
                  <div className="relative mr-2 flex items-center">
                    {/* Only show TokenDisplay when polish modal is closed */}
                    {!showPolishModal && (
                      <motion.div 
                        className="transform hover:scale-105 transition-transform duration-200"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <TokenDisplay key={tokenDisplayKey} />
                      </motion.div>
                    )}
                    <motion.button
                      onClick={() => setShowTokenPurchase(true)}
                      className="ml-2 btn-cyber-narrow transform transition-all duration-300 ease-out bg-gradient-to-r from-orange-500 to-amber-500 hover:from-orange-600 hover:to-amber-600 text-white"
                      whileHover={{ 
                        scale: 1.05,
                        boxShadow: "0 0 15px rgba(234, 88, 12, 0.5)"
                      }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <span className="flex items-center justify-center space-x-1">
                        <IoFlash className="text-base text-yellow-200" />
                        <span className="font-bold tracking-wider">token shop</span>
                      </span>
                    </motion.button>
                    <motion.button
                      onClick={() => setShowReferralModal(true)}
                      className="ml-2 btn-cyber-narrow transform transition-all duration-300 ease-out"
                      whileHover={{ 
                        scale: 1.05,
                        boxShadow: isDarkMode ? "0 0 8px rgba(59, 130, 246, 0.5)" : "0 0 8px rgba(59, 130, 246, 0.5)"
                      }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <span className="flex items-center justify-center space-x-1">
                        <IoGift className="text-base" />
                        <span className="font-bold tracking-wider">{t('homeTab.recommend')}</span>
                      </span>
                    </motion.button>
                    <motion.img
                      src={userInfo.headimgurl || `https://ui-avatars.com/api/?name=${encodeURIComponent(userInfo.nickname || userInfo.email || 'User')}&background=random&color=fff&size=128`}
                      alt="User"
                      className="w-8 h-8 rounded-full cursor-pointer ml-2 border-2 border-transparent"
                      onClick={toggleLogoutButton}
                      whileHover={{ 
                        scale: 1.1,
                        borderColor: isDarkMode ? "#3b82f6" : "#3b82f6"
                      }}
                      whileTap={{ scale: 0.9 }}
                    />
                    <AnimatePresence>
                      {showLogoutButton && (
                        <motion.div 
                          ref={logoutButtonRef}
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.2 }}
                        >
                          <button
                            onClick={onLogout}
                            className={`absolute right-0 mt-2 py-1 px-2 text-sm rounded-md flex items-center ${
                              isDarkMode
                                ? 'bg-[#21262d] text-gray-200 hover:bg-[#30363d]'
                                : 'bg-white text-gray-800 hover:bg-gray-100'
                            } shadow-md`}
                          >
                            <IoLogOutOutline className="mr-1" />
                            {t('homeTab.logout')}
                          </button>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                )}
                {!isAuthenticated && (
                  <motion.button
                    onClick={onLogin}
                    className="mr-2 btn-cyber-narrow transform transition-all duration-300 ease-out bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white"
                    whileHover={{ 
                      scale: 1.05,
                      boxShadow: "0 0 15px rgba(16, 185, 129, 0.5)"
                    }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <span className="flex items-center justify-center space-x-1">
                      <IoLogIn className="text-base text-white" />
                      <span className="font-bold tracking-wider">{t('homeTab.loginSignUp')}</span>
                    </span>
                  </motion.button>
                )}
                <motion.button
                  onClick={toggleDarkMode}
                  className={`p-2 rounded-full ${isDarkMode ? 'bg-[#21262d] text-yellow-300' : 'bg-gray-200 text-gray-800'}`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  title={isDarkMode ? t('common.lightMode') : t('common.darkMode')}
                >
                  {isDarkMode ? <IoSunny size={20} /> : <IoMoon size={20} />}
                </motion.button>
              </div>
            </div>
            
            {/* Modern styled FileUploadArea */}
            <div className="mb-4">
              <FileUploadArea onAddPdf={onAddPdf} isDarkMode={isDarkMode} />
            </div>
            
            {/* Text Polish Button - now below upload area */}
            <div className="mb-6">
              <motion.button
                onClick={() => setShowPolishModal(true)}
                className={`w-full py-4 px-5 rounded-lg flex items-center justify-center space-x-3 transition duration-200 transform border-2 ${
                  isDarkMode 
                    ? 'bg-gradient-to-r from-purple-900/40 to-indigo-900/40 hover:from-purple-900/60 hover:to-indigo-900/60 border-purple-800/30 hover:border-purple-700' 
                    : 'bg-gradient-to-r from-purple-500/10 to-indigo-500/10 hover:from-purple-500/20 hover:to-indigo-500/20 border-purple-200 hover:border-purple-300'
                }`}
                whileHover={{ 
                  scale: 1.02,
                  boxShadow: isDarkMode 
                    ? "0 0 20px rgba(139, 92, 246, 0.2)" 
                    : "0 0 20px rgba(139, 92, 246, 0.15)"
                }}
                whileTap={{ scale: 0.98 }}
              >
                <IoCreateOutline className={`text-2xl ${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`} />
                <span className={`font-semibold text-base ${isDarkMode ? 'text-purple-300' : 'text-purple-700'}`}>
                  {t('homeTab.textPolish')}
                </span>
              </motion.button>
            </div>
            
            {/* Display GeneratedPoem for all users */}
            <div className="mb-6 mt-6">
              {memoizedGeneratedPoem}
            </div>
            
            {/* Meme section with improved spacing */}
            <div className="mt-6">
              {/* Meme toggle button */}
              <div className="mb-3 relative flex space-x-2">
                <motion.button
                  onClick={handleShowMeme}
                  className={`flex-1 py-2 px-4 text-sm rounded-md ${
                    isDarkMode
                      ? 'text-blue-400 hover:text-blue-300 hover:bg-blue-900/20'
                      : 'text-blue-600 hover:text-blue-700 hover:bg-blue-100/50'
                  } transition-colors duration-200 relative inline-flex items-center justify-center`}
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  onMouseEnter={() => setHoverButton('meme')}
                  onMouseLeave={() => setHoverButton(null)}
                >
                  <span className="relative flex items-center">
                    <IoBookmark className={`mr-1 ${hoverButton === 'meme' ? 'animate-bounce' : ''}`} />
                    <span>{showMeme ? t('homeTab.hideMeme') : t('homeTab.showMeme')}</span>
                    {t('homeTab.dailyMeme')}
                    {!hasSeenMeme && (
                      <span className="absolute top-0 right-0 transform translate-x-full -translate-y-1/2 bg-blue-500 rounded-full w-2 h-2 animate-ping"></span>
                    )}
                  </span>
                </motion.button>
              </div>
              
              {/* Daily Meme Component with more space */}
              <div className="mb-4">
                <DailyMeme isDarkMode={isDarkMode} showMeme={showMeme} />
                {/* Extra blank space to ensure tall memes have enough room */}
                {showMeme && <div className="h-20"></div>}
              </div>
            </div>
          </div>
          
          {/* Empty div to maintain spacing */}
          <div className="mt-2"></div>
        </div>
        
        <div className={`w-px ${isDarkMode ? 'bg-[#30363d]' : 'bg-gray-300'} mx-2`}></div>
        <div className="w-3/4 pl-2 overflow-y-auto" style={{ height: 'calc(100vh - 16px)', scrollbarWidth: 'thin' }}>
          {/* Recent PDFs Section */}
          <RecentPDFs
            sortedPdfs={sortedPdfs}
            displayedRecentPdfs={displayedRecentPdfs}
            onOpenPdf={onOpenPdf}
            onRemoveFromRecent={onRemoveFromRecent}
            onAddPdf={onAddPdf}
            isDarkMode={isDarkMode}
            showAllRecent={showAllRecent}
            toggleShowAllRecent={toggleShowAllRecent}
          />

          {/* PubMed Keyword Subscriptions Section */}
          <KeywordSubscriptions
            isDarkMode={isDarkMode}
            keywords={keywords}
            showSubscriptionInput={showSubscriptionInput}
            toggleSubscriptionInput={toggleSubscriptionInput}
            newKeyword={newKeyword}
            setNewKeyword={setNewKeyword}
            handleAddKeyword={handleAddKeyword}
            handleRemoveKeyword={handleRemoveKeyword}
            handleKeywordClick={handleKeywordClick}
            selectedKeyword={selectedKeyword}
            displayedKeywords={displayedKeywords}
            isLoading={isLoading}
            displayedPdfs={displayedPdfs}
            handlePubMedClick={handlePubMedClick}
            showAllPapers={showAllPapers}
            toggleShowAllPapers={toggleShowAllPapers}
            loadingKeywords={loadingKeywords}
            handleUpdateAll={updateAllKeywords}
          />
        </div>
      </div>
      
      {/* Spotify-style signup belt for unauthenticated users */}
      {!isAuthenticated && (
        <div className="fixed bottom-0 left-0 right-0 z-50">
          <div className="bg-gradient-to-r from-blue-600 via-purple-600 to-indigo-700 text-white py-3 px-6 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold">{t('loginModal.welcome')}</h3>
              <p className="text-xs opacity-90">{t('loginModal.description')}</p>
            </div>
            <motion.button
              onClick={onLogin}
              className="rounded-full bg-white text-black py-2 px-6 font-bold text-sm transition-all"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('homeTab.loginSignUp')}
            </motion.button>
          </div>
        </div>
      )}

      <AnimatePresence>
        {showTokenPurchase && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <TokenPurchase 
              isDarkMode={isDarkMode} 
              onClose={handleTokenPurchaseClose} 
            />
          </motion.div>
        )}
      </AnimatePresence>
      
      <AnimatePresence>
        {showReferralModal && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
          >
            <ReferralModal
              isOpen={showReferralModal}
              onClose={() => setShowReferralModal(false)}
              isDarkMode={isDarkMode}
            />
          </motion.div>
        )}
      </AnimatePresence>
      
      <AnimatePresence>
        {showPolishModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <TextPolishModal
              isDarkMode={isDarkMode}
              onClose={handlePolishModalClose}
              forceTokenUpdate={tokenDisplayKey}
              onTokenUpdate={setTokenDisplayKey}
              isAuthenticated={isAuthenticated}
              onLogin={onLogin}
            />
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Version display */}
      <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2">
        <p className={`text-xs ${isDarkMode ? 'text-gray-600' : 'text-gray-400'} opacity-60`}>
          v{packageJson.version}
        </p>
      </div>
    </div>
  );
});

export default HomeTab;
